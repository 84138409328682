import React from 'react'
import { Transition } from 'react-transition-group'
import styled, { css } from 'styled-components'
import { rgba, stripUnit } from 'polished'
import { Colors, Global, Spacing, Types } from '@walter/shared'
import { animationCurve, animationTime, borderRadius, boxShadow, cover, square } from '../../styles/global'
import { fontSizes } from '../../styles/typography'
import { Heading } from '../Heading'
import { Icon } from '../Icon'

const getColor = ({ type }: { type?: ToastType }) => {
  if (type === 'negative') return Colors.red
  if (type === 'positive') return Colors.green
  return Colors.grey
}

const Container = styled.div<{ animationState: string }>`
  transition: all ${animationTime} ${animationCurve};
  z-index: 601;
  opacity: 0;
  transform: translateX(${Spacing.small});
  visibility: hidden;

  ${(props) =>
    (props.animationState === 'entering' || props.animationState === 'entered') &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  ${(props) =>
    props.animationState === 'entered' &&
    css`
      transform: none;
    `}

  ${(props) =>
    (props.animationState === 'exiting' || props.animationState === 'exited') &&
    css`
      opacity: 0;
      visibility: hidden;
    `}
`

const Inner = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: ${`${(stripUnit(Spacing.medium) as number) * 1.25}px`};
  padding-top: ${Spacing.large}; /* Compensate for border */
  border-radius: ${borderRadius};
  border: 1px solid ${Colors.borderColor};
  background-color: ${rgba(Colors.white, 0.96)};
  max-width: 360px;
  backdrop-filter: blur(2px);
  ${boxShadow};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: ${getColor};
    border-radius: ${borderRadius} ${borderRadius} 0 0;
  }
`

const IconWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${getColor};
  margin-right: ${Spacing.medium};
  flex-shrink: 0;
  ${square('24px')};

  &:after {
    content: '';
    background-color: ${getColor};
    border-radius: 50%;
    opacity: 0.2;
    ${cover('absolute')};
  }
`

const Content = styled.div`
  flex: 1;
  padding-right: ${Spacing.medium};
`

const Title = styled(Heading)`
  margin-bottom: ${Spacing.tiny};
`

const Text = styled.p`
  font-size: ${fontSizes.small};
  white-space: pre-wrap; /* So that the message is formatted correctly */
`

const Close = styled.button`
  position: relative;
  display: flex;
  padding: ${Spacing.small};
  margin: -${Spacing.small};
  color: ${Colors.greyLight};
  transition: color ${animationTime} ${animationCurve};

  &:hover {
    color: ${Colors.grey};
  }
`

export type ToastType = 'positive' | 'negative' | 'info' | 'warning'

export type ToastProps = {
  index?: number
  text?: string
  type?: ToastType
  title?: string
  icon?: Types.IconName
  duration?: number
  close?: () => void
  visible: boolean
}

export const Toast = ({ icon, type, title, text, visible, close, duration = 5000 }: ToastProps) => {
  React.useEffect(() => {
    const disappearTimeout = setTimeout(() => {
      if (close) {
        close()
      }
    }, duration)
    return () => {
      clearTimeout(disappearTimeout)
    }
  }, [close, duration])

  return (
    <Transition in={visible} timeout={Global.TRANSITION_LENGTH} unmountOnExit enter>
      {(state: string) => (
        <Container animationState={state} onClick={close}>
          <Inner type={type}>
            {icon && (
              <IconWrap type={type}>
                <Icon icon={icon} size="tiny" />
              </IconWrap>
            )}
            <Content>
              {title && <Title>{title}</Title>}
              <Text>{text}</Text>
            </Content>
            <Close onClick={close}>
              <Icon icon="close" size="small" />
            </Close>
          </Inner>
        </Container>
      )}
    </Transition>
  )
}
