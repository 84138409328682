import React, { useEffect } from 'react'
import { Colors } from '@walter/shared'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import styled from 'styled-components'
import { animationCurve, animationTime, borderRadius } from '../../../styles/global'
import { fontSizes } from '../../../styles/typography'
import { useFormContext } from 'react-hook-form'

const QuillWrapper = styled.div`
  .quill {
    .ql-toolbar.ql-snow {
      border: 1px solid ${Colors.borderColor};
      border-top-left-radius: ${borderRadius};
      border-top-right-radius: ${borderRadius};
      background-color: ${Colors.offWhite};
    }

    .ql-container.ql-snow {
      font-size: ${fontSizes.regular};
      /* 320 px because right now we use it in post and event and it's often big text that goes there */
      min-height: 320px;
      background-color: ${Colors.white};
      border: 1px solid ${Colors.borderColor};
      border-bottom-left-radius: ${borderRadius};
      border-bottom-right-radius: ${borderRadius};
      transition: background-color ${animationTime} ${animationCurve}, border-color ${animationTime} ${animationCurve},
        color ${animationTime} ${animationCurve}, box-shadow ${animationTime} ${animationCurve};

      .ql-editor {
        min-height: 320px;
        color: ${Colors.grey};
      }
      .ql-editor:focus {
        box-shadow: inset 0 0 0 1px ${Colors.primaryColor}, 0 0 0 1px ${Colors.primaryColor};
        background-color: ${Colors.white};
      }
    }
  }
`
type RichTextareaProps = { name: string; disabled?: boolean; placeholder?: string }

export const RichTextarea = ({ name, disabled, placeholder }: RichTextareaProps) => {
  const {
    formState: { isSubmitting },
    register,
    setValue,
    getValues,
  } = useFormContext()

  useEffect(() => {
    register(name)
  }, [register, name])

  const handleChange = (value: string) => setValue(name, value, { shouldDirty: true })

  return (
    <QuillWrapper data-test-id="RichTextArea" data-cy="rich-text-area">
      <ReactQuill
        theme="snow"
        placeholder={placeholder}
        readOnly={isSubmitting || disabled}
        onChange={handleChange}
        defaultValue={getValues(name)}
      />
    </QuillWrapper>
  )
}
