import { Colors, Spacing } from '@walter/shared'
import { rgba, stripUnit } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { animationCurve, animationTime, borderRadius } from '../../styles/global'
import { fontSizes } from '../../styles/typography'
import { Icon } from '../Icon'

const TagContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: ${Spacing.small};
  margin-bottom: ${Spacing.small};
  background-color: ${rgba(Colors.grey, 0.15)};
  font-size: ${fontSizes.small};
  border-radius: ${`${(stripUnit(borderRadius) as number) * 0.6}px`};
  padding: ${Spacing.tiny} ${Spacing.small};
`

const TagRemove = styled.button`
  display: flex;
  margin-left: ${Spacing.tiny};
  transition: opacity ${animationTime} ${animationCurve};
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }
`

type TagProps = {
  label?: string
  handleClick: () => void
}

export function FilterResultItem({ label, handleClick }: TagProps) {
  return (
    <TagContainer data-test-id={'FilterTag_' + label}>
      {label}
      <TagRemove data-test-id="Close_Button" onClick={handleClick}>
        <Icon icon="close" size="tiny" />
      </TagRemove>
    </TagContainer>
  )
}
