import * as yup from 'yup'
import { t } from '../../../utils'

export const TaskCategoryi18nFields = {
  title: t('form-label-title'),
  description: t('description'),
}

export const taskCategorySchema = yup.object({
  title: yup.string().required(),
  description: yup.string().optional(),
  managingCompany: yup.object({ id: yup.string() }).optional(),
})

export type TaskCategoryFormItem = yup.InferType<typeof taskCategorySchema>
