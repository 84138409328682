import isArray from 'lodash/isArray'
import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'
import transform from 'lodash/transform'
import { curry, filter, mergeLeft, omit, pick } from 'ramda'
import { difference } from './general'
import { upload } from './upload'

export type Evolve<T> = {
  [key in keyof Partial<T>]: (value: T[key]) => any
}
type IdObject = { id: string }
type GraphQLFile = { name: string; url: string }
type InputElement<T> = {
  value: T
}
export type OriginalFiles = { id: string; name: string; url: string }[]

export const getChangedProperties = <Obj extends Record<string, unknown>, OtherObj extends Record<string, unknown>>(
  current: Obj,
  previous: OtherObj,
) => {
  const keysOfValuesChanged = Object.keys(difference(current, previous))
  return pick(keysOfValuesChanged, current)
}

export const toConnect = ({ id }: IdObject) => ({ connect: { id } })
const isSameId = (one: IdObject) => (two: IdObject) => one.id === two.id

const beforeHasObjectAndAfterIsEmpty = (before: IdObject, after: IdObject) =>
  before?.id !== undefined && after?.id === undefined
const bothHaveObject = (before: IdObject, after: IdObject) => before?.id !== undefined && after?.id !== undefined
const toIdObject = pick(['id'])
export const withoutIdAndTypeFrom = omit(['id', '__typename'])

export const toConnectOrDisconnect = curry((before: IdObject, after: IdObject) =>
  (!before && after.id !== undefined) || bothHaveObject(before, after)
    ? { connect: toIdObject(after) }
    : beforeHasObjectAndAfterIsEmpty(before, after)
    ? { disconnect: true }
    : null,
)

export const toConnectArray = (elements: IdObject[]) => {
  return {
    connect: elements.map((element) => ({ id: element.id })),
  }
}

export const extractInputNumber = (element: InputElement<number>) => element?.value

export const setNullValuesAfterEvolveZeroDepth = (
  dataBeforeEvolve: Record<string, unknown>,
  dataAfterEvolve: Record<string, unknown>,
) =>
  mergeLeft(
    filter((x) => x === null, dataBeforeEvolve),
    dataAfterEvolve,
  )

export const toConnectOrDisconnectArray = curry((before: IdObject[], after: IdObject[]) => {
  const disconnect = before.filter((inBefore) => after.find(isSameId(inBefore)) === undefined).map(toIdObject)
  const connect = after.filter((inAfter) => before.find(isSameId(inAfter)) === undefined).map(toIdObject)
  const result: { connect?: IdObject[]; disconnect?: IdObject[] } = {}
  if (connect) result.connect = connect
  if (disconnect) result.disconnect = disconnect
  return result
})

export const toDisconnect = () => ({ disconnect: true })
export const toCreateOrUpdate = (val: any) =>
  val.id || val.__typename ? { update: omit(['__typename'], val) } : { create: val }

const uploadFile = async (file: File) => {
  const uploaded = await upload([file])
  return uploaded?.[0]
}

export async function convertUpload(method: 'create' | 'update', file: File) {
  const fileData = await uploadFile(file)
  if (fileData) {
    return { [method]: { name: file.name, url: fileData.url } }
  }
  return {}
}

const getUpdatedFiles = async (files: File[], originalFiles: OriginalFiles) => {
  const uploadedFiles: GraphQLFile[] = []
  const originalFileNames = originalFiles.map((file) => file.name)

  const newFiles = files.filter((file) => !originalFileNames.includes(file.name))

  for (const file of newFiles) {
    const fileData = await uploadFile(file)
    if (fileData) {
      uploadedFiles.push({ name: file.name, url: fileData.url })
    }
  }
  return uploadedFiles
}

const getAndFormatDeletedFiles = (files: File[], originalFiles: OriginalFiles) => {
  const deleteMany = {
    url: { in: [] as string[] },
  }
  const finalFileNames = files.map((file) => file.name)
  const deletedFileNames = []

  for (const originalFile of originalFiles) {
    if (!finalFileNames.includes(originalFile.name)) {
      deleteMany.url.in.push(originalFile.url)
      deletedFileNames.push(originalFile.name)
    }
  }

  return deleteMany
}

export async function convertUploadArray(files: File[], originalFiles: OriginalFiles) {
  const uploadedFiles: GraphQLFile[] = await getUpdatedFiles(files, originalFiles)
  const deleteMany = getAndFormatDeletedFiles(files, originalFiles)

  const returnObject: { [x: string]: GraphQLFile[] | { url: { in: string[] } } } = { ['create']: uploadedFiles }

  if (deleteMany.url.in.length) {
    returnObject.deleteMany = deleteMany
  }

  return returnObject
}

export function getValueForConnectionMultiple(
  newData: { id?: string },
  oldValue: { id: string }[],
  newValue: { id: string }[],
) {
  const newItems = newValue.filter((item) => !item.id)
  const existingItems = newValue.filter((item) => item.id)

  // If the main object already exists
  if (newData.id) {
    const disconnect = oldValue
      .filter((item) => !existingItems.some((i) => i.id === item.id))
      .map((item) => ({
        id: item.id,
      }))
    const connect = existingItems
      .filter((item) => !oldValue || !oldValue.some((i) => i.id === item.id))
      .map((item) => ({
        id: item.id,
      }))
    const create = newItems
    return {
      ...(create.length > 0 && { create }),
      ...(connect.length > 0 && { connect }),
      ...(disconnect.length > 0 && { disconnect }),
    }
  } else {
    const create = newValue.filter(({ id }) => !id)
    const connect = existingItems.map(({ id }) => ({ id }))
    return {
      ...(connect.length > 0 && { connect }),
      ...(create.length > 0 && { create }),
    }
  }
}

export function differenceWithNull(base: any, object: any) {
  // If one or the other is empty...
  if (!base || !object) {
    return object || base
  }

  const changes = (base: any, object: any) => {
    return transform(base, (result: any, value, key) => {
      if (!isEqual(value, object[key])) {
        if (isObject(value) && isObject(object[key]) && !isArray(value)) {
          if (object[key] instanceof File) {
            result[key] = object[key]
            return
          }

          result[key] = changes(value, object[key])

          return
        }

        if (isArray(value) && isArray(object[key])) {
          if (object[key].length <= value.length) {
            result[key] = value.map((arrayItem) =>
              object[key].some((modifiedArrayItem: { id: string }) => modifiedArrayItem?.id === arrayItem?.id)
                ? arrayItem
                : null,
            )
          } else {
            result[key] = object[key]
          }

          return
        }

        if (object[key] === undefined) {
          result[key] = null

          return
        }

        result[key] = object[key]
      } else {
        delete result[key]
      }
    })
  }

  return changes(base, object)
}
