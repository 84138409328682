import { Field, FieldType } from '../types'
import * as Yup from 'yup'
import { TypeOf } from 'yup'
import { getTexts } from './localizations'
import i18n from 'i18next'

export const isValid = (value: unknown) =>
  value === 0 ||
  value === false ||
  value === null ||
  value === undefined ||
  (typeof value === 'string' && value.trim().length) ||
  value

export const required = (value: unknown) => {
  if (!isValid(value)) return 'Required'
}

export const match = (value: unknown, { password }: { password?: string }) => {
  if (!value || password === value) return

  return 'Passwords must match'
}

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const minPasswordLength = (value: string) => {
  if (value.length < 8) return 'Must be at least 8 characters'
}

export const isEmail = (value?: string) => {
  if (!value || emailRegex.test(value)) return

  return 'Please enter a valid email address'
}

function canValidateField(field: Field) {
  const { path, type } = field

  const validTypes: FieldType[] = ['email', 'boolean', 'textarea']

  if (path && path.split('.').length > 1) {
    return false
  }

  if (field.isArray) {
    return false
  }

  if (!type || (typeof type === 'string' && validTypes.includes(type))) {
    return true
  }

  return false
}

export function getYupValidation(field: Field) {
  const { type, validate, required, label, hidden } = field

  // Don't have the logic for that now so we return null for address.apartmentNumber
  if (!canValidateField(field)) {
    if (field.validate || field.required) {
      console.warn(
        `${field.path} is not supported by our yup validation, please make sure you check these values externally`,
      )
    }

    return null
  }

  let validation: TypeOf<Yup.AnySchema> = Yup

  switch (type) {
    case 'boolean':
      validation = validation.boolean()
      break
    default:
      validation = validation.string()
      break
  }

  if (required && !hidden) {
    validation = validation.required(`${label} ${getTexts(i18n.language || 'en', 'is-a-required-field')}`)
  } else {
    validation = validation.nullable()
  }

  if (validate) {
    const fieldValidation: undefined | ((value: any) => boolean) = validate.isValid

    //This solves the issue of non required values
    const validateNonEmpty = (value: any): boolean => {
      if (!value || !fieldValidation) {
        return true
      }
      return fieldValidation(value)
    }

    const defaultErrorMessage = label
      ? `${label} ${getTexts(i18n.language || 'en', 'is-not-valid')}`
      : getTexts(i18n.language || 'en', 'not-valid')

    validation = validation.test('Validate', validate.message || defaultErrorMessage, validateNonEmpty)
  }

  return validation
}
