import * as yup from 'yup'
import { t } from '../../../utils'

export const thirdPartiesMagexi18nFields = {
  magexClientId: t('magex-client-identifier-please-note-that'),
  shouldSkipProjectCreation: t('third-party-auto-add-missing-buildings'),
}

export const thirdPartiesMagexSchemaUpdate = yup.object({
  magexClientId: yup.string().optional(),
  shouldSkipProjectCreation: yup.boolean().nullable().default(true),
})

export type ThirdPartiesMagex = yup.InferType<typeof thirdPartiesMagexSchemaUpdate>
