import { Api } from '@walter/shared'
import * as yup from 'yup'
import { t } from '../../utils'

export const Packagesi18nFields = {
  title: t('title'),
  description: t('description'),
  status: t('status'),
  client: t('resident'),
  image: t('image'),
}

export const PackagesSchema = yup.object({
  title: yup.string().optional().nullable(),
  description: yup.string().optional().nullable(),
  status: yup.mixed<Api.Package_Status>().oneOf(Object.values(Api.Package_Status)),
  client: yup.object({ id: yup.string(), firstName: yup.string(), lastName: yup.string() }).nullable(),
  image: yup.mixed(),
})

export type Packages = yup.InferType<typeof PackagesSchema>
