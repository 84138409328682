import { Spacing } from '@walter/shared'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { t } from '../../../utils'
import { Button } from '../../Button'
import { SelectMemoPhoto } from '../../Drawer/SelectMemoPhoto'
import { Icon } from '../../Icon'
import { Label } from '../../Label'
import { PostCoverImage } from '../../PostCoverImage'

const PostCoverImageWrapper = styled.div`
  margin-bottom: ${Spacing.medium};
  position: relative;
`

const RemoveWrap = styled.button`
  position: absolute;
  top: ${Spacing.small};
  right: ${Spacing.small};
`

type CoverImageUploadProps = {
  name: string
  label: string
  disabled: boolean
}

type ImageFromBackend = { id: string; url: string }
type ImageFile = { path: string; preview: string }
type ImageOrString = string | ImageFile | ImageFromBackend

export const CoverImageUpload = ({ name, label, disabled }: CoverImageUploadProps) => {
  const [openCustomImage, setOpenCustomImage] = React.useState(false)
  const { setValue, getValues } = useFormContext()

  const image: ImageOrString = getValues(name)

  const [showCustomImage, setShowCustomImage] = React.useState(!!image)

  const isImage = (image: ImageOrString): image is ImageFile => typeof image !== 'string' && 'path' in image
  const isImageBackend = (image: ImageOrString): image is ImageFromBackend =>
    typeof image !== 'string' && 'url' in image

  const url: string = React.useMemo(() => {
    return image && (isImage(image) ? image.preview : isImageBackend(image) ? image.url : image)
  }, [image])

  return (
    <>
      <Label>{label}</Label>
      {showCustomImage && (
        <PostCoverImageWrapper>
          <PostCoverImage onClick={() => window.open(url, '_blank')} url={url} />
          <RemoveWrap
            data-test-id="CoverImage_Close_Button"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setValue(name, null, { shouldDirty: true })
              setOpenCustomImage(false)
              setShowCustomImage(false)
            }}
          >
            <Icon icon="close" size="small" />
          </RemoveWrap>
        </PostCoverImageWrapper>
      )}
      <Button
        dataTestId="ChooseCoverImage_Button"
        data-cy="choose-cover-image"
        onClick={() => {
          setOpenCustomImage(true)
        }}
        disabled={disabled}
      >
        {t('choose-cover-image')}
      </Button>
      <SelectMemoPhoto
        close={() => setOpenCustomImage(false)}
        visible={openCustomImage}
        handlePhotoSelected={(photoUrl: string) => {
          setValue(name, photoUrl, { shouldDirty: true })
          setOpenCustomImage(false)
          setShowCustomImage(true)
        }}
      />
    </>
  )
}
