import * as yup from 'yup'
import { i18n, t } from '../../utils'
import { fieldIsTooLong } from './common'
import { emptyStringToNull } from './transformationUtils'

export const uniti18nFields = {
  apartmentNumber: t('fields-unit:number'),
  floor: t('fields-unit:floor'),
  type: t('fields-unit:type'),
  squareFeet: t('fields-unit:square-feet'),
  squareFeetBalcony: t('fields-unit:square-feet-balcony'),
  proportionateShare: t('fields-unit:percentage-square-footage'),
  rooms: t('fields-unit:rooms'),
  note: t('fields-unit:note'),
  plan: t('fields-unit:plan'),

  users: t('fields-unit:residents'),
  owners: t('fields-unit:owners'),

  monthlyCondoFeeAmount: t('fields-unit:monthly-condo-fees'),

  intercomCode: t('fields-unit:intercom-code'),
  buzzCode: t('fields-unit:buzz-code'),
  wifiPassword: t('fields-unit:wifi-password'),
  hasAlarmSystem: t('fields-unit:has-alarm-system'),

  remotes: t('fields-unit:remotes'),
  accessKeys: t('fields-unit:access-keys'),

  parkings: t('fields-unit:parkings'),

  lockers: t('fields-unit:lockers'),

  rentAmount: t('current-rent-amount'),
  rentEnd: t('fields-unit:rent-end'),
  rentStart: t('fields-unit:rent-start'),
  lease: t('fields-unit:lease'),

  annexes: t('fields-unit:annexes'),
  referenceProperty: t('fields-unit:reference-property'),
}

export const unitSchemaCreate = yup.object({
  address: yup
    .object({
      apartmentNumber: yup.string().required(i18n.t('input:is-required', { field: uniti18nFields.apartmentNumber })),
    })
    .required(i18n.t('input:is-required', { field: uniti18nFields.apartmentNumber })),

  floor: yup.string().optional().nullable(),
  type: yup.string().optional().nullable(),
  squareFeet: yup.number().optional().transform(emptyStringToNull).nullable(),
  squareFeetBalcony: yup.number().optional().transform(emptyStringToNull).nullable(),
  proportionateShare: yup
    .number()
    .optional()
    .transform(emptyStringToNull)
    .nullable()
    .max(100, t('proportionate-share-should-be-percentage')),
  rooms: yup.string().optional().nullable(),
  note: yup.string().optional().nullable(),
  plan: yup.mixed().optional().nullable(),

  users: yup.array(yup.object({ id: yup.string(), name: yup.string() })),
  owners: yup.array(yup.object({ id: yup.string(), name: yup.string() })),

  monthlyCondoFeeAmount: yup.number().optional().transform(emptyStringToNull).nullable(),

  intercomCode: yup.string().optional().nullable(),
  buzzCode: yup.string().optional().nullable(),
  wifiPassword: yup.string().optional().nullable(),
  hasAlarmSystem: yup.boolean().optional().nullable(),

  remotes: yup.array(yup.object({ id: yup.string(), name: yup.string() })),
  accessKeys: yup.array(yup.object({ id: yup.string(), name: yup.string() })),

  parkings: yup.array(yup.object({ id: yup.string(), name: yup.string() })),

  lockers: yup.array(yup.object({ id: yup.string(), name: yup.string() })),

  rentAmount: yup.number().optional().transform(emptyStringToNull).nullable(),
  rentStart: yup.date().optional().nullable(),
  rentEnd: yup.date().optional().nullable(),
  lease: yup.mixed().optional().nullable(),

  annexes: yup.mixed().optional().nullable(),
  referenceProperty: yup.mixed().optional().nullable(),
})

export type Unit = yup.InferType<typeof unitSchemaCreate>

export const unitSchemaUpdate = unitSchemaCreate
