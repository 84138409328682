import React from 'react'
import styled, { css } from 'styled-components'
import { Spacing } from '@walter/shared'

const Container = styled.div<{ spacing?: string; fullWidth?: boolean; alignItems?: string }>`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: -${Spacing.small};

  ${(props) =>
    props.spacing === 'tight' &&
    css`
      margin: -${Spacing.tiny};
    `}

  ${(props) =>
    props.fullWidth &&
    css`
      display: flex;
      flex-direction: column;
      align-items: stretch;
    `}

  ${(props) =>
    props.alignItems === 'center' &&
    css`
      display: flex;
      justyf-content: center;
    `}
`

const Item = styled.div<{ spacing?: string }>`
  padding: ${Spacing.small};

  ${(props) =>
    props.spacing === 'tight' &&
    css`
      padding: ${Spacing.tiny};
    `}
`
type ButtonGroupProps = {
  children: React.ReactNode
  spacing?: string
  fullWidth?: boolean
  alignItems?: string
}

export const ButtonGroup = ({ children, spacing, fullWidth, alignItems }: ButtonGroupProps) => (
  <Container data-test-id="Button_Group" spacing={spacing} fullWidth={fullWidth} alignItems={alignItems}>
    {React.Children.map(
      children,
      (child, i) =>
        child && (
          <Item spacing={spacing} key={i}>
            {child}
          </Item>
        ),
    )}
  </Container>
)
