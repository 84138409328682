import * as yup from 'yup'
import { t } from '../../utils'

export const Project18nFields = {
  name: t('project-name'),
  colorHex: t('brand-color'),
  logo: t('horizontal-logo'),
  squareLogo: t('project-avatar'),
  nameInitials: t('project-initials'),

  address1: t('address-line-1'),
  address2: t('address-line-2'),
  apartmentNumber: t('unit-number'),
  city: t('city'),
  zip: t('postal-code'),
  state: t('province-state'),
  country: t('country'),
  timezone: t('timezone'),

  numberOfProperties: t('number-of-units'),
  additionalInfo: t('additional-information'),
  isCondoProject: t('is-condo-project'),
  isRentalProject: t('is-rental-project'),
  isInConstruction: t('project-is-in-construction'),

  residentCanCreateTask: t('resident-can-create-task'),
  residentCanEditFirstName: t('resident-can-edit-their-first-name'),
  residentCanEditLastName: t('resident-can-edit-their-last-name'),
  residentCanEditEmail: t('resident-can-edit-their-email'),
  residentCanEditPhoneNumber: t('resident-can-edit-their-phone-number'),

  news: t('news'),
  calendar: t('events'),
  contact: t('contacts'),
  rule: t('rule'),
  amenity: t('amenities'),
  parcel: t('parcel'),
  chat: t('chat'),
  files: t('files'),
  marketplace: t('marketplace'),
  tasks: t('tasks'),
  faq: t('faq'),
}

export const ProjectSchema = yup.object({
  name: yup.string().required(),
  colorHex: yup.string().optional().nullable(),
  logo: yup.mixed().optional().nullable(),
  squareLogo: yup.mixed().optional().nullable(),
  nameInitials: yup.string().optional().nullable(),

  building: yup.object({
    address1: yup.string().optional().nullable(),
    address2: yup.string().optional().nullable(),
    apartmentNumber: yup.string().optional().nullable(),
    city: yup.string().optional().nullable(),
    zip: yup.string().optional().nullable(),

    state: yup.string().optional().nullable(),
    country: yup.string().optional().nullable(),
  }),
  timezone: yup.string().optional().nullable(),

  numberOfProperties: yup
    .number()
    .optional()
    .nullable()
    .transform((value) => (isNaN(value) ? 0 : value)),
  additionalInfo: yup.string().optional().nullable(),
  isCondoProject: yup.boolean().nullable().default(false),
  isRentalProject: yup.boolean().nullable().default(false),
  isInConstruction: yup.boolean().nullable().default(false),

  residentCanCreateTask: yup.boolean().optional().nullable(),
  residentCanEditFirstName: yup.boolean().optional().nullable(),
  residentCanEditLastName: yup.boolean().optional().nullable(),
  residentCanEditEmail: yup.boolean().optional().nullable(),
  residentCanEditPhoneNumber: yup.boolean().optional().nullable(),

  tools: yup.array().of(yup.string()),
})

export type ProjectSchemaType = yup.InferType<typeof ProjectSchema>
