import { DateUtilsFactory } from '@walter/shared'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import { getLanguage } from './i18n'
import timezone from 'dayjs/plugin/timezone'
import localeData from 'dayjs/plugin/localeData'
import duration from 'dayjs/plugin/duration'

const locale = getLanguage()

dayjs.locale(locale)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(timezone)
dayjs.extend(localeData)
dayjs.extend(duration)

export const WebDateUtils = new DateUtilsFactory(dayjs)
