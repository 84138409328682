import * as yup from 'yup'
import { i18n, t } from '../../utils'

export const FileI18n = {
  name: t('name-uppercase'),
  files: t('file-s'),
  sharedWithResidents: t('shared-with-residents'),
}

export const FileCreateSchema = yup.object({
  name: yup.array(yup.string().nullable().optional()),
  sharedWithResidents: yup.boolean().required(),
  files: yup
    .array(yup.mixed())
    .min(1, i18n.t('input:is-required', { field: FileI18n.files }))
    .required(i18n.t('input:is-required', { field: FileI18n.files })),
})

export const FileUpdateSchema = yup.object({
  name: yup.array(yup.string().required(i18n.t('input:is-required', { field: FileI18n.name }))),
  sharedWithResidents: yup.boolean().required(),
  files: yup.array(yup.mixed()).nullable().optional(),
})

export type File = yup.InferType<typeof FileCreateSchema>
