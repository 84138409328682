import { Spacing, truthy } from '@walter/shared'
import React from 'react'
import { WebDateUtils } from '../../../src/utils/date'
import styled from 'styled-components'
import { Fieldset } from '../Fieldset'
import { FieldsetItem } from '../Fieldset/FieldsetItem'
import { FilterResultsList } from '../FilterBar/FilterResultsList'
import { t } from '@walter/shared-web'
import { Field } from '../Form/Field'

type Args = {
  target?: {
    value?: string
    checked?: boolean
  }
}
export function getValueForArgs(args: Args, field: Record<string, unknown>) {
  let value

  if (args?.target) {
    if (field.type === 'boolean') {
      value = args.target.checked
    } else {
      value = args.target.value
    }
  } else {
    value = args
  }

  if (typeof field.onChange === 'function') {
    value = field.onChange(value)
  }

  return value
}

const Container = styled.div`
  margin-bottom: ${Spacing.large};
  z-index: 3;
`

interface FilterNarrowItem {
  path: string
  label: string
  dataTestId?: string
}

interface FilterNarrowItemText extends FilterNarrowItem {
  type: 'text'
  dataTestId?: string
}

interface FilterNarrowItemBoolean extends FilterNarrowItem {
  type: 'boolean'
  dataTestId?: string
}

interface FilterNarrowItemSingleDate extends FilterNarrowItem {
  type: 'singledate'
  dataTestId?: string
}

interface FilterNarrowItemSelect extends FilterNarrowItem {
  path: string
  type: 'select'
  options: {
    value: number | string
    label: string
  }[]
  multiple?: boolean
  dataTestId?: string
}

type FilterTypes = FilterNarrowItemSingleDate | FilterNarrowItemSelect | FilterNarrowItemText | FilterNarrowItemBoolean

type FilterBarNarrowProps<Filter> = {
  dataTestId?: string
  filters: Filter[]
  // values: { [key in typeof Filter[number]['path']]?: string }
  values: any
  onFiltersChange: (values: Record<string, unknown>) => void
  numberOfItems: number
  loadingItems: boolean
  hideResults?: boolean
}

export function FilterBarNarrow<Filter extends FilterTypes>({
  dataTestId,
  filters,
  values,
  onFiltersChange,
  numberOfItems,
  loadingItems,
  hideResults,
}: FilterBarNarrowProps<Filter>) {
  function handleRemoveFilter(filter: { label: string; path: string; value?: string }) {
    onFiltersChange({
      ...values,
      [filter.path]: Array.isArray(values[filter.path])
        ? values[filter.path].filter((value: unknown) => value !== (filter as { value?: unknown })?.value)
        : null,
    })
  }

  const filtersTags = React.useMemo(() => {
    return getFiltersResultTags(values, filters)
  }, [values, filters])

  return (
    <Container data-test-id={`${dataTestId}_FilterBar_Container`}>
      <Fieldset>
        {filters.map((filter) => {
          function handleChange(args: Args) {
            let value = getValueForArgs(args, filter as unknown as Record<string, unknown>)

            // TMP because of Select...
            if (value?.value !== undefined) {
              value = value?.value
            }

            onFiltersChange({ ...values, [filter.path]: value })
          }

          const { type, ...rest } = filter
          return (
            <FieldsetItem dataTestId={filter.dataTestId} key={filter.path} width={`1-${filters.length}`}>
              <Field
                dataTestId={filter.dataTestId}
                {...rest}
                type={type === 'text' ? 'string' : type}
                onChange={handleChange}
                value={values[filter.path]}
              />
            </FieldsetItem>
          )
        })}
      </Fieldset>

      {filtersTags.length > 0 && (
        <FilterResultsList
          onRemoveResult={handleRemoveFilter}
          results={filtersTags}
          numberOfFoundItems={numberOfItems}
          loadingSearchResults={loadingItems}
          hideResults={hideResults}
        />
      )}
    </Container>
  )
}

function getFiltersResultTags(
  values: Record<string, unknown>,
  filters: FilterTypes[],
): {
  label: string
  path: string
  value?: string
}[] {
  const tags = Object.keys(values)
    .map((path) => {
      const value = values[path] as string

      if (!value) {
        return null
      }

      const filter = filters.find((filter) => filter.path === path)

      if (!filter) {
        return null
      }

      if (filter.type === 'select') {
        if (filter.multiple) {
          const test = filter.options.filter((option) => value.includes(option.value as string))
          const test1 = test.map((option) => {
            return {
              value: option.value as string,
              label: option.label,
              path,
            }
          })
          return test1
        }
      }

      let label = ''

      if (filter.type === 'select') {
        if (filter.multiple) {
          //
        } else {
          const option = filter.options.find((option) => value === option.value)
          if (option) {
            label = option.label
          }
        }
      } else if (filter.type === 'singledate') {
        // Should match label in 'singledate' \manager-web\src\components\reservation\ReservationsFilterBar.tsx
        if (filter.label === t('booking-start-on')) {
          label = `${t('booking-start-on')} ${WebDateUtils.dayjs(value).format('LL')}`
        } else {
          label = WebDateUtils.dayjs(value).format('LL')
        }
      } else if (filter.type === 'text') {
        label = value.toString()
      } else if (path === 'showSharedAmenities') {
        label = t('shared-amenity')
      } else {
        throw new Error(`why here`)
        // label = filter.label
      }

      return [
        {
          path,
          label,
        },
      ]
    })
    .flat()

  return tags.filter(truthy)
}
