import isInteger from 'lodash/isInteger'
import * as yup from 'yup'
import { i18n, t } from '../../utils'
import { fieldIsTooLong } from './common'

export const ResidentProfileManagerWebi18nFields = {
  firstName: t('first-name'),
  lastName: t('last-name'),
  title: t('title'),
  email: t('email'),
  secondaryEmail: t('secondary-email'),
  phone: t('mobile-phone-number'),
  homePhone: t('home-phone-number'),
  officePhone: t('office-phone-number'),
  extension: t('extension'),
  preferedLanguage: t('preferred-language'),
  emergencyContact: t('emergency-contact'),
  note: t('note'),

  property: t('living-unit'),
  properties: t('owning-units'),
  projectsTenants: t('is-tenant'),

  accessKeys: t('access-keys'),
  remotes: t('remotes'),

  parking: t('parkings'),

  lockers: t('lockers'),

  hasAppNotificationEnabled: t('has-app-notification-enabled'),
  hasEmailNotificationEnabled: t('has-email-notification-enabled'),

  totalLeaseAmount: t('total-lease-amount'),
  leaseEnd: t('lease-end'),
  leaseStart: t('lease-start'),
  rentLeaveDate: t('rent-leave-date'),
  accountsReceivableAmount: t('receivable-amount'),
  accountsReceivableComments: t('accounts-receivable-comments'),
}

export const residentProfileManagerWebSchema = yup.object({
  firstName: yup.string().max(20, fieldIsTooLong(20, ResidentProfileManagerWebi18nFields.title)).nullable(),
  lastName: yup.string().max(20, fieldIsTooLong(20, ResidentProfileManagerWebi18nFields.lastName)).nullable(),
  title: yup.string().optional().nullable(),
  email: yup.string().email().nullable().optional(),
  secondaryEmail: yup.string().email().nullable(),
  phone: yup.object({
    number: yup
      .string()
      .nullable()
      .test(
        'phone',
        i18n.t('input:must-be-phone-number', {
          field: ResidentProfileManagerWebi18nFields.phone,
          formats: '+11231231234, 1231231234',
        }),
        (value) => {
          const regex = new RegExp(/(\+[1-9])?[0-9]{3}[0-9]{3}[0-9]{4}/)
          return (value?.trim().length ?? 0) > 0 && typeof value === 'string' ? regex.test(value) : true
        },
      ),
  }),
  homePhone: yup.object({
    number: yup
      .string()
      .nullable()
      .test(
        'homePhone',
        i18n.t('input:must-be-phone-number', {
          field: ResidentProfileManagerWebi18nFields.homePhone,
          formats: '+11231231234, 1231231234',
        }),
        (value) => {
          if (value === null) return true
          const regex = new RegExp(/(\+[1-9])?[0-9]{3}[0-9]{3}[0-9]{4}/)
          return (value?.trim().length ?? 0) > 0 && typeof value === 'string' ? regex.test(value) : true
        },
      ),
  }),
  officePhone: yup.object({
    number: yup
      .string()
      .nullable()
      .test(
        'officePhone',
        i18n.t('input:must-be-phone-number', {
          field: ResidentProfileManagerWebi18nFields.officePhone,
          formats: '+11231231234, 1231231234',
        }),
        (value) => {
          if (value === null) return true
          const regex = new RegExp(/(\+[1-9])?[0-9]{3}[0-9]{3}[0-9]{4}/)
          return (value?.trim().length ?? 0) > 0 && typeof value === 'string' ? regex.test(value) : true
        },
      ),
    extension: yup
      .string()
      .nullable()
      .optional()
      .test(
        'officePhone',
        i18n.t('input:must-be-number', {
          field: ResidentProfileManagerWebi18nFields.extension,
        }),
        (value) => {
          return (value?.trim().length ?? 0) > 0 && typeof value === 'string'
            ? isInteger(parseInt(value?.trim()))
            : true
        },
      ),
  }),
  preferedLanguage: yup.string().optional(),
  emergencyContact: yup.string().optional().nullable(),
  note: yup.string().optional().nullable(),

  property: yup.object({ id: yup.string().nullable().optional() }).nullable().optional(),

  properties: yup
    .array(yup.object({ id: yup.string().nullable(), name: yup.string().nullable() }))
    .test('properties', t('cannot-rent-and-own-same-unit'), function (properties) {
      const projectsTenants = this.parent.projectsTenants
      const livingProperty = this.parent.property
      if (projectsTenants?.length > 0) {
        return !properties || properties.every((property) => property.id !== livingProperty?.id)
      }

      return true
    }),
  projectsTenants: yup.array(yup.object({ id: yup.string() })),

  accessKeys: yup.array(yup.object({ id: yup.string(), name: yup.string() })),
  remotes: yup.array(yup.object({ id: yup.string(), name: yup.string() })),

  parkings: yup.array(yup.object({ id: yup.string(), name: yup.string() })),

  lockers: yup.array(yup.object({ id: yup.string(), name: yup.string() })),

  hasAppNotificationEnable: yup.boolean().optional(),
  hasEmailNotificationEnable: yup.boolean().optional(),

  totalLeaseAmount: yup.number().optional().nullable(),
  leaseEnd: yup.date().optional().nullable(),
  leaseStart: yup.date().optional().nullable(),
  rentLeaveDate: yup.date().optional().nullable(),
  accountsReceivableAmount: yup.number().optional().nullable(),
  accountsReceivableComments: yup.string().optional().nullable(),
})

export type ResidentProfileManagerWeb = yup.InferType<typeof residentProfileManagerWebSchema>

export type ResidentProfileManagerWebFormContext = yup.InferType<typeof residentProfileManagerWebSchema>
