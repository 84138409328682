import React from 'react'
import { Button } from './../Button'
import { t } from '../../utils'
import { ButtonGroup } from '../ButtonGroup'
import { Modal } from '../Modal'

type ConfirmModalProps = {
  onConfirm?: (event: React.MouseEvent<HTMLButtonElement>) => any
  onClose?: (event: React.MouseEvent<HTMLButtonElement>) => any
  visible: boolean
  isLoading?: boolean
  customDescription?: string
  extraContent?: React.ReactNode
}

export const ConfirmModal = ({
  onConfirm,
  visible,
  onClose,
  isLoading,
  customDescription,
  extraContent,
}: ConfirmModalProps) => {
  return (
    <Modal dataTestId="ConfirmModal_Header" title={t('general:are-you-sure')} visible={visible} close={onClose}>
      <>
        {extraContent}
        <p data-test-id="ConfirmModal_Description">{customDescription || t('button:confirm-modal-text')}</p>
        <ButtonGroup>
          <Button
            dataTestId="ConfirmModal_Yes_button"
            isLoading={isLoading}
            testID={'confirmation-yes'}
            theme="negative"
            onClick={onConfirm}
          >
            {t('button:confirm-modal-yes')}
          </Button>
          <Button dataTestId="ConfirmModal_No_Button" testID={'confirmation-no'} theme="tertiary" onClick={onClose}>
            {t('button:confirm-modal-no')}
          </Button>
        </ButtonGroup>
      </>
    </Modal>
  )
}
