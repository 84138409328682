import { Colors } from '@walter/shared'
import React from 'react'
import styled, { css } from 'styled-components'
import { cover, square } from '../../styles/global'
import { fontSizes, fontWeights } from '../../styles/typography'

const getDimensions = (size: AvatarSize) => {
  if (size === 'tiny') return '18px'
  if (size === 'small') return '24px'
  if (size === 'large') return '40px'
  if (size === 'medium') return '32px'
  return '32px'
}

const getFontSize = (size: AvatarSize) => {
  if (size === 'tiny') return '8px'
  if (size === 'small') return '10px'
  if (size === 'large') return fontSizes.small
  if (size === 'medium') return fontSizes.tiny
  return fontSizes.tiny
}

const Container = styled.div<{ size: AvatarSize; isOnline?: boolean; textColor?: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.textColor ? props.textColor : Colors.white)};
  font-weight: ${fontWeights.bold};
  text-transform: uppercase;

  ${(props) =>
    props.isOnline &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        background-color: ${Colors.green};
        border: 2px solid ${Colors.white};
        display: block;
        border-radius: 50%;
        pointer-events: none;
        ${square('14px')};
      }
    `};

  ${(props) =>
    css`
      font-size: ${getFontSize(props?.size)};
      line-height: ${getDimensions(props?.size)};
      ${square(getDimensions(props?.size))};
    `}
`

const Inner = styled.div`
  overflow: hidden;
  border-radius: 50%;
  background-color: ${(props) => props.color || Colors.secondaryColor};
  width: 100%;
  text-align: center;
`

const Photo = styled.img`
  ${cover('absolute')};
  object-fit: cover;
  border-radius: 50%;
`

export type AvatarSize = 'tiny' | 'small' | 'medium' | 'large'

type AvatarProps = {
  photo?: string
  name?: string
  size?: AvatarSize
  color?: string
  isOnline?: boolean
  hint?: string
  textColor?: string
}

export const Avatar = React.memo(({ name, size = 'medium', color, photo, hint, isOnline, textColor }: AvatarProps) => {
  return (
    <Container
      data-test-id="Avatar_Container"
      data-cy={'avatar'}
      size={size}
      {...(name && { 'data-tip': hint || `${name}${isOnline ? ` (online)` : ''}` })}
      isOnline={isOnline}
      textColor={textColor}
    >
      <Inner data-test-id="Avatar_Item" color={color}>
        {photo && size !== 'small' && <Photo src={photo} alt={`${name} avatar`} />}
        {name
          ?.match(/[^\s]+/g)
          ?.map((namePart) => namePart.charAt(0))
          .join('') ||
          name?.substring(0, 1) ||
          '?'}
      </Inner>
    </Container>
  )
})
