import * as yup from 'yup'
import { i18n, t } from '../../utils'

export const BoardMembersi18nFields = {
  user: t('user'),
}

export const boardMemberSchema = yup.object({
  user: yup
    .object({ id: yup.string().required(), phone: yup.object({ number: yup.string() }).nullable().optional() })
    .required(i18n.t('input:is-required', { field: BoardMembersi18nFields.user })),
})

export const boardMemberUpdate = boardMemberSchema.clone().concat(yup.object({ id: yup.string() }))

export type BoardMember = yup.InferType<typeof boardMemberSchema>
