import * as yup from 'yup'
import { t } from '../../utils'

export const Notificationsi18nFields = {
  hasAppNotificationEnable: t('user:app-notification'),
  hasEmailNotificationEnable: t('user:email-notification'),
}

export const NotificationsSchema = yup.object({
  hasAppNotificationEnable: yup.boolean(),
  hasEmailNotificationEnable: yup.boolean(),
})

export type Notifications = yup.InferType<typeof NotificationsSchema>
