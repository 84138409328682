import * as yup from 'yup'
import { t } from '../../../utils'

export const thirdPartiesCondoManagerApii18nFields = {
  condoManagerApiKey: t('condo-manager-id'),
  shouldSkipProjectCreation: t('third-party-auto-add-missing-buildings'),
  shouldRemoveFamilyMembersAddedByUsers: t('third-party-remove-family-members-added-by-users'),
  shouldRemoveTenantsAddedByUsers: t('third-party-remove-tenants-added-by-users'),
  shouldRemoveOwnersAddedByUsers: t('third-party-remove-owners-added-by-users'),
}

export const thirdPartiesCondoManagerApiSchemaUpdate = yup.object({
  condoManagerApiKey: yup.string().optional(),
  shouldSkipProjectCreation: yup.boolean().optional().nullable().default(true),
  shouldRemoveFamilyMembersAddedByUsers: yup.boolean().optional().nullable().default(false),
  shouldRemoveTenantsAddedByUsers: yup.boolean().optional().nullable().default(false),
  shouldRemoveOwnersAddedByUsers: yup.boolean().optional().nullable().default(true),
})

export type ThirdPartiesCondoManagerApi = yup.InferType<typeof thirdPartiesCondoManagerApiSchemaUpdate>
