import * as yup from 'yup'
import { i18n, t } from '../../utils'

export const Marketplacei18nFields = {
  title: t('title'),
  description: t('description'),
  price: t('price'),
  phone: t('mobile-phone-number'),
  email: t('email'),
  images: t('photos'),
}

export const MarketplaceSchema = yup.object({
  title: yup.string().required(i18n.t('input:is-required', { field: Marketplacei18nFields.title })),
  description: yup.string().nullable(),
  price: yup
    .number()
    .typeError(i18n.t('input:is-required', { field: Marketplacei18nFields.price }))
    .required(i18n.t('input:is-required', { field: Marketplacei18nFields.price })),
  phone: yup.string().nullable(),
  email: yup.string().email(t('enter-email-invalid')).nullable(),
  images: yup.mixed().nullable(),
})

export const MarketplaceUpdateSchema = MarketplaceSchema.clone().concat(yup.object({ id: yup.string() }))

export type Marketplace = yup.InferType<typeof MarketplaceSchema>
