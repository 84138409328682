import { Spacing } from '@walter/shared'
import React from 'react'
import styled from 'styled-components'
import { fontSizes } from '../../styles/typography'
import { Heading } from '../Heading'

const Container = styled.div<{ isBottomAligned: string | undefined }>`
  display: flex;
  margin-bottom: ${Spacing.large};
`

const TitleWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: ${Spacing.xxLarge};
`

const Title = styled(Heading)`
  margin-bottom: ${Spacing.small};
`

const Description = styled.p`
  font-size: ${fontSizes.small};
  max-width: 640px;
`

type TitleBarProps = {
  dataTestId?: string
  title?: string
  description?: string
  actions?: React.ReactNode
  testID?: string
}

export const TitleBar = ({ dataTestId, title, description, actions, testID }: TitleBarProps) => {
  const dataTestIdValue = dataTestId ? dataTestId + '_TitleBar_Container' : 'TitleBar_Container'
  return (
    <Container data-test-id={dataTestIdValue} isBottomAligned={description} data-testid={testID}>
      <TitleWrap>
        <Title data-test-id="Title" id={`title-bar`} data-cy={`title-bar`} size={1}>
          {title}
        </Title>
        {description && <Description data-test-id="Description">{description}</Description>}
      </TitleWrap>
      <div style={{ paddingTop: Spacing.xTiny }}>{actions}</div>
    </Container>
  )
}
