import { Spacing } from '@walter/shared'
import styled, { css } from 'styled-components'

import { wrapperWidth } from '../styles/global'

export const DashboardLayout = styled.main<{ isFullPage?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${(props) => (props.isFullPage ? '0' : Spacing.xLarge)};

  ${(props) =>
    !props.isFullPage &&
    css`
      max-width: ${wrapperWidth};
      margin-left: auto;
      margin-right: auto;
    `}
`
