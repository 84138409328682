import uniqBy from 'lodash/uniqBy'
import get from 'lodash/get'
import { RESIDENT_TYPE } from '../types'
import { Colors } from '../constants'
import { uniq } from './general'
import { Api } from '..'

type UserResidentType = {
  projectsBoardMember?: unknown[]
  projectsTenants?: unknown[]
  properties?: unknown[]
  property?: unknown
  type?: RESIDENT_TYPE
  id?: string
}

export const getResidentType = (user?: UserResidentType | null): RESIDENT_TYPE | null => {
  if (!user) {
    return null
  }

  if (user.type) {
    return user.type
  }

  if (user.projectsBoardMember?.length) {
    return RESIDENT_TYPE.boardMember
  }

  if (user.projectsTenants && user.projectsTenants?.length > 0) {
    return RESIDENT_TYPE.tenant
  }

  if (user.properties?.length) {
    return RESIDENT_TYPE.owner
  }

  return RESIDENT_TYPE.familyMember
}

// TODO: compare function with the one in provider-web
export const residentIsType = (type: RESIDENT_TYPE, user: UserResidentType) => {
  if (!user) {
    return false
  }

  if (type === RESIDENT_TYPE.boardMember) {
    return user.projectsBoardMember && user.projectsBoardMember?.length > 0
  }

  if (type === RESIDENT_TYPE.tenant) {
    return user.projectsTenants && user.projectsTenants?.length > 0
  }

  if (type === RESIDENT_TYPE.owner) {
    // Also checking of projectsTenants because sometimes a resident will have a unit in their owning properties
    // Even if she's a tenant
    return user.properties && user.properties?.length > 0 && user.projectsTenants?.length === 0
  }

  if (type === RESIDENT_TYPE.familyMember) {
    return user.property && user.projectsTenants?.length === 0
  }

  return false
}

export const typeIsEqual = (type: RESIDENT_TYPE, userType: RESIDENT_TYPE) => {
  if (type === 'owner') {
    return userType === 'owner' || userType === 'boardMember'
  }

  if (type === 'boardMember') {
    return userType === 'boardMember'
  }

  if (type === 'tenant') {
    return userType === 'tenant'
  }

  if (type === 'familyMember') {
    return userType === 'familyMember'
  }

  return false
}

export const getResidentTypeLabel = (user: UserResidentType) => {
  const residentType = getResidentType(user)

  if (residentType === 'boardMember') {
    return 'Board member'
  }

  if (residentType === 'tenant') {
    return 'Tenant'
  }

  if (residentType === 'owner') {
    return 'Owner'
  }

  if (!residentType) {
    return 'N/A'
  }

  return 'Family'
}

export const getResidentTypeLabelForType = (type: RESIDENT_TYPE) => {
  if (type === 'boardMember') {
    return 'Board member'
  }

  if (type === 'tenant') {
    return 'Tenant'
  }

  if (type === 'owner') {
    return 'Owner'
  }

  if (!type) {
    return 'N/A'
  }

  return 'Family'
}

export const getResidentTypeTranslationKey = (type: RESIDENT_TYPE) => {
  if (type === 'boardMember') {
    return 'board-member'
  }

  if (type === 'tenant') {
    return 'tenant'
  }

  if (type === 'owner') {
    return 'owner'
  }

  if (!type) {
    return 'empty'
  }

  return 'family-member'
}

export const getColorForResidentType = (type: RESIDENT_TYPE) => {
  if (type === 'owner') return Colors.red

  if (type === 'tenant') return Colors.yellow

  if (type === 'familyMember') return Colors.blue
}

type Segment<Resident> = {
  properties: { users: Resident[]; owners: Resident[] }[]
  users: Resident[]
  segmentFields: { path: string; comparison: string; value: any }[]
}

export function getResidentsForSegments<Resident extends { id: string; type: RESIDENT_TYPE }>(
  segments: Segment<Resident>[],
  users: Resident[],
) {
  const validSegments = segments.filter(Boolean)

  if (validSegments.length === 0) {
    return []
  }

  const segmentsUsers: Resident[] = []

  segments.forEach((segment) => {
    if (segment.segmentFields) {
      segmentsUsers.push(
        ...users.filter((user) => {
          return segment.segmentFields.some((segmentField) => {
            if (segmentField.path === 'residentType') {
              // We're now formatting user to have "type"
              // field so it's easier to know his type
              // Instead of recalculating everything everytime...
              if (segmentField.comparison === 'EQUALS') {
                return user.type ? typeIsEqual(segmentField.value, user.type) : residentIsType(segmentField.value, user)
              }

              return user.type ? !typeIsEqual(segmentField.value, user.type) : !residentIsType(segmentField.value, user)
            }

            if (segmentField.comparison === 'EQUALS') {
              return get(user, segmentField.path) === segmentField.value
            }

            return !get(user, segmentField.path) === segmentField.value
          })
        }),
      )
    }

    segmentsUsers.push(...segment.users)

    // Do not do the followings in chained operationsas it might create max call stack error in big projects.
    const propertiesResidents =
      segment.properties?.flatMap((property) => [...(property?.users || []), ...(property?.owners || [])]) || []
    const filteredPropertiesResidents = propertiesResidents.filter(Boolean)
    const correspondingUsers = filteredPropertiesResidents.map((user) => users.find(({ id }) => id === user.id))
    const filteredCorrespondingResidents = correspondingUsers.filter(Boolean) as Resident[]

    for (const filteredCorrespondingResident of filteredCorrespondingResidents) {
      segmentsUsers.push(filteredCorrespondingResident)
    }
  })

  return uniqBy(segmentsUsers, 'id')
}

export const getPropertiesForSegments = (
  segments: {
    properties: [{ users: [{ id: string }]; owners: [{ id: string }] }]
    users: [{ id: string }]
    segmentFields: { path: string; comparison: string; value: string }[]
  }[] = [],
  properties: { owners: { id: string }[]; users: { id: string }[] }[] = [],
  users: { id: string }[] = [],
) => {
  const validSegments = segments.filter(Boolean)

  if (validSegments.length === 0) {
    return []
  }

  return segments.reduce((acc: any, segment: Segment<UserResidentType>) => {
    if (segment.segmentFields) {
      const usersForSegment = users.filter((user) => {
        // eslint-disable-next-line array-callback-return
        return segment.segmentFields.some((segmentField) => {
          if (segmentField.path === 'residentType') {
            const residentType = getResidentType(user)

            if (segmentField.comparison === 'EQUALS') {
              return segmentField.value === residentType
            }

            return segmentField.value !== residentType
          }
        })
      })
      const propertiesForUsers = properties.filter((p) =>
        [...p.owners, ...p.users].some((user) => usersForSegment.some((user1) => user1.id === user.id)),
      )

      acc = [...acc, ...propertiesForUsers]
    }

    if (segment.properties) {
      acc = [...acc, ...segment.properties]
    }

    if (segment.users) {
      const propertiesForUsers = properties.filter((p) =>
        [...p.owners, ...p.users].some((user) => segment.users.some((user1) => user1.id === user.id)),
      )

      acc = [...acc, ...propertiesForUsers]
    }

    return uniq(acc, 'id')
  }, [])
}

export function residentCanSeeAccountStatements(
  type: RESIDENT_TYPE,
  ownedProperties: Api.GetMeResidentWebQuery['me']['ownedProperties'],
  currentPropertyId: string,
) {
  const ownsCurrentProperty = ownedProperties.some((property) => property.id === currentPropertyId)

  return (type === 'owner' || type === 'boardMember') && ownsCurrentProperty
}
