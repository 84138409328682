import React from 'react'
import styled from 'styled-components'
import { Spacing } from '@walter/shared'
import { Button } from '../Button'
import { ButtonGroup } from '../ButtonGroup'
import { Dropdown } from '../Dropdown'
import { Heading } from '../Heading'
import { IconButton } from '../IconButton'
import { Loading } from '../Loading'
import { CalendarViewOption, CalendarToolbarNavigateItem } from './types'
import { t } from '../../utils'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${Spacing.large};
`

const Month = styled(Heading)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${Spacing.large};
  margin-bottom: 0;
  text-align: center;
`

const Section = styled.div`
  width: 200px;
`

const LoadingDataWrap = styled.div`
  margin-left: ${Spacing.small};
  display: flex;
  align-items: center;
`

export type ToolbarProps = {
  onNavigate?: (navigate: CalendarToolbarNavigateItem) => void
  label?: string
  onViewChange?: any
  view?: string
  isLoadingData?: boolean
  viewOptions: CalendarViewOption[]
}

export const DEFAULT_CALENDAR_VIEW_OPTIONS: CalendarViewOption[] = [
  {
    label: t('general:month'),
    value: 'month',
  },
  {
    label: t('general:week'),
    value: 'week',
  },
  {
    label: t('general:day'),
    value: 'day',
  },
]

export const Toolbar = ({
  isLoadingData,
  onViewChange,
  onNavigate,
  label,
  view,
  viewOptions = DEFAULT_CALENDAR_VIEW_OPTIONS,
}: ToolbarProps) => {
  return (
    <Container data-test-id="Calendar_Toolbar_Container">
      <Section data-test-id="Calendar_Toolbar_Left_Section">
        <ButtonGroup spacing="tight">
          <Button dataTestId="Today_Button" size="small" onClick={() => onNavigate?.('TODAY')}>
            {t('general:today')}
          </Button>
          <IconButton
            dataTestId="Previous_Button"
            size="small"
            icon="left-chevron"
            onClick={() => onNavigate?.('PREV')}
          />
          <IconButton dataTestId="Next_Button" size="small" icon="right-chevron" onClick={() => onNavigate?.('NEXT')} />
        </ButtonGroup>
      </Section>
      <Month data-test-id="Calendar_Toolbar_Middle_Section" size={3}>
        {label}
        {isLoadingData && (
          <LoadingDataWrap>
            <Loading type="inline" />
          </LoadingDataWrap>
        )}
      </Month>
      <Section
        data-test-id="Calendar_Toolbar_Right_Section"
        css={`
          text-align: right;
        `}
      >
        <Dropdown
          dataTestId="ViewOptions"
          size="small"
          value={view}
          onChange={(e) => onViewChange(e)}
          options={viewOptions}
        />
      </Section>
    </Container>
  )
}
