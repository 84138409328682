import * as yup from 'yup'
import { i18n, t } from '../../utils'

export const ResetPasswordi18nFields = {
  password: t('login-page-password'),
  confirmPassword: t('reset-password-page-confirm-password'),
}

export const ResetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, t('password-error-length'))
    .required(i18n.t('input:is-required', { field: ResetPasswordi18nFields.password })),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], t('reset-password-page-must-match')),
})

export type ResetPassword = yup.InferType<typeof ResetPasswordSchema>
