import * as yup from 'yup'
import { t } from '../../utils'

export const ManagingCompanyi18nFields = {
  shortName: t('managing-company-name-short'),
  longName: t('managing-company-name-long'),
  email: t('email'),
  phoneNumber: t('phone-number'),
  emeregencyPhone: t('emergency-phone-number'),
  extension: t('extension'),
  logo: t('logo'),
  logoAvatar: t('logo-avatar'),
  address: t('address-line-1'),
  address2: t('address-line-2'),
  apartmentNumber: t('unit-number'),
  city: t('city'),
  zip: t('postal-code'),
  state: t('province-state'),
  country: t('country'),
}

export const managingCompanySchema = yup.object({
  shortName: yup.string().optional().nullable(),
  longName: yup.string().optional().nullable(),
  email: yup.string().optional().nullable(),
  phone: yup.object({
    number: yup
      .string()
      .optional()
      .nullable()
      .test(
        'phone',
        t('input:must-be-phone-number', {
          field: ManagingCompanyi18nFields.phoneNumber,
          formats: '+11231231234, 1231231234',
        }),
        (value) => {
          const regex = new RegExp(/(\+[1-9])?[0-9]{3}[0-9]{3}[0-9]{4}/)
          return (value?.trim().length ?? 0) > 0 && typeof value === 'string' ? regex.test(value) : true
        },
      ),
  }),
  emergencyPhone: yup.object({
    number: yup
      .string()
      .optional()
      .nullable()
      .test(
        'emergencyPhone',
        t('input:must-be-phone-number', {
          field: ManagingCompanyi18nFields.phoneNumber,
          formats: '+11231231234, 1231231234',
        }),
        (value) => {
          const regex = new RegExp(/(\+[1-9])?[0-9]{3}[0-9]{3}[0-9]{4}/)
          return (value?.trim().length ?? 0) > 0 && typeof value === 'string' ? regex.test(value) : true
        },
      ),
  }),
  logo: yup.mixed().optional(),
  logoAvatar: yup.mixed().optional(),
  address: yup.object({
    address1: yup.string().optional().nullable(),
    address2: yup.string().optional().nullable(),
    apartmentNumber: yup.string().optional().nullable(),
    city: yup.string().optional().nullable(),
    zip: yup.string().optional().nullable(),
    state: yup.string().optional().nullable(),
    country: yup.string().optional().nullable(),
  }),
})

export type ManagingCompany = yup.InferType<typeof managingCompanySchema>
