import { get, isArray } from 'lodash'
import * as Yup from 'yup'
import { ConnectedField, Field } from '../types'
import { isFunction } from './general'
import { getYupValidation } from './validators'
import * as Types from '../types'

export function getDisplayValueForField(display: any, values: any) {
  if (!display) {
    return null
  }

  if (display === 'owner' && values.owner === null) {
    return '-'
  }

  if (isFunction(display)) {
    return display(values)
  }

  if (isArray(values[display]) && values[display].length === 0 && display !== 'images') {
    return '-'
  }

  return get(values, display)
}

export function putConnectedItemsIntoFields(
  fields: Readonly<Types.Field[]>,
  connectedFields: ConnectedField,
): Types.Field[] {
  return fields.map((field: any) => {
    // Recursion for Array Field
    if (field.fields) {
      return {
        ...field,
        fields: putConnectedItemsIntoFields(field.fields, connectedFields),
      }
    }

    // Do we have data to put into this field?
    if (connectedFields[field.path]) {
      // 2 ways, either we pass just the items and the name is the path
      // Or we pass an object {items: [], loading: false }
      if (connectedFields[field.path].items) {
        return {
          ...field,
          items: connectedFields[field.path].items || [],
          loadingItems: connectedFields[field.path].loading,
        }
      }

      return {
        ...field,
        items: connectedFields[field.path],
      }
    }

    return field
  })
}

export function getVisibleFields(fields: Field[], values: any | any[]): Field[] {
  return fields
    .map((field) => {
      if (fieldIsHidden(field, values)) {
        return null
      }

      if (field.fields) {
        return {
          ...field,
          fields: getVisibleFields(field.fields, values).filter(Boolean) as Field[],
        }
      }

      return field
    })
    .filter(Boolean) as Field[]
}

function fieldIsHidden(field: Field, values: any | any[]) {
  if (typeof field.hidden === 'undefined') {
    return false
  }

  if (field.hidden instanceof Function) {
    return field.hidden({ values })
  }

  return field.hidden
}

export function getFinalOptions(field: Field, values: any | any[]) {
  if (field.options instanceof Function) {
    return field.options(values)
  }
  return field.options
}

// TODO: Make unit test function
export function getValidationSchema(fields: Readonly<Types.Field[]>, isTesting?: boolean) {
  const schema = Yup.object().shape(
    // @ts-ignore
    fields.reduce((finalShape, field: Field) => {
      if (typeof field.path !== 'string') {
        return finalShape
      }
      const yupForType = getYupValidation(field)

      if (!yupForType || yupForType.mixed) {
        return finalShape
      }

      return {
        ...finalShape,
        [field.path]: yupForType,
      }
    }, {}),
  )

  if (isTesting) {
    return schema
  }

  return Yup.lazy(() => schema)
}
