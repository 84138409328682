import * as yup from 'yup'
import { t } from '../../../utils'

export const TaskStatusi18nFields = {
  title: t('form-label-title'),
  description: t('description'),
  orderPosition: t('task-order-position'),
  actAsComplete: t('act-as-complete'),
}

export const taskStatusSchema = yup.object({
  title: yup.string().required(),
  description: yup.string().optional(),
  orderPosition: yup.number().optional(),
  actAsComplete: yup.boolean().optional(),
  managingCompany: yup.object({ id: yup.string() }).optional(),
})

export type TaskStatusFormItem = yup.InferType<typeof taskStatusSchema>
