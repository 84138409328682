import * as yup from 'yup'
import { i18n, t } from '../../utils'

export const Posti18nFields = {
  projects: t('project-s'),
  coverImage: t('cover-image'),
  title: t('title'),
  description: t('description'),
  segmentId: t('group'),
  sendPostAt: t('custom-date'),
  attachments: t('files'),
}

export const postSingleProjectSchema = yup.object({
  coverImage: yup.mixed().optional(),
  title: yup.string().required(i18n.t('input:is-required', { field: Posti18nFields.title })),
  description: yup.string().optional(),
  sendPostAt: yup.date().optional(),
  attachments: yup.mixed().optional(),
  segment: yup.object({ id: yup.string() }),
  audience: yup.array(yup.object({ id: yup.string().required() })).optional(),
  segments: yup.array(yup.object({ id: yup.string().required() })).optional(),
  properties: yup.array(yup.object({ id: yup.string().required() })).optional(),
})

export const postAllProjectsSchema = yup.object({
  projects: yup
    .array(yup.object({ id: yup.string() }))
    .min(1, i18n.t('input:is-required', { field: Posti18nFields.projects }))
    .required(i18n.t('input:is-required', { field: Posti18nFields.projects })),
  coverImage: yup.mixed().optional(),
  title: yup.string().required(i18n.t('input:is-required', { field: Posti18nFields.title })),
  description: yup.string().optional(),
  sendPostAt: yup.date().optional(),
  attachments: yup.mixed().optional(),
  audience: yup.array(yup.object({ id: yup.string().required() })).optional(),
})

export const postEmailPreviewSchema = yup.object({ email: yup.string().email().required() })

export type PostSingleProjectFormSchema = yup.InferType<typeof postSingleProjectSchema>
export type PostAllProjectsFormSchema = yup.InferType<typeof postAllProjectsSchema>
