import { Api, Types } from '@walter/shared'
import * as yup from 'yup'
import { i18n, t } from '../../utils'

export const eventi18nFields = {
  title: t('fields-event:title'),
  description: t('fields-event:description'),
  type: t('fields-event:type-of-event'),
  start: t('start'),
  end: t('end'),
  coverImage: t('fields-event:cover-image'),
  hasReminder: t('fields-event:has-reminder'),
  typePublic: t('fields-event:for-residents'),
  typePrivate: t('fields-event:only-for-managing-company'),
  segments: t('fields-segment:group'),
}

export const eventSchemaCreate = yup.object({
  type: yup
    .string()
    .oneOf(Object.values(Api.Event_Type))
    .required(i18n.t('input:is-required', { field: eventi18nFields.type })),
  title: yup.string().required(i18n.t('input:is-required', { field: eventi18nFields.title })),
  description: yup.string().nullable(),
  start: yup.date().required(i18n.t('input:is-required', { field: eventi18nFields.start })),
  end: yup
    .date()
    .required(i18n.t('input:is-required', { field: eventi18nFields.end }))
    .min(yup.ref('start'), i18n.t('input:end-must-be-after-start')),
  coverImage: yup.mixed<File>(),
  hasAlertReminder: yup.boolean().nullable().default(false),
  segments: yup.array(yup.object({ id: yup.string() })),
})

export type Event = yup.InferType<typeof eventSchemaCreate> & { type: Api.Event_Type }

export const calendarEventType = [Api.Event_Type.Private, Api.Event_Type.Public]
export const eventTypeOptions = [
  {
    label: t('fields-event:for-residents'),
    value: 'PUBLIC',
  },
  {
    label: t('fields-event:only-for-managing-company'),
    value: 'PRIVATE',
  },
]
