import React from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { Colors } from '@walter/shared'
import { borderRadius, cover, square } from '../../styles/global'
import { fontSizes, fontWeights } from '../../styles/typography'

const Container = styled.div<{ hasBorder: string; size?: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.greyLight};
  color: ${Colors.white};
  border-radius: ${borderRadius};
  flex-shrink: 0;
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.minuscule};
  ${square('24px')};

  ${(props) =>
    props.hasBorder &&
    css`
      position: relative;

      &:after {
        content: '';
        box-shadow: inset 0 0 0 1px ${rgba(Colors.black, 0.15)};
        border-radius: ${borderRadius};
        ${cover('absolute')};
      }
    `}

  ${(props) =>
    props.size === 'medium' &&
    css`
      font-size: ${fontSizes.tiny};
      ${square('32px')};
    `}
  
  ${(props) =>
    props.size === 'large' &&
    css`
      font-size: ${fontSizes.regular};
      ${square('48px')};
    `}
`

const Logo = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${borderRadius};
`

type BuildingLogoProps = {
  name: string
  logo?: any
  initial?: string
  size?: string
}

export const BuildingLogo = ({ name, logo, initial, size }: BuildingLogoProps) => (
  <Container data-test-id="Building_Logo" data-tip={name} hasBorder={logo} size={size}>
    {logo && <Logo src={logo} alt={`${name} logo`} />}
    <span>{initial || name?.charAt(0) || 'N/A'}</span>
  </Container>
)
