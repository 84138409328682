import * as yup from 'yup'
import { i18n, t } from '../../utils'
import { ResidentProfileManagerWebi18nFields } from './residentProfileManagerWeb'

export const Contacti18nFields = {
  projects: t('selected-project'),
  isPrivate: t('contact-is-private'),
  firstName: t('first-name'),
  lastName: t('last-name'),
  title: t('title'),
  email: t('email'),
  phone: t('phone-number'),
  extension: t('extension'),
  website: t('website'),
}

export const ContactSchema = yup.object({
  projects: yup
    .array()
    .required(i18n.t('input:is-required', { field: Contacti18nFields.projects }))
    .min(1, i18n.t('input:is-required', { field: Contacti18nFields.projects })),
  allowMultipleBookings: yup
    .boolean()
    .nullable()
    .transform((value) => (value === null ? false : value)),
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  title: yup.string().optional().nullable(),
  email: yup.string().email().nullable().optional(),
  phone: yup.object({
    number: yup
      .string()
      .nullable()
      .test(
        'phone',
        i18n.t('input:must-be-phone-number', {
          field: ResidentProfileManagerWebi18nFields.officePhone,
          formats: '+11231231234, 1231231234',
        }),
        (value) => {
          if (value === null) return true
          const regex = new RegExp(/(\+[1-9])?[0-9]{3}[0-9]{3}[0-9]{4}/)
          return (value?.trim().length ?? 0) > 0 && typeof value === 'string' ? regex.test(value) : true
        },
      ),
    extension: yup.string().nullable().optional(),
  }),
  website: yup.string().nullable().optional(),
})

export const contactSchema = ContactSchema.clone().concat(yup.object({ id: yup.string() }))
export type ContactFormType = yup.InferType<typeof ContactSchema>
