import { Config } from '../config'
import { convertToArray } from './general'
import { serverlessUpload } from './serverlessUpload'
import i18n from 'i18next'

// https://cloud.google.com/storage/docs/json_api/v1/objects#resource
type GoogleFileResponse = {
  kind: string
  id: string
  selfLink: string
  mediaLink: string
  name: string
  bucket: string
  generation: string
  metageneration: string
  contentType: string
  storageClass: string
  size: string
  md5Hash: string
  contentDisposition: string
  crc32c: string
  etag: string
  timeCreated: string
  updated: string
  timeStorageClassUpdated: string
}

type WalterFileResponse = GoogleFileResponse & { url: string }

export type FileUploadType = File & {
  path?: string
  preview?: string
}

const config = { maxFileIndividualUploadSizeInMb: 20, maxTotalFileUploadSizeInMb: 24 }

export function validateFileSize(
  files: File[],
  maxFileIndividualUploadSizeInMb = config.maxFileIndividualUploadSizeInMb * 1024 * 1024,
  maxTotalFileUploadSizeInMb = config.maxTotalFileUploadSizeInMb * 1024 * 1024,
) {
  let totalSize = 0

  Object.keys(files).map((fileIndex) => {
    if (files[fileIndex as any].size > maxFileIndividualUploadSizeInMb) {
      throw new Error(`Error with file upload: Invididual file size limit exceeded`)
    }
    totalSize += files[fileIndex as any].size
  })

  if (totalSize > maxTotalFileUploadSizeInMb) {
    throw new Error(`Error with file upload: Total file size limit exceeded`)
  }
  return true
}

export const upload = async (
  files: FileUploadType[] | Blob[],
  options?: { useBackend?: boolean },
): Promise<WalterFileResponse[]> => {
  if (!files || files.length == 0) {
    return []
  }

  function blobToFile(inputBlob: Blob, fileName = 'image.png'): File {
    const b: any = inputBlob
    b.lastModifiedDate = new Date()
    b.name = fileName

    return <File>inputBlob
  }

  try {
    const filesArray = convertToArray(files)

    const data = new FormData()
    filesArray.forEach((file, index) => {
      console.log('file', file)
      console.log('file instanceof File', file instanceof File)
      console.log('file instanceof Blob', file instanceof Blob)
      if (file instanceof File) {
        data.append(String(index), file)
        return
      }
      if (file instanceof Blob) {
        data.append(String(index), blobToFile(file))
        return
      }
    })

    try {
      validateFileSize(filesArray)
    } catch {
      return Promise.reject(i18n.t('file-size-error'))
    }

    if ((!options?.useBackend && !window?.location?.origin.includes(':300')) || Config.isMobile) {
      const allFiles = filesArray.map((f) => serverlessUpload(f))

      return Promise.all(allFiles)
    }

    // Legacy uploads
    const response = await fetch(`${Config.restEndPoint}/upload`, {
      method: 'POST',
      body: data,
      headers: {
        Accept: 'application/json',
      },
    })

    const responseJson = await response.json()

    if (response.status === 413) {
      throw new Error(responseJson.errorMessage)
    } else {
      const error = (response as any)[0] && (response as any)[0].error
      if (error) {
        throw new Error(error.message)
      }
      return Promise.resolve(responseJson)
    }
  } catch (error) {
    if ((error as any).message.includes('file size')) {
      throw error
    } else {
      return Promise.reject(error)
    }
  }
}
