import { FileMessage, UserMessage } from '@sendbird/chat/message'
import { Colors, Message, SharedUtils, Spacing } from '@walter/shared'
import { Attachment, Icon, globalStyles, t, typography } from '@walter/shared-web'
import { mix, rgba, stripUnit } from 'polished'
import React from 'react'
import styled, { css } from 'styled-components'
import { ToastType } from '../Toast'

const Container = styled.div`
  position: relative;
`

const Content = styled.div<{ isPhoto: boolean; isFromManagement: boolean; isNote: boolean }>`
  position: relative;
  padding: ${Spacing.small} ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
  background-color: ${Colors.borderColor};
  border-radius: ${globalStyles.borderRadius};
  font-size: ${typography.fontSizes.small};
  white-space: pre-wrap; /* So that the message is formatted correctly */

  ${(props) =>
    props.isPhoto &&
    css`
      padding: 0;

      &:after {
        content: '';
        pointer-events: none;
        border-radius: ${globalStyles.borderRadius};
        box-shadow: inset 0 0 0 1px ${rgba(Colors.black, 0.1)};
        ${globalStyles.cover('absolute')};
      }
    `}

  ${(props) =>
    props.isFromManagement &&
    css`
      background-color: ${Colors.primaryColor};
      color: ${Colors.white};
    `}

  ${(props) =>
    props.isNote &&
    css`
      background-color: ${rgba(Colors.primaryColor, 0.25)};
      color: ${mix(0.3, Colors.black, Colors.primaryColor)};
    `}
`

const Photo = styled.img`
  display: block;
  width: 360px;
  border-radius: ${globalStyles.borderRadius};
`

const PhotoCaption = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0 0 ${globalStyles.borderRadius} ${globalStyles.borderRadius};
  padding: ${Spacing.small} ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
  background-color: ${rgba(Colors.black, 0.5)};
  color: ${rgba(Colors.white, 0.75)};
  text-shadow: 0 1px 2px ${rgba(Colors.black, 0.25)};
  backdrop-filter: blur(1);
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: -${Spacing.small};
  padding: ${`${(stripUnit(Spacing.tiny) as number) * 0.5}px`} ${Spacing.tiny};
  right: 0;
  margin-right: -${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
  background-color: ${Colors.white};
  border-radius: ${globalStyles.borderRadius};
  border: 1px solid ${Colors.borderColor};
  opacity: 0;
  visibility: hidden;
  transition: opacity ${globalStyles.animationTime} ${globalStyles.animationCurve},
    visibility ${globalStyles.animationTime} ${globalStyles.animationCurve};
  ${globalStyles.boxShadow};

  ${Container}:hover & {
    opacity: 1;
    visibility: visible;
  }
`

const Action = styled.button`
  position: relative;
  display: flex;
  padding: ${Spacing.tiny};
  transition: color ${globalStyles.animationTime} ${globalStyles.animationCurve};

  &:hover {
    color: ${Colors.greyDark};
  }
`

const Divider = styled.div`
  width: 1px;
  height: ${Spacing.medium};
  background-color: ${Colors.borderColor};
  margin: 0 ${Spacing.tiny};
`

const ReadMore = styled.div`
  display: inline-block;
  font-weight: ${typography.fontWeights.bold};
  margin-left: ${Spacing.tiny};
  color: ${Colors.primaryColor};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export interface UserMessageBubbleContentProps {
  message: UserMessage
}

export interface FileMessageBubbleContentProps {
  message: FileMessage
  isNote: boolean
}

const UserMessageBubbleContent = (props: UserMessageBubbleContentProps) => {
  const { message } = props
  const text = message.message

  const isEmail = text?.startsWith('* User replied by email')
  const [isTruncated, setIsTruncated] = React.useState(isEmail)

  return (
    <>
      {isEmail && text ? (
        <>
          {isTruncated ? SharedUtils.truncate({ string: text, maxChars: text?.indexOf('BODY') + 100 }) : text}
          <ReadMore onClick={() => setIsTruncated(!isTruncated)}>{isTruncated ? 'Read more' : 'Hide'}</ReadMore>
        </>
      ) : (
        text
      )}
    </>
  )
}

const FileMessageBubbleContent = (props: FileMessageBubbleContentProps) => {
  const url = props.message.url || props.message.plainUrl
  const fileName = props.message.name
  return (
    <>
      {url?.toLowerCase().includes('.pdf') ? (
        <Attachment
          handleClick={() => window.open(url, '_blank')}
          name={fileName || url.split('/')[url.split('/').length - 1]}
        />
      ) : url ? (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {props.isNote && (
            <PhotoCaption>
              <div
                // eslint-disable-next-line react/no-unknown-property
                css={`
                  margin-right: ${Spacing.small};
                  display: flex;
                `}
              >
                <Icon icon="lock" size="tiny" />
              </div>
              <span>{t('managers-only')}</span>
            </PhotoCaption>
          )}
          <Photo src={url} />
        </a>
      ) : null}
    </>
  )
}

export interface BubbleContentProps {
  message?: Message | null
  isNote: boolean
}

export const BubbleContent = (props: BubbleContentProps) => {
  const { message, isNote } = props
  switch (message?.messageType) {
    case 'user':
      return <UserMessageBubbleContent message={message as UserMessage} />
    case 'file':
      return <FileMessageBubbleContent message={message as FileMessage} isNote={isNote} />
    default:
      return null
  }
}

export interface BubbleProps {
  message: Message
  isFromMe: boolean
  isFromManagement: boolean
  isNote: boolean
  showToast: (type: ToastType, title: string, description?: string) => void
  promptDeleteMessage: (msg: Message) => void
  onCreateTask?: (taskName: string) => void
}

const Bubble = (props: BubbleProps) => {
  const { message, isFromManagement, promptDeleteMessage, isNote, isFromMe, onCreateTask } = props
  return (
    <Container
      data-test-id="Message_Item_Container"
      data-cy={`bubble-${message instanceof UserMessage ? message?.message : ''}`}
    >
      <Content
        data-test-id="Message_Item"
        isFromManagement={isFromManagement}
        isNote={isNote}
        isPhoto={message.isFileMessage()}
      >
        <BubbleContent message={message} isNote={isNote} />
      </Content>
      <Actions>
        {message instanceof UserMessage && onCreateTask && (
          <Action
            data-cy="create-task-from-message"
            data-tip={t('create-task')}
            onClick={() => onCreateTask(message.message)}
            data-test-id="Message_Item_CreateTask_Button"
          >
            <Icon icon="plus" size="small" />
          </Action>
        )}
        {!!isFromMe && (
          <>
            <Divider />
            <Action
              data-cy="delete-message-button"
              onClick={() => {
                promptDeleteMessage(message)
              }}
              data-tip={t('delete-message')}
              data-test-id="Message_Item_Delete_Button"
            >
              <Icon icon="trash" size="small" />
            </Action>
          </>
        )}
      </Actions>
    </Container>
  )
}

export default Bubble
