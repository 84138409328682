import { Dropdown } from '@walter/shared-web'
import React from 'react'

type FilterTagListProps = {
  dataTestId?: string
  tags: { value: string; label: string; isActive: boolean; handleClick: () => void }[]
  tagsListTitle?: string
}

export function FilterTagList({ dataTestId, tags, tagsListTitle }: FilterTagListProps) {
  const handleHandleClick = (value: string | number) => {
    const key = typeof value === 'string' ? parseInt(value) : value
    // 'all' and 'custom search' have had their onClicks added elsewhere. This prevents 'select an option' causing a crash
    if (tags[key]) {
      tags[key].handleClick()
    }
  }

  return (
    <Dropdown
      dataTestId={`${dataTestId}_${tagsListTitle}`}
      options={tags}
      defaultValue={'0'}
      onChange={(value) => handleHandleClick(value)}
      label={tagsListTitle || ''}
    />
  )
}
