import * as yup from 'yup'
import { i18n, t } from '../../utils'
import { emptyStringToNull } from './transformationUtils'

export const amenityi18nFields = {
  image: t('image'),
  title: t('fields-amenity:title'),
  description: t('fields-amenity:description'),
  otherInformation: t('fields-amenity:other-information-optional'),
  reservationType: t('fields-amenity:reservation-type'),
  singleDateReservation: t('fields-amenity:single-date-reservation'),
  multipleDaysReservation: t('fields-amenity:multiple-days-reservation'),
  singleDateTimeReservation: t('fields-amenity:single-date-reservation-with-time'),
  terms: t('fields-amenity:terms'),
  disclaimer: t('fields-amenity:disclaimer'),
  sharedWithProject: t('fields-amenity:shared-with-projects'),
  bookingOptions: t('fields-amenity:booking-options'),
  targetedBuilding: t('shared-booking-targeted-building'),
  group: t('fields-segment:group'),
  timeslotIncrementalInSeconds: t('fields-amenity:start-new-booking-every-x'),
  bookingInAdvanceMaxSeconds: t('fields-amenity:amenity-available-x-in-advance'),
  bookingInAdvanceMinSeconds: t('fields-amenity:resident-need-to-book-x-in-advance'),
  usageDuration: t('fields-amenity:usage-duration'),
  unavailableTimeBetweenBookings: t('fields-amenity:unavailable-time-between-each-booking'),
  residentCanBookOneEvery: t('fields-amenity:resident-can-book-once-every'),
  requireStaffApproval: t('fields-amenity:requires-staff-approval'),
  allowMultipleBooking: t('fields-amenity:allow-multiple-bookings-at-the-same-time'),
  maximumNumberResidentAtSameTime: t('fields-amenity:maximum-number-of-residents-at-the-same-time'),
  maximumResidentPerBooking: t('fields-amenity:maximum-residents-per-booking'),
  businessHours: t('fields-amenity:business-hours'),
  sharedWithProjects: t('fields-amenity:shared-with-projects'),
  bookingIntervalPerUserInSeconds: t('fields-amenity:resident-can-book-once-every'),
  maximumBookingsAtSameTime: t('fields-amenity:maximum-number-of-residents-at-the-same-time'),
  maximumOfResidentsInOneBooking: t('fields-amenity:maximum-residents-per-booking'),
  timeRequiredBetweenEachBookingSeconds: t('fields-amenity:unavailable-time-between-each-booking'),
}

export const businessHour = yup.object({
  id: yup.string(),
  dayOfWeek: yup.number(),
  openAtMS: yup.number(),
  closeAtMS: yup.number(),
  isClosed: yup.boolean(),
})

const yupObjectId = yup.object({ id: yup.string() })
const yupArrayIds = yup.array(yupObjectId)

export const bookingOptions = yup.object({
  id: yup.string(), //also used in the creation form as key in the list
  project: yupObjectId,
  segments: yupArrayIds,
  timeslotIncrementalInSeconds: yup
    .number()
    .required(i18n.t('input:is-required', { field: amenityi18nFields.timeslotIncrementalInSeconds })),
  bookingInAdvanceMaxSeconds: yup.number().positive().transform(emptyStringToNull).nullable(),
  bookingInAdvanceMinSeconds: yup.number().positive().transform(emptyStringToNull).nullable(),
  usageDurationSeconds: yup.array(yup.number()),
  timeRequiredBetweenEachBookingSeconds: yup.number().nullable().positive().transform(emptyStringToNull),
  bookingIntervalPerUserInSeconds: yup.number().positive().transform(emptyStringToNull).nullable(),
  maximumBookingsAtSameTime: yup.number().positive().transform(emptyStringToNull).nullable(),
  maximumOfResidentsInOneBooking: yup.number().positive().transform(emptyStringToNull).nullable(),
  bookingRequiresStaffApproval: yup.boolean(),
  allowMultipleBookings: yup
    .boolean()
    .nullable()
    .transform((value) => (value === null ? false : value)),
  businessHours: yup.array(businessHour),
})

export const reservationTypeDefinition = {
  SingleDate: 'SINGLE_DATE',
  MultipleDate: 'MULTIPLE_DATE',
  SingleDateTime: 'SINGLE_DATE_TIME',
} as const

export const amenitySchema = yup.object({
  id: yup.string(),
  title: yup.string().required(i18n.t('input:is-required', { field: amenityi18nFields.title })),
  description: yup.string(),
  reservationType: yup.mixed(),
  project: yup.object({ id: yup.string() }),
  image: yup.mixed(),
  termsFile: yup.mixed(),
  disclaimerFile: yup.mixed(),
  sharedWithProjects: yup.array(yup.object({ id: yup.string(), name: yup.string() })),
  bookingOptions: yup
    .array(bookingOptions)
    .test('isValid', t('error-business-hours-overlap-issue-description'), (value) => {
      return !doesBusinessHourOverlap(value as BookingOptions[])
    }),
})

export type Amenity = yup.InferType<typeof amenitySchema>
export type BookingOptions = yup.InferType<typeof bookingOptions>
export type BusinessHour = yup.InferType<typeof businessHour>

export const availableReservationTypes = [
  {
    value: reservationTypeDefinition.SingleDate,
    label: t('fields-amenity:single-date-reservation'),
  },
  {
    value: reservationTypeDefinition.MultipleDate,
    label: t('fields-amenity:multiple-days-reservation'),
  },
  {
    value: reservationTypeDefinition.SingleDateTime,
    label: t('fields-amenity:single-date-reservation-with-time'),
  },
] as const

const doesBusinessHourOverlap = (bookingOptions?: BookingOptions[]) => {
  return bookingOptions?.some((bookingOptionA, indexA) => {
    return bookingOptions?.some((bookingOptionB, indexB) => {
      if (indexA === indexB) {
        return false
      }

      return bookingOptionB.businessHours?.some((businessHourB) =>
        bookingOptionA.businessHours?.some((businessHourA) => businessHoursOverlap(businessHourA, businessHourB)),
      )
    })
  })
}

export function businessHoursOverlap(businessHourA: BusinessHour, businessHourB: BusinessHour) {
  const bothAreOpened = !businessHourA.isClosed && !businessHourB.isClosed
  const sameDay = businessHourA.dayOfWeek === businessHourB.dayOfWeek
  return bothAreOpened && sameDay && hoursOverlap(businessHourA, businessHourB)
}

function hoursOverlap(
  businessHourA: { openAtMS?: number; closeAtMS?: number },
  businessHourB: { openAtMS?: number; closeAtMS?: number },
) {
  return (
    (businessHourA.openAtMS ?? 0) < (businessHourB.closeAtMS ?? 0) &&
    (businessHourA.closeAtMS ?? 0) > (businessHourB.openAtMS ?? 0)
  )
}
