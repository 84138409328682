/* eslint-disable react/no-unknown-property */
import { ErrorMessage } from '@hookform/error-message'
import { ErrorMessage as ErrorMessageComponent } from '../../ErrorMessage'
import { Colors, Spacing } from '@walter/shared'
import { WebDateUtils } from '../../../utils/date'
import React, { ChangeEvent, useState } from 'react'
import Calendar from 'react-calendar/dist/entry.nostyle'
import { useFormContext, useWatch } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { boxShadowDark } from '../../../styles/global'
import { i18n, t } from '../../../utils'
import { useOutsideAlerter } from '../../../utils/hooks'
import { Button } from '../../Button'
import { ButtonGroup } from '../../ButtonGroup'
import { Icon } from '../../Icon'
import { Input } from '../../Input'
import { DatePickerStyled } from './styles'

export type DatePickerPosition = 'top' | 'bottom'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CalendarWrap = styled.div<{ withTime: boolean; position: DatePickerPosition }>`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: ${Colors.white};
  margin-top: ${Spacing.small};

  ${(props) =>
    props.withTime &&
    css`
      margin-bottom: ${Spacing.xLarge};
      padding: ${Spacing.large};
      ${boxShadowDark};
      .react-calendar {
        box-shadow: none;
      }
    `}

  ${(props) =>
    props.position === 'top' &&
    css`
      top: auto;
      bottom: 100%;
      margin-top: 0;
      margin-bottom: ${Spacing.small};
    `}
  
  .react-calendar__month-view__days button:disabled {
    color: ${Colors.greyLight};
  }
`

const Arrow = styled.span`
  display: flex;
  padding: ${Spacing.tiny};
`

const ManuallyCenteredDiv = styled.div`
  margin-top: 31px;
  margin-left: -25px;
  cursor: pointer;
  z-index: 2;
`

type DatePickerProps = {
  dataTestId?: string
  name: string
  label: string
  value: Date | null
  placeholder?: string
  position?: DatePickerPosition
  withTime?: boolean
  calendarProps?: any // https://github.com/wojtekmaj/react-calendar/blob/master/src/Calendar.jsx
  minDate?: Date
  isClearable?: boolean
  disabled?: boolean
  errors:
    | {
        [x: string]: any
      }
    | undefined
  additionalOnChange?: (date?: Date) => void
}

export const DatePicker = React.memo(
  ({
    dataTestId,
    name,
    label,
    value,
    placeholder,
    position = 'bottom',
    withTime,
    calendarProps,
    minDate,
    isClearable = false,
    disabled = false,
    errors,
    additionalOnChange,
  }: DatePickerProps) => {
    const [calendarVisible, setCalendarVisible] = useState(false)
    const wrapperRef = React.useRef(null)
    const { setValue } = useFormContext()
    const internalValue = useWatch({ name })
    if (value) setValue(name, value)

    useOutsideAlerter(wrapperRef, () => setCalendarVisible(false))

    function handleCalendarChange(newDate: Date) {
      additionalOnChange && additionalOnChange(newDate)

      if (withTime) {
        setValue(name, newDate)
      } else {
        setCalendarVisible(false)
        setValue(name, newDate)
      }
    }

    function handleClearCalendar() {
      setValue(name, null)
    }

    function convertHourMinutes(time: string) {
      const [hours, minutes] = time?.length > 0 ? time.split(':').map((val) => parseInt(val)) : [0, 0]

      return WebDateUtils.dayjs(internalValue ?? new Date())
        .set('hours', hours)
        .set('minutes', minutes)
        .toDate()
    }

    function handleClickConfirm() {
      const dateTime = convertHourMinutes(time)

      setValue(name, dateTime)
      setCalendarVisible(false)
    }

    function handleTimeChange(e: ChangeEvent<HTMLInputElement>) {
      const dateWithTime = convertHourMinutes(e.target.value)
      additionalOnChange && additionalOnChange(dateWithTime)

      setValue(name, dateWithTime)
    }

    const time = React.useMemo(() => {
      if (typeof internalValue === 'string') {
        return WebDateUtils.format(new Date(internalValue) || new Date(), 'HH:mm')
      }

      return WebDateUtils.format(internalValue || new Date(), 'HH:mm')
    }, [internalValue])

    const formattedTextInputValue = React.useMemo(() => {
      if (!internalValue) {
        return ''
      }

      if (withTime) {
        if (typeof internalValue === 'string') {
          return WebDateUtils.format(new Date(internalValue) || new Date(), 'YYYY-MM-DD - HH:mm')
        }
        return WebDateUtils.format(internalValue || new Date(), 'YYYY-MM-DD - HH:mm')
      }

      if (typeof internalValue === 'string') {
        return WebDateUtils.format(new Date(internalValue) || new Date(), 'YYYY-MM-DD')
      }

      return WebDateUtils.format(internalValue || new Date(), 'YYYY-MM-DD')
    }, [withTime, internalValue])

    return (
      <DatePickerStyled data-cy="pick-a-date">
        <Container data-test-id={dataTestId}>
          <Input
            dataTestId={`${dataTestId}_Input`}
            label={label}
            placeholder={placeholder}
            onClick={() => (!disabled ? setCalendarVisible(true) : null)}
            value={formattedTextInputValue}
            readOnly
          />
          {calendarVisible && (
            <CalendarWrap ref={wrapperRef} position={position} withTime={!!withTime}>
              <Calendar
                locale={i18n.language}
                onChange={(newDate) => handleCalendarChange(Array.isArray(newDate) ? newDate[0] : newDate)}
                value={value}
                minDate={minDate}
                prevLabel={
                  <Arrow data-test-id={`${dataTestId}_Left_Button`}>
                    <Icon icon="left-chevron" size="small" />
                  </Arrow>
                }
                nextLabel={
                  <Arrow data-test-id={`${dataTestId}_Right_Button`}>
                    <Icon icon="right-chevron" size="small" />
                  </Arrow>
                }
                {...calendarProps}
              />
              {withTime && (
                <>
                  <div
                    css={`
                      margin-top: ${Spacing.medium};
                    `}
                  >
                    <Input
                      dataTestId={`${dataTestId}_Time_Input`}
                      value={time}
                      type="time"
                      label="Time"
                      onChange={handleTimeChange}
                    />
                  </div>

                  <div
                    css={`
                      margin-top: ${Spacing.large};
                      display: flex;
                      justify-content: flex-end;
                    `}
                  >
                    <ButtonGroup>
                      <Button
                        dataTestId={`${dataTestId}_Cancel_Button`}
                        testID="cancel-button"
                        size="small"
                        onClick={() => setCalendarVisible(false)}
                      >
                        {t('cancel')}
                      </Button>
                      <Button
                        dataTestId={`${dataTestId}_Confirm_Button`}
                        testID="confirm-button"
                        size="small"
                        theme="primary"
                        onClick={handleClickConfirm}
                      >
                        {t('confirm')}
                      </Button>
                    </ButtonGroup>
                  </div>
                </>
              )}
            </CalendarWrap>
          )}
          {isClearable && (
            <ManuallyCenteredDiv onClick={handleClearCalendar}>
              <Icon size={'small'} icon="close" color={Colors.greyLight} />
            </ManuallyCenteredDiv>
          )}
        </Container>
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <ErrorMessageComponent data-cy="error-message">{message}</ErrorMessageComponent>}
        />
      </DatePickerStyled>
    )
  },
)
