import React from 'react'
import { t } from '../../utils'
import { Icon } from '../Icon'
import { Input } from '../Input'
import { Colors } from '@walter/shared'
import { animations } from '../../styles'
import styled from 'styled-components'

const DEFAULT_WAITING_TIME = 700

type SearchInputProps = {
  value?: string
  onTextChange?: (value: string) => any
  waitingTime?: number
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => any
  resetOnEnter?: boolean
  isLoading?: boolean
}

export const SearchInput = ({
  onTextChange,
  waitingTime = DEFAULT_WAITING_TIME,
  onKeyDown,
  resetOnEnter,
  isLoading,
  ...rest
}: SearchInputProps) => {
  const [searchTextValue, setSearchTextValue] = React.useState('')
  const inputRef = React.useRef(null)
  const timer = React.useRef<number | null>(null)

  React.useEffect(() => {
    return () => {
      timer.current = null
    }
  }, [])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      const target = e.target as HTMLInputElement
      onTextChange?.(target.value)
      if (resetOnEnter) {
        setSearchTextValue('')
      }
    }
    if (e.key === 'Enter' && resetOnEnter) {
      setSearchTextValue('')
    }
    if (onKeyDown) {
      onKeyDown(e)
    }
  }

  const handleTextChange = (text: string) => {
    onTextChange?.(text)
    setSearchTextValue('')
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    setSearchTextValue(e.target.value)
    timer.current = window.setTimeout(handleTextChange, waitingTime, e.target.value)
  }

  return (
    <Input
      dataTestId="Search_Input"
      ref={inputRef}
      type="text"
      placeholder={t('placeholder')}
      onChange={onChange}
      value={searchTextValue}
      onKeyDown={handleKeyDown}
      addon={
        isLoading ? (
          <InputLoader>
            <Icon icon="loading" size="small" color={Colors.primaryColor} />
          </InputLoader>
        ) : (
          <Icon icon="search" size="small" />
        )
      }
      {...rest}
    />
  )
}

const InputLoader = styled.div`
  animation: ${animations.rotate} 1.5s linear infinite;
  height: 16px;
  width: 16px;
`
