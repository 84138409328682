import React from 'react'
import styled from 'styled-components'
import { Colors } from '@walter/shared'
import { fontWeights, lineHeights } from '../../styles/typography'

const getFontSize = ({ size }: { size: number }) => {
  if (size === 1) return '32px'
  if (size === 2) return '24px'
  if (size === 3) return '20px'
  if (size === 4) return '18px'
  if (size === 5) return '16px'
  if (size === 6) return '13px'

  return '16px'
}

const Container = styled.div<{ size: number }>`
  font-weight: ${fontWeights.bold};
  font-size: ${getFontSize};
  line-height: ${lineHeights.heading};
  color: ${Colors.greyDark};
  margin-bottom: 0.25em;

  &:last-child {
    margin-bottom: 0;
  }
`

type HeadingProps = React.ComponentPropsWithoutRef<'div'> & {
  size?: 1 | 2 | 3 | 4 | 5 | 6
  children: React.ReactNode
}

export const Heading = ({ size = 5, children, ...rest }: HeadingProps) => (
  <Container as={`h${size}`} size={+size} {...rest}>
    {children}
  </Container>
)
