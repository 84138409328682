import { Colors, Spacing } from '@walter/shared'
import React from 'react'
import { Calendar as RBCalendar, Formats, dayjsLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import styled, { css } from 'styled-components'
import { borderRadius, boxShadow } from '../../styles/global'
import { WebDateUtils, getLanguage } from '../../utils'
import { Styles } from './styles'
import { Toolbar, ToolbarProps } from './Toolbar'
import { CalendarView } from './types'

const localizer = dayjsLocalizer(WebDateUtils.dayjs)

interface ContainerProps {
  shouldFlex: boolean
}
const Container = styled.div`
  ${(props: ContainerProps) => {
    return css`
      flex: 1;
      display: flex;
      position: relative;
      flex-direction: column;
      padding: ${Spacing.xLarge};
      background-color: ${Colors.white};
      border-radius: ${borderRadius};
      border: 1px solid ${Colors.borderColor};
      max-height: 850px;
      z-index: 0;
      ${boxShadow};

      .rbc-calendar {
        flex: ${props.shouldFlex ? '1 !important' : 'none'};
      }
    `
  }}
`

type CalendarProps = {
  dataTestId?: string
  localizer?: any
  selectable?: boolean
  view: CalendarView
  isLoadingData?: boolean
  toolbarProps?: ToolbarProps
  onViewChange?: (view: string) => void
  onNavigate?: React.Dispatch<React.SetStateAction<Date>>
  selectedData?: any
  events?: any
  onSelectEvent?: React.Dispatch<any>
  eventPropGetter?: (event: any) => {
    style: {
      backgroundColor: string
    }
  }
  onSelectSlot?: (data: any) => void
  date?: Date
}

export const Calendar = ({
  dataTestId,
  isLoadingData,
  toolbarProps,
  view = 'day',
  onViewChange,
  ...rest
}: CalendarProps) => {
  const language = getLanguage()
  const formats: Formats = {
    dayHeaderFormat: (date, _, localizer) => localizer?.format(date, 'LL', language) ?? new Date(date).toString(),
    dayRangeHeaderFormat: ({ start, end }, culture, localizer) => {
      return language.startsWith('fr')
        ? localizer?.format(start, 'D', culture) + ' au ' + localizer?.format(end, 'D MMMM', culture)
        : localizer?.format(start, 'MMMM D', culture) + ' — ' + localizer?.format(end, 'D', culture)
    },
  }

  const shouldFlex = view === 'day' || view === 'week'
  return (
    <>
      <Styles />
      <Container
        data-test-id={`${dataTestId}_Calendar_Container`}
        data-cy="calendar-container"
        shouldFlex={!shouldFlex}
      >
        <RBCalendar<any>
          formats={formats}
          style={!shouldFlex ? {} : { height: '54vh', maxHeight: '750px' }}
          localizer={localizer}
          popup
          selectable
          step={30}
          timeslots={1}
          view={view}
          onView={() => null}
          {...rest}
          components={{
            toolbar: (props) => (
              <Toolbar
                {...props}
                {...toolbarProps}
                viewOptions={toolbarProps?.viewOptions ?? []}
                isLoadingData={isLoadingData}
                onViewChange={onViewChange}
                view={view}
              />
            ),
          }}
          scrollToTime={WebDateUtils.date().set('hour', 8).set('minute', 0).toDate()}
        />
      </Container>
    </>
  )
}
