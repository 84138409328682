import { Spacing } from '@walter/shared'
import React from 'react'
import styled from 'styled-components'
import { FilterResultsList } from '../FilterBar/FilterResultsList'

const Container = styled.div`
  margin-bottom: ${Spacing.large};
`

interface FilterBarNarrowProps<Filter> {
  dataTestId?: string
  filters: React.ReactNode
  numberOfItems: number
  selectedFilters: Filter[]
  onRemoveFilterTag: (f: Filter) => void
  loadingItems?: boolean
}

export function FilterBar2<Filter extends { path: string; label: string; value: string | number }>({
  dataTestId,
  filters,
  numberOfItems,
  loadingItems,
  selectedFilters,
  onRemoveFilterTag,
}: FilterBarNarrowProps<Filter>) {
  return (
    <Container data-test-id={`${dataTestId}_FilterBar_Container`}>
      {filters}
      {selectedFilters.length > 0 && (
        <FilterResultsList
          onRemoveResult={onRemoveFilterTag}
          results={selectedFilters}
          numberOfFoundItems={numberOfItems}
          loadingSearchResults={!!loadingItems}
        />
      )}
    </Container>
  )
}
