import React from 'react'
import { useParams } from 'react-router-dom'
import { t } from '../../utils'
import { Icon } from '../Icon'
import { MultiProjects } from '../MultiProjects'
import { Pill } from '../Pill'
import { PillGroup } from '../PillGroup'
import { ResourceItem } from '../ResourceItem'
import { FolderType } from './helpers'

type FolderProps = {
  folder: FolderType
  numberOfFilesInIt: number
  onClick: () => void
  handleClickOnEdit?: () => void
  isShared?: boolean
}

export function Folder({ folder, onClick, handleClickOnEdit, numberOfFilesInIt, isShared }: FolderProps) {
  const { projectId } = useParams<{ projectId: string }>()

  const secondaryAction = React.useMemo(() => {
    if (handleClickOnEdit) {
      return {
        disabled: isShared && projectId !== 'all',
        label: t('edit'),
        onClick: handleClickOnEdit,
      }
    }
    return null
  }, [handleClickOnEdit])

  const Description = React.useMemo(() => {
    // Don't show anything if segments isn't specified. (Valid when using this component in resident-web for example)
    if (!folder.segments) {
      return null
    }

    if (folder.segments.length > 0) {
      return (
        <PillGroup dataTestId="PillGroup_Folder_Item">
          {folder.segments.map((segment) => (
            <Pill dataTestId="Pill_Folder_Item" key={segment.id} text={segment.title} />
          ))}
        </PillGroup>
      )
    }

    return <Pill dataTestId="Pill_Folder_Item" text={t('no-group-restriction')} />
  }, [folder])

  return (
    <ResourceItem
      name={`${folder.name}${numberOfFilesInIt > 0 ? ` (${numberOfFilesInIt})` : ``}`}
      figure={isShared ? <Icon icon="shared-folder" /> : <Icon icon="folder" />}
      renderDescription={Description ? () => Description : () => null}
      handleClick={onClick}
      secondaryAction={secondaryAction}
      extra={projectId === 'all' || isShared ? <MultiProjects projects={folder?.projects || []} /> : null}
    />
  )
}
