import * as yup from 'yup'
import { i18n, t } from '../../utils'

export const FaqItemi18nFields = {
  projects: t('selected-project'),
  author: t('author'),
  type: t('state'),
  question: t('question'),
}

export const FaqItemSchema = yup.object({
  projects: yup
    .array()
    .of(
      yup
        .object({ id: yup.string(), name: yup.string() })
        .required(i18n.t('input:is-required', { field: FaqItemi18nFields.projects })),
    )
    .min(1),
  author: yup.object({
    id: yup.string(),
    firstName: yup.string().nullable().optional(),
    lastName: yup.string().nullable().optional(),
  }),
  type: yup.string().optional().nullable(),
  question: yup.string().optional().nullable(),
  answer: yup.string().optional().nullable(),
})

export const faqItemUpdateSchema = FaqItemSchema.clone().concat(yup.object({ id: yup.string() }))

export type FaqFormItem = yup.InferType<typeof FaqItemSchema>
export type FaqFormItemUpdate = yup.InferType<typeof faqItemUpdateSchema>
