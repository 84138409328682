import * as yup from 'yup'
import { i18n, t } from '../../utils'

export const Taski18nFields = {
  associatedProject: t('project'),
  title: t('title'),
  categoryId: t('category'),
  statusId: t('status'),

  assignToIds: t('managers'),
  associateTo: t('associateTo'),

  priorityStatus: t('priority'),
  description: t('description'),

  attachments: t('files'),
  pictures: t('images'),
}

export const taskSchema = yup.object({
  associatedProject: yup
    .object({
      id: yup.string().required(i18n.t('input:is-required', { field: Taski18nFields.associatedProject })),
    })
    .required(i18n.t('input:is-required', { field: Taski18nFields.associatedProject }))
    .typeError(i18n.t('input:is-required', { field: Taski18nFields.associatedProject })),
  title: yup.string().required(i18n.t('input:is-required', { field: Taski18nFields.title })),
  category: yup
    .object({
      id: yup.string(),
    })
    .typeError(i18n.t('input:is-required', { field: Taski18nFields.categoryId }))
    .default(undefined)
    .required(i18n.t('input:is-required', { field: Taski18nFields.categoryId })),
  status: yup
    .object({ id: yup.string() })
    .typeError(i18n.t('input:is-required', { field: Taski18nFields.statusId }))
    .default(undefined)
    .required(i18n.t('input:is-required', { field: Taski18nFields.statusId })),
  assignedToIds: yup.array().of(yup.object({ id: yup.string() })),
  associatedProperties: yup.array().of(yup.object({ id: yup.string() })),
  associatedResidents: yup.array().of(yup.object({ id: yup.string() })),
  associatedSegments: yup.array().of(yup.object({ id: yup.string() })),

  priorityStatus: yup.object({ id: yup.string() }).optional(),
  description: yup.string().optional(),

  attachments: yup.mixed().optional(),
  pictures: yup.mixed().optional(),
})

export type TaskFormSchema = yup.InferType<typeof taskSchema>
