import * as yup from 'yup'
import { i18n, t } from '../../utils'

export const Logini18nFields = {
  email: t('email-address'),
  password: t('login-page-password'),
}

export const LoginSchema = yup.object({
  email: yup
    .string()
    .email(t('enter-email-invalid'))
    .required(i18n.t('input:is-required', { field: Logini18nFields.email })),
  password: yup
    .string()
    .min(8, t('password-error-length'))
    .required(i18n.t('input:is-required', { field: Logini18nFields.password })),
})

export type Login = yup.InferType<typeof LoginSchema>
