import { Colors, Spacing, Types } from '@walter/shared'
import { rgba, stripUnit } from 'polished'
import React, { InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import { animationCurve, animationTime, borderRadius, inputHeight } from '../../../styles/global'
import { fontSizes, lineHeights } from '../../../styles/typography'
import { Button, ButtonProps } from '../../Button'
import { ErrorMessage } from '@hookform/error-message'
import { ErrorMessage as ErrorMessageComponent } from '../../ErrorMessage'
import { Label } from '../../Label'

const Container = styled.div`
  display: block;
  width: 100%;
`

const Wrap = styled.div`
  position: relative;
  display: flex;
`

const InputElement = styled.input<{ variant: Types.Variant; error: boolean }>`
  flex: 1;
  height: ${inputHeight};
  border-radius: ${borderRadius};
  box-shadow: inset 0 0 0 1px ${Colors.borderColor};
  background-color: ${Colors.white};
  width: 100%;
  resize: none;
  color: ${Colors.grey};
  vertical-align: top;
  outline: 0;
  padding: 0 ${Spacing.medium};
  transition: background-color ${animationTime} ${animationCurve}, color ${animationTime} ${animationCurve},
    box-shadow ${animationTime} ${animationCurve};

  &::placeholder {
    color: ${rgba(Colors.grey, 0.3)};
  }

  ${(props) =>
    props.variant === 'rounded' &&
    `
    border-radius: ${Spacing.large};
  `}

  ${(props) =>
    props.error &&
    `
    box-shadow: inset 0 0 0 1px ${Colors.red}, 0 0 0 1px ${Colors.red};
  `}

  &:focus {
    background-color: ${Colors.white};
    box-shadow: inset 0 0 0 1px ${Colors.primaryColor}, 0 0 0 1px ${Colors.primaryColor};
  }

  &[disabled] {
    pointer-events: none;
    user-select: none;
    opacity: 0.5; /* Overwrite iOS styles */
  }
`

const TextareaElement = styled(InputElement)<{ textareaSize: string }>`
  padding: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`} ${Spacing.medium};
  min-height: 120px;
  resize: vertical;
  line-height: ${lineHeights.base};

  ${(props) =>
    props.textareaSize === 'large' &&
    `
    min-height: 240px;
  `}
`

const Addon = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  right: ${Spacing.medium};
  font-size: ${fontSizes.small};
`

const Hint = styled.span`
  margin-left: ${`${(stripUnit(Spacing.tiny) as number) * 0.5}px`};
`

const RightActionWrap = styled.div`
  margin-left: ${Spacing.small};
`
type InputType =
  | 'textarea'
  | 'tel'
  | 'date'
  | 'file'
  | 'color'
  | 'email'
  | 'image'
  | 'radio'
  | 'reset'
  | 'hidden'
  | 'number'
  | 'search'
  | 'submit'
  | 'password'
  | 'text'
  | 'time'
  | 'textarea'

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  dataTestId?: string
  label?: string
  hint?: string
  variant?: Types.Variant
  meta?: {
    error?: string
    touched?: boolean
  }
  addon?: React.ReactNode
  value?: string | number
  type?: InputType
  size?: string
  rightAction?: ButtonProps & { label: string }
  required?: boolean
  errors: any
}

export const Input = React.forwardRef<HTMLButtonElement, InputProps>(
  // @ts-ignore TODO: check typing issue
  (
    {
      dataTestId,
      label,
      rightAction,
      addon,
      hint,
      variant = 'form',
      value,
      type,
      size,
      required,
      name,
      errors,
      ...rest
    }: InputProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const isTextArea = type === 'textarea'

    return (
      <Container data-cy="input-field">
        {label && (
          <Label htmlFor={name}>
            {required ? label + '*' : label}
            {hint && <Hint data-tip={hint}>(?)</Hint>}
          </Label>
        )}
        <Wrap>
          {isTextArea ? (
            // @ts-expect-error input `as` textarea makes ref expect textarea ref
            <TextareaElement
              data-test-id={dataTestId}
              data-cy={label}
              data-testid={name}
              ref={ref}
              variant={variant}
              as="textarea"
              error={name && !!errors[name]}
              value={ref ? undefined : value || ''}
              type={type}
              textareaSize={size}
              name={name}
              {...rest}
            />
          ) : (
            <InputElement
              data-test-id={dataTestId}
              ref={ref}
              height={300}
              error={!!name && !!errors[name]}
              variant={variant}
              value={value}
              type={type || 'text'}
              data-cy={label}
              data-testid={name}
              name={name}
              {...rest}
            />
          )}
          {addon && <Addon>{addon}</Addon>}
          {rightAction && (
            <RightActionWrap>
              <Button testID={'right-action-wrap-button'} {...rightAction}>
                {rightAction.label}
              </Button>
            </RightActionWrap>
          )}
        </Wrap>
        {name && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <ErrorMessageComponent data-cy="error-message">{message}</ErrorMessageComponent>}
          />
        )}
      </Container>
    )
  },
)

Input.displayName = 'Input'
