import React, { useState } from 'react'
import { Colors, Spacing } from '@walter/shared'
import { WebDateUtils } from '../../utils/date'
import Calendar from 'react-calendar/dist/entry.nostyle'
import Select from 'react-select'
import styled, { css } from 'styled-components'
import { boxShadowDark } from '../../styles/global'
import { useOutsideAlerter } from '../../utils/hooks'
import { Icon } from '../Icon'
import { Label } from '../Label'
import { DatePickerStyled } from './styles'

const Container = styled.div`
  position: relative;
`

const CalendarWrap = styled.div<{ withTime: boolean; position: string }>`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: ${Colors.white};
  margin-top: ${Spacing.small};

  withTime
  ${(props) =>
    props.withTime &&
    css`
      margin-bottom: ${Spacing.xLarge};
      padding: ${Spacing.large};
      ${boxShadowDark};
      .react-calendar {
        box-shadow: none;
      }
    `}

  ${(props) =>
    props.position === 'top' &&
    css`
      top: auto;
      bottom: 100%;
      margin-top: 0;
      margin-bottom: ${Spacing.small};
    `}
`

const Arrow = styled.span`
  display: flex;
  padding: ${Spacing.tiny};
`

type MultipleDatePickerProps = {
  dataTestId?: string
  value: Date[]
  onChange: (dates: Date[]) => any
  label?: string
  position?: string
  withTime?: boolean
}

export const MultipleDatePicker = React.memo(
  ({ dataTestId, label, value, onChange, position = 'bottom', withTime }: MultipleDatePickerProps) => {
    const [calendarVisible, setCalendarVisible] = useState(false)
    const wrapperRef = React.useRef(null)
    useOutsideAlerter(wrapperRef, () => setCalendarVisible(false))

    const datesFormattedForMultiple = value.map((date) => ({
      label: WebDateUtils.format(date, 'YYYY-MM-DD'),
      value: date,
    }))

    return (
      <DatePickerStyled>
        <Container data-test-id={`${dataTestId}_DatePicker`}>
          <Label>{label}</Label>
          <Select
            id={`${dataTestId}_Select`}
            onChange={(options) => onChange(options?.map((o) => o.value) || [])}
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            value={datesFormattedForMultiple}
            options={datesFormattedForMultiple}
            openMenuOnClick={false}
          />
          {calendarVisible && (
            <CalendarWrap
              data-test-id={`${dataTestId}_Calendar`}
              ref={wrapperRef}
              position={position}
              withTime={!!withTime}
            >
              <Calendar
                value={undefined}
                onChange={(date) => {
                  // TODO: Fix this and test the component. Not sure if it's working correctly
                  // @ts-ignore
                  onChange([...(value || []), date])
                  setCalendarVisible(false)
                }}
                prevLabel={
                  <Arrow>
                    <Icon icon="left-chevron" size="small" />
                  </Arrow>
                }
                nextLabel={
                  <Arrow>
                    <Icon icon="right-chevron" size="small" />
                  </Arrow>
                }
              />
            </CalendarWrap>
          )}
        </Container>
      </DatePickerStyled>
    )
  },
)
