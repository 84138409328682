import { Colors, Spacing, Types } from '@walter/shared'
import { rgba, stripUnit } from 'polished'
import React, { InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import { animationCurve, animationTime, borderRadius, inputHeight } from '../../styles/global'
import { fontSizes, lineHeights } from '../../styles/typography'
import { Button, ButtonProps } from '../Button'
import { ErrorMessage } from '../ErrorMessage'
import { Label } from '../Label'

const Container = styled.div`
  display: block;
  width: 100%;
`

const Wrap = styled.div`
  position: relative;
  display: flex;
`

const Field = styled.input<{ variant?: Types.Variant; error: boolean }>`
  flex: 1;
  height: ${inputHeight};
  border-radius: ${borderRadius};
  box-shadow: inset 0 0 0 1px ${Colors.borderColor};
  background-color: ${Colors.white};
  width: 100%;
  resize: none;
  color: ${Colors.grey};
  vertical-align: top;
  outline: 0;
  padding: 0 ${Spacing.medium};
  transition: background-color ${animationTime} ${animationCurve}, color ${animationTime} ${animationCurve},
    box-shadow ${animationTime} ${animationCurve};

  &::placeholder {
    color: ${rgba(Colors.grey, 0.3)};
  }

  ${(props) =>
    props.variant === 'rounded' &&
    `
    border-radius: ${Spacing.large};
  `}

  ${(props) =>
    props.error &&
    `
    box-shadow: inset 0 0 0 1px ${Colors.red}, 0 0 0 1px ${Colors.red};
  `}

  &:focus {
    background-color: ${Colors.white};
    box-shadow: inset 0 0 0 1px ${Colors.primaryColor}, 0 0 0 1px ${Colors.primaryColor};
  }

  &[disabled] {
    pointer-events: none;
    user-select: none;
    opacity: 0.5; /* Overwrite iOS styles */
  }
`

const Textarea = styled(Field)<{ textareaSize: string }>`
  padding: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`} ${Spacing.medium};
  min-height: 120px;
  resize: vertical;
  line-height: ${lineHeights.base};

  ${(props) =>
    props.textareaSize === 'large' &&
    `
    min-height: 240px;
  `}
` as any // Could be React.Ref<HTMLTextAreaElement>, but produces JSX element type 'Textarea' does not have any construct or call signatures

const Addon = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  right: ${Spacing.medium};
  font-size: ${fontSizes.small};
`

const Hint = styled.span`
  margin-left: ${`${(stripUnit(Spacing.tiny) as number) * 0.5}px`};
`

const RightActionWrap = styled.div`
  margin-left: ${Spacing.small};
`

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  dataTestId?: string
  label?: string
  textarea?: boolean
  hint?: string
  field?: InputHTMLAttributes<HTMLInputElement>
  variant?: Types.Variant
  meta?: {
    error?: string
    touched?: boolean
  }
  addon?: React.ReactNode
  value?: string | number
  type?: string
  size?: string
  rightAction?: ButtonProps & { label: string }
  required?: boolean
  maxLength?: number
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      dataTestId,
      label,
      field = {},
      rightAction,
      textarea,
      addon,
      hint,
      variant,
      meta = {},
      value,
      type,
      size,
      required,
      maxLength,
      ...rest
    }: InputProps,
    ref,
  ) => {
    return (
      <Container data-cy="input-field">
        {label && (
          <Label>
            {required ? label + '*' : label}
            {hint && <Hint data-tip={hint}>(?)</Hint>}
          </Label>
        )}
        <Wrap>
          {!textarea && (
            <Field
              data-test-id={dataTestId}
              ref={ref}
              error={!!meta.error && !!meta.touched}
              {...field}
              value={value}
              type={type || 'text'}
              data-cy={label}
              variant={variant}
              maxLength={maxLength ? maxLength : undefined}
              {...rest}
            />
          )}
          {textarea && (
            <Textarea
              data-test-id={dataTestId}
              data-cy={label}
              ref={ref}
              as="textarea"
              error={meta.error && meta.touched}
              {...field}
              value={ref ? null : value || ''}
              type={type}
              textareaSize={size}
              maxLength={maxLength ? maxLength : undefined}
              {...rest}
            />
          )}
          {addon && <Addon>{addon}</Addon>}
          {rightAction && (
            <RightActionWrap>
              <Button dataTestId={dataTestId} testID={'right-action-wrap-button'} {...rightAction}>
                {rightAction.label}
              </Button>
            </RightActionWrap>
          )}
        </Wrap>
        {meta.touched && meta.error && <ErrorMessage data-cy="error-message">{meta.error}</ErrorMessage>}
      </Container>
    )
  },
)

Input.displayName = 'Input'
