import * as yup from 'yup'
import { i18n, t } from '../../utils'

export const EnterEmaili18nFields = {
  email: t('email-address'),
}

export const EnterEmailSchema = yup.object({
  email: yup
    .string()
    .email(t('enter-email-invalid'))
    .required(i18n.t('input:is-required', { field: EnterEmaili18nFields.email })),
})

export type EnterEmail = yup.InferType<typeof EnterEmailSchema>
