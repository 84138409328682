import * as yup from 'yup'
import { i18n, t } from '../../utils'

export const EnterPasswordi18nFields = {
  password: t('auth:enter-password-page-password-input-label'),
}

export const EnterPasswordSchema = yup.object({
  password: yup
    .string()
    .min(8, t('password-error-length'))
    .required(i18n.t('input:is-required', { field: EnterPasswordi18nFields.password })),
})

export type EnterPassword = yup.InferType<typeof EnterPasswordSchema>
