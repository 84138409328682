import { WebDateUtils } from '../../../src/utils/date'
import React from 'react'
import { useParams } from 'react-router-dom'
import { t } from '../../utils'
import { Icon } from '../Icon'
import { ResourceItem } from '../ResourceItem'

type FileProps = {
  file: { name: string; createdAt: Date; isFromSharedFolder: boolean }
  onClick: () => void
  handleClickOnEdit?: () => void
}

export function File({ file, onClick, handleClickOnEdit }: FileProps) {
  const { projectId } = useParams<{ projectId: string }>()

  const secondaryAction = React.useMemo(() => {
    if (handleClickOnEdit) {
      if (file.isFromSharedFolder && projectId !== 'all') {
        return {
          disabled: true,
          label: t('edit'),
          onClick: handleClickOnEdit,
        }
      }
      return {
        label: t('edit'),
        onClick: handleClickOnEdit,
      }
    }
    return null
  }, [handleClickOnEdit])

  return (
    <ResourceItem
      name={file.name}
      figure={<Icon icon="attachment" />}
      description={`Added on ${WebDateUtils.dayjs(file.createdAt).format('LLLL')}`}
      handleClick={onClick}
      secondaryAction={secondaryAction}
    />
  )
}
