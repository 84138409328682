import React from 'react'
import { Colors } from '@walter/shared'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import styled from 'styled-components'
import { animationCurve, animationTime, borderRadius } from '../../styles/global'
import { fontSizes } from '../../styles/typography'

const QuillWrapper = styled.div<{ isFocused: boolean }>`
  .quill {
    .ql-toolbar.ql-snow {
      border: 1px solid ${Colors.borderColor};
      border-top-left-radius: ${borderRadius};
      border-top-right-radius: ${borderRadius};
      background-color: ${Colors.offWhite};
    }

    .ql-container.ql-snow {
      font-size: ${fontSizes.regular};
      /* 320 px because right now we use it in post and event and it's often big text that goes there */
      min-height: 320px;
      background-color: ${Colors.white};
      border: 1px solid ${Colors.borderColor};
      border-bottom-left-radius: ${borderRadius};
      border-bottom-right-radius: ${borderRadius};
      transition: background-color ${animationTime} ${animationCurve}, border-color ${animationTime} ${animationCurve},
        color ${animationTime} ${animationCurve}, box-shadow ${animationTime} ${animationCurve};

      ${(props) =>
        props.isFocused &&
        `
        box-shadow: inset 0 0 0 1px ${Colors.primaryColor}, 0 0 0 1px ${Colors.primaryColor};
        background-color: ${Colors.white};
      `}

      .ql-editor {
        min-height: 320px;
        color: ${Colors.grey};
      }
    }
  }
`

type RichTextareaProps = {
  dataTestId?: string
  value: string
  onChange: (content: any) => void
}

export const RichTextarea = ({ dataTestId, value = '', onChange, ...rest }: RichTextareaProps) => {
  const [isFocused, setIsFocused] = React.useState(false)
  return (
    <QuillWrapper data-test-id={dataTestId} isFocused={isFocused} data-cy="rich-text-area">
      <ReactQuill
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        theme="snow"
        value={value || ''}
        onChange={(content) => {
          onChange(content)
        }}
        {...rest}
      />
    </QuillWrapper>
  )
}
