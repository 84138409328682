import { Api } from '@walter/shared'
import * as yup from 'yup'
import { i18n, t } from '../../utils'

export const Rolesi18nFields = {
  title: t('general:title'),
  description: t('general:description'),
  projects: t('general:projects'),
  shouldAutomaticallyAddNewBuildings: t('should-automatically-add-new-buildings-to-role'),
  permissions: t('functionality-permissions'),
  MANAGING_COMPANY_INFO_READ: t('view-managing-company-information'),
  MANAGING_COMPANY_INFO_WRITE: t('edit-managing-company-information'),
  ROLES_READ: t('view-managing-company-roles'),
  ROLES_WRITE: t('edit-managing-company-roles'),
  ACCOUNTS_READ: t('view-your-profile'),
  ACCOUNTS_WRITE: t('edit-your-profile'),
  NOTIFICATION_READ: t('view-news-memos'),
  NOTIFICATION_WRITE: t('edit-news-memos'),
  CALENDAR_READ: t('view-calendar-events'),
  CALENDAR_WRITE: t('edit-calendar-events'),
  CONTACT_READ: t('view-contacts'),
  CONTACT_WRITE: t('edit-contacts'),
  RULE_READ: t('view-rules'),
  RULE_WRITE: t('edit-rules'),
  AMENITY_READ: t('view-amenities'),
  AMENITY_WRITE: t('edit-amenities'),
  MAIL_READ: t('view-packages'),
  MAIL_WRITE: t('edit-packages'),
  CHAT_READ: t('view-chat-messages'),
  CHAT_WRITE: t('send-chat-messages'),
  USER_READ: t('view-residents-list'),
  USER_WRITE: t('edit-residents-list'),
  TASK_READ: t('view-tasks'),
  TASK_WRITE: t('edit-tasks'),
  FILE_READ: t('view-documents'),
  FILE_WRITE: t('edit-documents'),
  SALE_READ: t('view-marketplace-items'),
  SALE_WRITE: t('edit-marketplace-items'),
  SEGMENT_READ: t('view-filters'),
  SEGMENT_WRITE: t('edit-filters'),
  PROJECT_READ: t('view-project-informations'),
  PROJECT_WRITE: t('edit-project-informations'),
  FAQ_READ: t('view-faq'),
  FAQ_WRITE: t('edit-faq'),
  THIRD_PARTY_SYNCHRONIZATION_WRITE: t('edit-bank-third-party-synchronization-information'),
  THIRD_PARTY_SYNCHRONIZATION_READ: t('view-bank-third-party-synchronization-information'),
  RENT_WRITE: t('edit-rent'),
  RENT_READ: t('view-rent'),
  ACCOUNT_STATEMENT_WRITE: t('edit-account-statement'),
  ACCOUNT_STATEMENT_READ: t('view-account-statement'),
}

export const RolesSchema = yup.object({
  title: yup.string().required(i18n.t('input:is-required', { field: Rolesi18nFields.title })),
  description: yup.string().nullable().optional(),
  projects: yup
    .array(yup.object({ id: yup.string() }))
    .min(1, i18n.t('input:is-required', { field: Rolesi18nFields.projects }))
    .required(i18n.t('input:is-required', { field: Rolesi18nFields.projects })),
  shouldAutomaticallyAddNewBuildings: yup
    .boolean()
    .required(i18n.t('input:is-required', { field: Rolesi18nFields.title })),
  permissions: yup.array(yup.mixed<Api.Permission_Type>().oneOf([...Object.values(Api.Permission_Type)])),
})

export type Roles = yup.InferType<typeof RolesSchema>
