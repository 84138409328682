import { ApolloError, gql, useApolloClient } from '@apollo/client'
import { Api } from '@walter/shared'
import { uniqBy } from 'lodash'
import React, { useContext, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import AuthContext from './Auth'

type PropertyContextType = {
  uniqProperties: Api.GetMeResidentWebQuery['me']['property'][]
  selectedProperty: Nullable<Api.GetMeResidentWebQuery['me']['property']>
  loadingProperty: boolean
  errorLoadingPropertyQuery?: ApolloError
}

interface PropertyContextProps {
  children: React.ReactNode
}

export const PROPERTY_WITH_ADDRESS_FRAGMENT = gql`
  fragment propertyWithAddress on Property {
    id
    address {
      id
      apartmentNumber
    }
  }
`

export function PropertyProvider({ children }: PropertyContextProps) {
  const client = useApolloClient()
  const { propertyId } = useParams<{ propertyId: string }>()
  const { currentUser } = useContext(AuthContext)
  const { push } = useHistory()

  const {
    data: { property } = {},
    loading: loadingProperty,
    error: errorLoadingPropertyQuery,
  } = Api.usePropertyBuildingAddressQuery({
    variables: {
      where: {
        id: propertyId,
      },
    },
    skip: !propertyId,
  })

  const uniqProperties = useMemo(() => {
    const properties = [currentUser.livingProperty, ...currentUser.ownedProperties]
    const uniqProperties = uniqBy(properties, 'id')
    const validProperties = uniqProperties.filter(Boolean)
    return validProperties
  }, [currentUser.livingProperty, currentUser.ownedProperties])

  const selectedProperty = React.useMemo(() => {
    const res = client.readFragment({
      id: `Property:${propertyId}`,
      fragment: PROPERTY_WITH_ADDRESS_FRAGMENT,
    })

    return {
      ...property,
      ...res,
    }
  }, [property])

  useEffect(() => {
    push(`/${currentUser.ownedProperties[0]?.id || currentUser.livingProperty?.id}/news`)
  }, [currentUser])

  return (
    <PropertyContext.Provider value={{ selectedProperty, uniqProperties, loadingProperty, errorLoadingPropertyQuery }}>
      {children}
    </PropertyContext.Provider>
  )
}

const PropertyContext = React.createContext<PropertyContextType>({} as PropertyContextType)

export default PropertyContext
