import get from 'lodash/get'
import { matchSorter } from 'match-sorter'
import { SelectedFilter } from './types'

type SearchKey<T> = string | ((item: T) => string)

export const getFilteredItems = <T>(items: T[], filters: SelectedFilter[], searchKeys: SearchKey<T>[] = []) => {
  const searchFilters = filters.filter((f) => f.type === 'search')
  const otherFilters = filters.filter((f) => f.type !== 'search')
  let filteredItems = items

  if (otherFilters.length > 0) {
    filteredItems = items.filter((item) =>
      otherFilters.some((filter) => {
        if (filter.filterFunc) {
          return filter.filterFunc({ values: item, value: filter.chosenValue })
        }

        if (filter.type === 'boolean' && filter.pathOptionValue) {
          const itemPathValue = get(item, filter.pathOptionValue)

          return filter.chosenValue === 'true' ? itemPathValue : !itemPathValue
        }

        if (filter.pathOptionValue) {
          return filter.chosenValue === get(item, filter.pathOptionValue)
        }

        return false
      }),
    )
  }

  if (searchFilters.length) {
    searchFilters.forEach((searchFilter) => {
      filteredItems = matchSorter(filteredItems, String(searchFilter.chosenValue), {
        keys: searchKeys,
      })
    })
  }

  return filteredItems
}
