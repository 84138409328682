import * as yup from 'yup'
import { t } from '../../../utils'

export const thirdPartiesHopemi18nFields = {
  hopemClientId: t('hopem-client-identifier-please-note-that'),
  shouldSkipProjectCreation: t('third-party-auto-add-missing-buildings'),
  shouldSyncBuildingComplexesAsOneBuilding: t('third-party-sync-building-complexes-as-one-building'),
  shouldRemoveFamilyMembersAddedByUsers: t('third-party-remove-family-members-added-by-users'),
  shouldRemoveTenantsAddedByUsers: t('third-party-remove-tenants-added-by-users'),
  shouldRemoveOwnersAddedByUsers: t('third-party-remove-owners-added-by-users'),
}

export const thirdPartiesHopemSchemaUpdate = yup.object({
  hopemClientId: yup.string().optional(),
  shouldSkipProjectCreation: yup.boolean().nullable().default(false),
  shouldSyncBuildingComplexesAsOneBuilding: yup.boolean().optional().nullable().default(false),
  shouldRemoveFamilyMembersAddedByUsers: yup.boolean().optional().nullable().default(false),
  shouldRemoveTenantsAddedByUsers: yup.boolean().optional().nullable().default(false),
  shouldRemoveOwnersAddedByUsers: yup.boolean().optional().nullable().default(false),
})

export type ThirdPartiesHopem = yup.InferType<typeof thirdPartiesHopemSchemaUpdate>
