import { Colors, Spacing, Types } from '@walter/shared'
import { rgba, stripUnit } from 'polished'
import React from 'react'
import styled, { css } from 'styled-components'
import { animationCurve, animationTime, borderRadius, square } from '../../styles/global'
import { Icon } from '../Icon'
import { Pill } from '../Pill'

const Container = styled.div<{ isActive?: boolean; themeColor: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.greyLight};
  cursor: pointer;
  transition: color ${animationTime} ${animationCurve};
  outline: 0;

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background-color: transparent;
    border-radius: 0 ${borderRadius} ${borderRadius} 0;
    transition: background-color ${animationTime} ${animationCurve};
  }

  &:hover,
  &:focus {
    outline: 0;
    color: ${Colors.grey};
  }

  ${(props) =>
    props.isActive &&
    css`
      color: ${props.themeColor};

      &:hover,
      &:focus {
        color: ${props.themeColor};
      }

      &:after {
        background-color: ${props.themeColor};
      }
    `}

  & + & {
    margin-top: ${`${(stripUnit(Spacing.medium) as number) * 1.25}px`};
  }
`

const IconWrap = styled.div<{ isActive?: boolean; themeColor: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${borderRadius};
  background-color: transparent;
  transition: background-color ${animationTime} ${animationCurve};
  ${square('36px')};

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${rgba(props.themeColor, 0.2)};
    `}
`

const PillWrap = styled.div`
  position: absolute;
  top: -5px;
  right: -11px;
  display: flex;
  border-radius: ${Spacing.large};
  border: 3px solid ${Colors.white};
`

export type MenuItemProps = {
  label: string
  icon: Types.IconName
  dataTestId?: string
  handleClick?: (event: React.MouseEvent<HTMLDivElement>) => void | undefined
  notifications?: number
  isActive?: boolean
  notificationsHintText?: string
  themeColor?: string
  isNew?: boolean
}

export const MenuItem = ({
  label,
  notifications,
  icon,
  isActive,
  notificationsHintText,
  isNew,
  dataTestId,
  handleClick,
  themeColor = Colors.grey,
}: MenuItemProps) => {
  return (
    <Container
      onClick={handleClick}
      isActive={isActive}
      data-tip={label}
      data-place="right"
      data-class="tooltip large offset"
      data-test-id={dataTestId}
      themeColor={themeColor}
    >
      <IconWrap themeColor={themeColor} isActive={isActive}>
        <Icon icon={icon} />
        {isNew && (
          <PillWrap>
            <Pill text="NEW" type="danger" />
          </PillWrap>
        )}
        {!!notifications && notifications > 0 && (
          <PillWrap>
            <Pill text={notifications} type="danger" hint={notificationsHintText} />
          </PillWrap>
        )}
      </IconWrap>
    </Container>
  )
}
