import { Colors, SharedUtils, Spacing, Types } from '@walter/shared'
import Interweave from 'interweave'
import get from 'lodash/get'
import { WebDateUtils } from '../../../src/utils/date'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { Attachment } from '../Attachment'
import { AttachmentList } from '../Attachment/AttachmentList'
import { FieldContainer } from '../FieldContainer'
import { Icon } from '../Icon'
import { Input } from '../Input'
import { PhotoGallery } from '../PhotoGallery'
import { Pill } from '../Pill'
import { PillGroup } from '../PillGroup'
import { PostCoverImage } from '../PostCoverImage'
import { YesNo } from '../YesNo'
import Website from '../Website'
import { i18n } from '../../utils'
import { Option } from '../ReactForm/types'

type ConnectionTypeProps<ValueType> = {
  value: ValueType
  itemOptionLabel?: string | ((value: ValueType) => string)
  onClick?: any
  noValueDisplay?: string | (() => string)
  dataTestId?: string
}

const ConnectionType = <ValueType,>({
  value,
  onClick,
  noValueDisplay,
  itemOptionLabel,
  dataTestId,
}: ConnectionTypeProps<ValueType>) => {
  const values = (Array.isArray(value) ? value : [value]).filter(Boolean)

  if (values.length === 0) {
    if (noValueDisplay) {
      const text = noValueDisplay instanceof Function ? noValueDisplay() : noValueDisplay

      return <Pill dataTestId={dataTestId} text={text} />
    }

    return null
  }
  const formatNeutralValue = (value: string) => {
    return value ? `  ${value}  ` : null
  }

  return (
    <PillGroup dataTestId={dataTestId}>
      {values.map((value, index) => (
        <Pill
          dataTestId={
            dataTestId +
              '_' +
              (itemOptionLabel instanceof Function ? itemOptionLabel(value) : get(value, itemOptionLabel ?? '')) ||
            formatNeutralValue(value) ||
            'Unknown'
          }
          handleClick={onClick ? () => onClick(value) : undefined}
          key={index}
          text={
            (itemOptionLabel instanceof Function ? itemOptionLabel(value) : get(value, itemOptionLabel ?? '')) ||
            formatNeutralValue(value) ||
            'Unknown'
          }
        />
      ))}
    </PillGroup>
  )
}

type FormDataDisplayProps = {
  type: Types.FieldType | ((values: any) => Types.FieldType)
  value: any // Maybe we can use Generic here but not sure...
  label?: string
  hint?: string
  dataTestId?: string
}

export const FormDataDisplay = ({ type, label, value, hint, dataTestId, ...rest }: FormDataDisplayProps) => {
  React.useEffect(() => {
    if (hint) {
      ReactTooltip.rebuild()
    }
  }, [hint])

  if (type === 'connection') {
    return (
      <FieldContainer dataTestId={dataTestId} label={label} hint={hint}>
        <ConnectionType value={value} dataTestId={dataTestId} {...rest} />
      </FieldContainer>
    )
  }

  if (type === 'image') {
    const photos = (Array.isArray(value) ? value : [value]).filter(Boolean)

    return <PhotoGallery label={label} photos={photos.map((photo) => photo.url)} />
  }

  if (type === 'coverImage') {
    return <PostCoverImage url={value?.url} />
  }

  if (type === 'date') {
    return (
      <FieldContainer dataTestId={dataTestId} label={label} hint={hint}>
        {renderBasic({ value, type, ...rest })}
      </FieldContainer>
    )
  }

  if (type === 'datetime') {
    return (
      <FieldContainer dataTestId={dataTestId} label={label} hint={hint}>
        {renderBasic({ value, type, ...rest })}
      </FieldContainer>
    )
  }

  if (type === 'currency') {
    return (
      <FieldContainer dataTestId={dataTestId} label={label} hint={hint}>
        {renderBasic({ value: SharedUtils.formatToCurrency(value, i18n.language), type, ...rest })}
      </FieldContainer>
    )
  }

  return (
    <FieldContainer dataTestId={dataTestId} label={label} hint={hint}>
      {renderBasic({ value, type, ...rest })}
    </FieldContainer>
  )
}

const CopyToClipboardInner = styled.div`
  display: flex;
  align-items: center;
`

const CopyToClipboardIconWrapper = styled.div`
  margin-left: ${Spacing.medium};
  cursor: pointer;
`

function CopyToClipboardComponent({ text }: { text: string }) {
  const [isCopied, setIsCopied] = React.useState(false)

  return (
    <CopyToClipboard text={text} onCopy={() => setIsCopied(true)}>
      <CopyToClipboardInner>
        <Input readOnly value={text} />
        <CopyToClipboardIconWrapper>
          <Icon hint="Copy to clipboard" icon="duplicate" {...(isCopied && { color: Colors.primaryColor })} />
        </CopyToClipboardIconWrapper>
      </CopyToClipboardInner>
    </CopyToClipboard>
  )
}

const renderBasic = ({ value, type, ...rest }: FormDataDisplayProps & { options?: Option[] }) => {
  if (type === 'date') {
    if (value == null) {
      return '-'
    }

    return WebDateUtils.format(value, 'LL')
  }

  if (type === 'singledate' || type === 'datetime') {
    if (value == null) {
      return '-'
    }

    return WebDateUtils.dayjs(value).format(type === 'singledate' ? 'LL' : 'LLL')
  }

  if (type === 'richTextarea') {
    return <Interweave content={value} />
  }

  if (type === 'boolean') {
    if (typeof value === 'undefined') {
      return '-'
    }

    return <YesNo type={value ? 'yes' : 'no'} />
  }

  if (type === 'link' || type === 'website') {
    return <Website content={value} />
  }

  if (type === 'file') {
    const arrayValue = SharedUtils.convertToArray(value)

    if (arrayValue.length === 0) {
      return '-'
    }

    if (arrayValue.length === 1 && !arrayValue[0].url) {
      return '-'
    }

    return (
      <AttachmentList>
        {arrayValue.map((attachment, i) => {
          return (
            <Attachment
              handleClick={(e) => {
                e.stopPropagation()
                window.open(attachment.url, '_blank')
              }}
              key={attachment.id + i}
              name={attachment.name}
            />
          )
        })}
      </AttachmentList>
    )
  }

  if (type === 'booleans') {
    const { options } = rest

    return options?.map((option, index) => {
      const isChecked = value.includes(option.value)

      return (
        <div
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <span style={{ flex: 1 }}>{option.label}</span>
          <YesNo type={isChecked ? 'yes' : 'no'} />
        </div>
      )
    })
  }

  if (type === 'url') {
    return <CopyToClipboardComponent text={value} />
  }

  return value || '-'
}
