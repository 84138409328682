import { Api } from '@walter/shared'
import * as yup from 'yup'
import { i18n, t } from '../../utils'
import { fieldIsTooLong } from './common'

export const ManagerProfilei18nFields = {
  firstName: t('user:first-name'),
  lastName: t('user:last-name'),
  phone: t('user:mobile-phone-number'),
  officePhone: t('user:office-phone-number'),
  title: t('user:title'),
  preferedLanguage: t('user:preferred-language'),
  email: t('user:email'),
  password: t('change-your-password-here'),
}

export const ManagerProfileSchema = yup.object({
  firstName: yup
    .string()
    .required(i18n.t('input:is-required', { field: ManagerProfilei18nFields.title }))
    .max(20, fieldIsTooLong(20, ManagerProfilei18nFields.title)),
  lastName: yup.string().optional().nullable(),
  phone: yup.object({
    number: yup
      .string()
      .nullable()
      .optional()
      .test(
        'phoneNumber',
        i18n.t('input:must-be-phone-number', {
          field: ManagerProfilei18nFields.phone,
          formats: '+11231231234, 1231231234',
        }),
        (value) => {
          const regex = new RegExp(/(\+[1-9])?[0-9]{3}[0-9]{3}[0-9]{4}/)
          return (value?.trim().length ?? 0) > 0 && typeof value === 'string' ? regex.test(value) : true
        },
      ),
  }),
  officePhone: yup.object({
    number: yup
      .string()
      .nullable()
      .optional()
      .test(
        'phoneNumber',
        i18n.t('input:must-be-phone-number', {
          field: ManagerProfilei18nFields.officePhone,
          formats: '+11231231234, 1231231234',
        }),
        (value) => {
          const regex = new RegExp(/(\+[1-9])?[0-9]{3}[0-9]{3}[0-9]{4}/)
          return (value?.trim().length ?? 0) > 0 && typeof value === 'string' ? regex.test(value) : true
        },
      ),
  }),
  title: yup.string().optional().nullable(),
  preferedLanguage: yup.string().optional(),
  email: yup
    .string()
    .email()
    .required(i18n.t('input:is-required', { field: ManagerProfilei18nFields.email })),
  password: yup.string().optional(),
})

export type ManagerProfile = yup.InferType<typeof ManagerProfileSchema> & { status: Api.Ad_Status }
