import { get, isEmpty, isEqual, isNaN, isObject, transform, trim } from 'lodash'
import * as R from 'ramda'

export function convertWebsiteToHttp(website: string) {
  if (!website || website.startsWith('http') || website.startsWith('https')) {
    return website
  }

  return `http://${website}`
}

export function truncate({
  string,
  maxChars = 100,
  append = '...',
  onlyFullWords = true,
}: {
  string: string
  maxChars?: number
  append?: string
  onlyFullWords?: boolean
}): string {
  if (!string) {
    return ''
  }
  maxChars = onlyFullWords ? maxChars + 1 : maxChars
  string = trim(string)

  if (string.length <= maxChars) {
    return string
  }
  string = string.substr(0, maxChars - append.length)
  // crop at last space or remove trailing whitespace
  string = onlyFullWords ? string.substr(0, string.lastIndexOf(' ')) : trim(string)

  return string + append
}

export function isFunction(functionToCheck: any): boolean {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
}

export function difference(object: Record<string, unknown>, base: Record<string, unknown>) {
  // If one or the other is empty...
  if (!base || !object) {
    return object || base
  }
  const changes = (object: Record<string, any>, base: Record<string, any>) => {
    let arrayIndexCounter = 0

    return transform(object, (result: any, value, key) => {
      if (!isEqual(value, base[key])) {
        const resultKey = Array.isArray(base) ? (arrayIndexCounter += 1) : key

        result[resultKey] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value
      }
    })
  }

  return changes(object, base)
}

export function uniq(array: any[], param: any) {
  const result: any[] = []
  const map = new Map()

  // eslint--disable-next-line no-restricted-syntax
  for (const item of array) {
    if (!map.has(param ? get(item, param) : item)) {
      map.set(param ? get(item, param) : item, true) // set any value to Map
      result.push(item)
    }
  }

  return result.filter(Boolean)
}

export function isValidEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return re.test(String(email).toLowerCase())
}

export function formatEmail(email: string): string {
  return email ? email.toLowerCase().trim() : ''
}

export function getUserName(user?: { firstName?: string | null; lastName?: string | null } | null): string {
  if (!user) {
    return ''
  }

  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`
  }

  if (user.firstName) {
    return user.firstName
  }

  if (user.lastName) {
    return user.lastName
  }

  return `No Name`
}

export type UserNameAndUnitType = {
  firstName: string
  lastName: string
  property: { id: string; address: { apartmentNumber: string } }
  properties: { id: string; address: { apartmentNumber: string } }[]
}

export function getUserNameAndUnit(user: UserNameAndUnitType): string {
  if (!user) {
    return getUserName(user)
  }

  if (!user.property && user.properties?.length === 0) {
    return getUserName(user)
  }

  return `${getUserName(user)} - ${arrayDisplay({
    array: uniq([user.property, ...(user.properties || [])], 'id'),
    valueToDisplay: 'address.apartmentNumber',
    separatorString: ',',
    preItemString: '#',
  })}`
}

export function arrayDisplay({
  array,
  valueToDisplay,
  separatorString = ' - ',
  preItemString = '',
}: {
  array: any[]
  valueToDisplay: any
  separatorString?: string
  preItemString?: string
}) {
  const validArray = array.filter(Boolean)

  return validArray.reduce(
    // eslint-disable-next-line no-return-assign
    (final, item, index) =>
      (final += `${preItemString}${
        !valueToDisplay ? item : isFunction(valueToDisplay) ? valueToDisplay(item) : get(item, valueToDisplay)
      }${validArray.length - 1 !== index ? separatorString : ''}`),
    '',
  )
}

const getOnlyKeysWithData = (fields: string[], obj: { [key: string]: string }) => {
  const filterFunc = (field: string) => {
    return !!(Object.keys(obj || {}).includes(field) && obj[field])
  }
  return R.filter(filterFunc, fields)
}

const createStringFromKeysWithData = (keysWithData: string[], obj: { [key: string]: string }) => {
  return keysWithData.reduce(
    (total, key, index) => (total += `${obj[key]}${index === keysWithData.length - 1 ? '' : ', '}`),
    '',
  )
}

export function getFormattedStringWithComma(obj: { [key: string]: string }, fields: string[] = []): string {
  const keysWithData = getOnlyKeysWithData(fields, obj)
  return createStringFromKeysWithData(keysWithData, obj)
}

// 1950 South Ocean Drive, Hallandale Beach, FL, USA
export function getFormattedAddress(address: {
  address1: string
  apartmentNumber: string
  city: string
  state: string
  zip: string
  country: string
}): string {
  return address
    ? getFormattedStringWithComma(address, ['address1', 'apartmentNumber', 'city', 'state', 'zip', 'country'])
    : ''
}

export function wait(ms = 0) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function getParameterByName(name: string, url?: string): string | null {
  if (!url) url = window.location.href
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)

  const results = regex.exec(url)

  if (!results) return null

  if (!results[2]) return ''

  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function displayPropertyAddressAndResidents(property: {
  address: { apartmentNumber: string }
  users: { firstName: string; lastName: string }[]
  owners: { firstName: string; lastName: string }[]
}): string {
  let text = ''

  if (property.address) {
    text += `#${property.address.apartmentNumber}`
  }
  const uniqResidents = uniq(
    [...(property.users ? property.users : []), ...(property.owners ? property.owners : [])],
    'id',
  )

  if (uniqResidents.length) {
    text += ` - ${arrayDisplay({
      array: uniqResidents,
      valueToDisplay: (user: Parameters<typeof getUserName>[0]) => getUserName(user),
    })}`
  }

  return text
}

export function formatToCurrency(amount: number | string, preferredLanguage = 'fr'): string {
  const adjustedPreferredLanguage = preferredLanguage === 'fr' ? preferredLanguage + '-CA' : 'en-CA'
  const currency = 'CAD'

  if (!amount) {
    return new Intl.NumberFormat(adjustedPreferredLanguage, { style: 'currency', currency }).format(0)
  }

  if (typeof amount === 'string') {
    const parsed = parseFloat(amount)

    if (isNaN(parsed)) {
      throw new Error('Invalid value')
    }

    return new Intl.NumberFormat(adjustedPreferredLanguage, { style: 'currency', currency }).format(parsed)
  }

  return new Intl.NumberFormat(adjustedPreferredLanguage, { style: 'currency', currency }).format(amount)
}

export function convertToArray(thing: any): any[] {
  if (!thing) {
    return []
  }

  return Array.isArray(thing) ? thing : [thing]
}

export function isValidPhoneNumber(phoneNumber: string): boolean {
  return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(phoneNumber)
}

export function removeKeys(data: Record<string, unknown>, keys: string[]) {
  const newData = JSON.parse(JSON.stringify(data))
  const removeProps = (obj: Record<string, any>) => {
    if (obj) {
      if (obj instanceof Array) {
        obj.forEach((item) => removeProps(item))
      } else if (typeof obj === 'object' && Object.keys(obj).length !== 0) {
        Object.getOwnPropertyNames(obj).forEach((key) => {
          if (keys.indexOf(key) !== -1) delete obj[key]
          else removeProps(obj[key])
        })
      }
    }
  }

  removeProps(newData)

  return newData
}

// Usefull for user that have many condos
export function formatUserToOnlyHaveHisDataBasedOnCurrentProject<
  User extends {
    id: string
    properties: { building: { id: string } }[]
    ownedProperties: { building: { id: string } }[]
    property?: { building: { id: string } }
    livingProperty?: { building: { id: string } }
    projectsTenants: { id: string }[]
    projectsBoardMember: { id: string }[]
    projects: { id: string }[]
  },
>(user: User, projects: { id: string; building: { id: string } }[]): User | null {
  if (!user || isEmpty(user)) {
    return null
  }

  if (!projects) {
    return user
  }

  if (!projects.some(({ building }) => building)) {
    return user
  }

  // Should maybe do this in the graphql query where we only get units of the person based on the building
  const userPropertiesForProjects = user.properties?.filter((property) =>
    projects.some((project) => project.building.id === property.building.id),
  )
  const userOwnedPropertiesForProjects = user.ownedProperties?.filter((property) =>
    projects.some((project) => project.building.id === property.building.id),
  )
  const userPropertyForProjects =
    user.property && projects.some((project) => project.building.id === user.property?.building?.id)
      ? user.property
      : null
  const userLivingPropertyForProjects =
    user.livingProperty && projects.some((project) => project.building.id === user.livingProperty?.building?.id)
      ? user.property
      : null

  const formattedUser = {
    ...user,
    property: userPropertyForProjects,
    livingProperty: userLivingPropertyForProjects,
    properties: userPropertiesForProjects,
    ownedProperties: userOwnedPropertiesForProjects,
    projectsTenants:
      user.projectsTenants?.filter((projectTenant) => projects.some((project) => project.id === projectTenant.id)) ||
      [],
    projectsBoardMember:
      user.projectsBoardMember?.filter((projectBoardMember) =>
        projects.some((project) => project.id === projectBoardMember.id),
      ) || [],
    // Usefull for resident list all for showing only the user projects of the current managing company
    ...(user.projects && {
      projects: user.projects?.filter((project) => projects.some(({ id }) => project.id === id)) || [],
    }),
  }

  return formattedUser
}

export function getFormattedPhoneNumber(phoneNumber: string) {
  return phoneNumber
    .replace(/\+/g, '')
    .replace(/\(/g, '')
    .replace(/\)/g, '')
    .replace(/-/g, '')
    .replace(/_/g, '')
    .replace(/ /g, '')
    .trim()
}

export function arraysEqual(a: any[], b: any[]) {
  if (a === b) return true

  if (a == null || b == null) return false

  if (a.length !== b.length) return false

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }

  return true
}

export function pricify(price: { amount: number }) {
  return new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
  }).format(price.amount)
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function hasOwnProperty<X extends {}, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop)
}

export const phoneNumberRegex =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const formatPhoneNumber = (str: string) => {
  //Filter only numbers from the input
  const cleaned = ('' + str).replace(/\D/g, '')

  //Check if the input is of correct
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    const intlCode = match[1] ? '+1' : ''
    return [intlCode, ' (', match[2], ') ', match[3], '-', match[4]].join('')
  }

  return str
}
