import * as yup from 'yup'
import { t } from '../../utils'

export const CreateTaskResidenti18nFields = {
  category: t('category'),
  title: t('title'),
  description: t('description'),
  pictures: t('pictures'),
}

export const createTaskResidentSchema = yup.object({
  category: yup.string().required(),
  title: yup.string().required(),
  description: yup.string().optional().nullable(),
  pictures: yup.mixed().optional().nullable(),
})

export type CreateTaskResidentSchemaType = yup.InferType<typeof createTaskResidentSchema>
