import * as yup from 'yup'
import { i18n, t } from '../../utils'

export const folderI18n = {
  name: t('name-uppercase'),
  folderParent: t('fields-folder:folder-parent'),
  sharedWithResidents: t('shared-with-residents'),
  segments: {
    text: t('fields-segment:group'),
    hint: t('fields-segment:send-your-memo-events-folders-and-more-t'),
    placeholder: t('all-residents'),
  },
}

export const folderCreateSchema = yup.object({
  name: yup.string().required(i18n.t('input:is-required', { field: folderI18n.name })),
  sharedWithResidents: yup.boolean().required(),
  folderParent: yup.object({ id: yup.string().optional() }).nullable(),
})

export const folderUpdateSchema = yup.object({
  name: yup.string().required(i18n.t('input:is-required', { field: folderI18n.name })),
  sharedWithResidents: yup.boolean().required(),
  folderParent: yup.object({ id: yup.string().optional() }).nullable(),
})

export const singleFolderSchemaCreate = folderCreateSchema.clone().concat(
  yup.object({
    segments: yup.array(yup.object({ id: yup.string() })),
  }),
)

export const singleFolderSchemaUpdate = folderUpdateSchema.clone().concat(
  yup.object({
    segments: yup.array(yup.object({ id: yup.string() })),
  }),
)

export const sharedFolderSchemaCreate = folderCreateSchema.clone().concat(yup.object({ projects: yup.array() }))
export const sharedFolderSchemaUpdate = folderUpdateSchema.clone().concat(yup.object({ projects: yup.array() }))

export type FolderCreate = yup.InferType<typeof singleFolderSchemaCreate>
export type SharedFolderCreate = yup.InferType<typeof sharedFolderSchemaCreate>

export type FolderUpdate = yup.InferType<typeof singleFolderSchemaUpdate>
export type SharedFolderUpdate = yup.InferType<typeof sharedFolderSchemaUpdate>
