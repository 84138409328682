import * as yup from 'yup'
import { i18n, t } from '../../utils'
import { SharedUtils } from '@walter/shared'

export const EnterPhoneNumberi18nFields = {
  phoneNumber: t('auth:enter-phone-number-page-phone-number-input-label'),
}

export const EnterPhoneNumberSchema = yup.object({
  phoneNumber: yup
    .string()
    .matches(SharedUtils.phoneNumberRegex, t('auth:login-page-invalid-phone-number'))
    .required(i18n.t('input:is-required', { field: EnterPhoneNumberi18nFields.phoneNumber })),
})

export type EnterPhoneNumber = yup.InferType<typeof EnterPhoneNumberSchema>
