import { Loading } from '@walter/shared-web'
import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { DashboardWrapper } from '../layouts/DashboardWrapper'
import AuthContext from '../contexts/Auth'
import { MessagingService, useMessagingService } from '@walter/shared'

const Home = lazy(() => import(/* webpackChunkName: "home" */ './Home'))
const EnterEmail = lazy(() => import(/* webpackChunkName: "enterEmail", webpackPrefetch: true */ './auth/EnterEmail'))
const ConfirmEmail = lazy(
  () => import(/* webpackChunkName: "confirmEmail", webpackPrefetch: true */ './auth/ConfirmEmail'),
)
const ConfirmPhoneNumber = lazy(
  () => import(/* webpackChunkName: "confirmPhoneNumber", webpackPrefetch: true */ './auth/ConfirmPhoneNumber'),
)
const EnterPassword = lazy(
  () => import(/* webpackChunkName: "enterPassword", webpackPrefetch: true */ './auth/EnterPassword'),
)
const CreatePassword = lazy(
  () => import(/* webpackChunkName: "createPassword", webpackPrefetch: true */ './auth/CreatePassword'),
)
const EnterPhoneNumber = lazy(
  () => import(/* webpackChunkName: "enterPhoneNumber", webpackPrefetch: true */ './auth/EnterPhoneNumber'),
)
const ForgotPassword = lazy(
  () => import(/* webpackChunkName: "forgotPassword", webpackPrefetch: true */ './auth/ForgotPassword'),
)
const ResetPassword = lazy(
  () => import(/* webpackChunkName: "resetPassword", webpackPrefetch: true */ './auth/ResetPassword'),
)
const LoginForRetool = lazy(
  () => import(/* webpackChunkName: "loginForRetool", webpackPrefetch: true */ './auth/LoginForRetool'),
)

const PropertyRemoval = lazy(
  () => import(/* webpackChunkName: "propertyRemoval", webpackPrefetch: true */ './PropertyRemoval'),
)

const Proxy = lazy(() => import(/* webpackChunkName: "proxy", webpackPrefetch: true */ './proxy'))
const News = lazy(() => import(/* webpackChunkName: "news", webpackPrefetch: true */ './News'))
const Calendar = lazy(() => import(/* webpackChunkName: "calendar", webpackPrefetch: true */ './Calendar'))
const Amenities = lazy(() => import(/* webpackChunkName: "amenities", webpackPrefetch: true */ './Amenities'))
const Packages = lazy(() => import(/* webpackChunkName: "packages", webpackPrefetch: true */ './Packages'))
const Rules = lazy(() => import(/* webpackChunkName: "rules", webpackPrefetch: true */ './Rules'))
const Contacts = lazy(() => import(/* webpackChunkName: "contacts", webpackPrefetch: true */ './Contacts'))
const Chat = lazy(() => import(/* webpackChunkName: "chat", webpackPrefetch: true */ './Chat'))
const Profile = lazy(() => import(/* webpackChunkName: "profile", webpackPrefetch: true */ './Profile'))
const UnitDetails = lazy(() => import(/* webpackChunkName: "unitsInfo", webpackPrefetch: true */ './UnitDetails'))
const Files = lazy(() => import(/* webpackChunkName: "files", webpackPrefetch: true */ './Files'))
const ProxyCompleted = lazy(
  () => import(/* webpackChunkName: "proxy-completed", webpackPrefetch: true */ './proxy/ProxyCompleted'),
)
const OtherResidents = lazy(
  () => import(/* webpackChunkName: "otherResident", webpackPrefetch: true */ './OtherResidents'),
)
const MarketPlace = lazy(() => import(/* webpackChunkName: "marketPlace", webpackPrefetch: true */ './Marketplace'))
const Task = lazy(() => import(/* webpackChunkName: "serviceRequest", webpackPrefetch: true */ './Tasks'))
const Faq = lazy(() => import(/* webpackChunkName: "faq", webpackPrefetch: true */ './Faq'))
const OpenSourceAttributions = lazy(
  () => import(/* webpackChunkName: "openSourceAttributions", webpackPrefetch: true */ './OpenSourceAttributions'),
)
const AccountStatements = lazy(() => import(/* webpackChunkName: "faq", webpackPrefetch: true */ './AccountStatements'))

const messagingService = new MessagingService()

export default function Routes() {
  const { currentUser } = React.useContext(AuthContext)
  useMessagingService({
    messagingService,
    userId: currentUser?.id,
  })
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Switch>
          <Route exact path="/proxy" component={Proxy} />
          <Route exact path="/Utils/ProxySelectMember" component={Proxy} />
          <Route exact path="/proxy-completed" component={ProxyCompleted} />
          <Route exact path="/auth/login" component={EnterEmail} />
          <Route exact path="/auth/login/old" component={LoginForRetool} />
          <Route exact path="/auth/email" component={EnterEmail} />
          <Route exact path="/auth/confirmEmail" component={ConfirmEmail} />
          <Route exact path="/auth/confirmPhoneNumber" component={ConfirmPhoneNumber} />
          <Route exact path="/auth/phoneNumber" component={EnterPhoneNumber} />
          <Route exact path="/auth/password" component={EnterPassword} />
          <Route exact path="/auth/createPassword" component={CreatePassword} />
          <Route exact path="/auth/forgot-password" component={ForgotPassword} />
          <Route exact path="/auth/reset-password" component={ResetPassword} />
          <Route path="/property-removal/:propertyId/:tokenRemoval" component={PropertyRemoval} />
          <Route path="/open-source-attribution" component={OpenSourceAttributions} />
          <Route path="/:propertyId?">
            <DashboardWrapper>
              <Suspense fallback={<Loading type="page" />}>
                <Switch>
                  <Route path="/profile" component={Profile} />
                  <Route path="/:propertyId/profile" component={Profile} />
                  <Route exact path="/:propertyId?" component={Home} />
                  <Route path="/:propertyId/news" component={News} />
                  <Route path="/:propertyId/calendar" component={Calendar} />
                  <Route path="/:propertyId/amenities" component={Amenities} />
                  <Route path="/:propertyId/packages" component={Packages} />
                  <Route path="/:propertyId/rules" component={Rules} />
                  <Route path="/:propertyId/contacts" component={Contacts} />
                  <Route path="/:propertyId/otherResidents" component={OtherResidents} />
                  <Route path="/:propertyId/accountStatements" component={AccountStatements} />
                  <Route path="/:propertyId/serviceRequests" component={Task} />
                  <Route path="/:propertyId/chat" component={Chat} />
                  <Route path="/:propertyId/files" component={Files} />
                  <Route path="/:propertyId/unitDetails" component={UnitDetails} />
                  <Route path="/:propertyId/marketPlace" component={MarketPlace} />
                  <Route path="/:propertyId/faq" component={Faq} />
                </Switch>
              </Suspense>
            </DashboardWrapper>
          </Route>
        </Switch>
      </Router>
    </Suspense>
  )
}
