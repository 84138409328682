import { Spacing } from '@walter/shared'
import { t } from '../../utils'
import React from 'react'
import styled from 'styled-components'
import { Loading } from '../Loading'
import { FilterResultItem } from './FilterResultItem'
import uniqueId from 'lodash/uniqueId'

const SearchResults = styled.div``

const SearchDetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${Spacing.medium};
  justify-content: space-between;
`

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
`

type FilterResultsListProps<ResultItem> = {
  results: ResultItem[]
  numberOfFoundItems: number
  loadingSearchResults: boolean
  onRemoveResult: (result: ResultItem) => void
  hideResults?: boolean
}

export function FilterResultsList<ResultItem extends { label?: string }>({
  results,
  numberOfFoundItems,
  loadingSearchResults,
  onRemoveResult,
  hideResults = false,
}: FilterResultsListProps<ResultItem>) {
  return (
    <SearchDetailsContainer>
      <TagList>
        {results.map((result) => (
          <FilterResultItem
            label={result.label}
            key={result.label + uniqueId()}
            handleClick={() => onRemoveResult(result)}
          />
        ))}
      </TagList>
      {loadingSearchResults ? (
        <Loading type="inline" />
      ) : (
        <>
          {hideResults ? null : (
            <SearchResults data-test-id="nbOfResults">
              {numberOfFoundItems} {t('general:results')}
            </SearchResults>
          )}
        </>
      )}
    </SearchDetailsContainer>
  )
}
