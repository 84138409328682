import * as yup from 'yup'
import { t } from '../../utils'

export const Lockeri18nFields = {
  number: t('number'),
  cadastreNumber: t('cadastre-number'),
  owners: t('residents-uppercase'),
  properties: t('units'),
}

export const lockerSchema = yup.object({
  number: yup.string(),
  cadastreNumber: yup.string().optional().nullable(),
  owners: yup.array(yup.object({ id: yup.string() })),
  properties: yup.array(yup.object({ id: yup.string(), name: yup.string() })),
})

export type LockerItem = yup.InferType<typeof lockerSchema>
