import * as yup from 'yup'
import { i18n, t } from '../../utils'

export const PostContenti18nFields = {
  coverImage: t('cover-image'),
  title: t('title'),
  description: t('description'),
  attachments: t('files'),
}

export const postContentSchema = yup.object({
  coverImage: yup.mixed().optional(),
  title: yup.string().required(i18n.t('input:is-required', { field: PostContenti18nFields.title })),
  description: yup.string().optional().nullable(),
  attachments: yup.mixed().optional().nullable(),
})

export type PostContentFormSchema = yup.InferType<typeof postContentSchema>
