import React from 'react'
import { Transition } from 'react-transition-group'
import styled, { css } from 'styled-components'
import { Colors, Global, Spacing } from '@walter/shared'
import { elasticScale } from '../../styles/animations'
import { animationCurve, animationTime, borderRadius, square } from '../../styles/global'
import { fontWeights, fontSizes } from '../../styles/typography'
import { Icon } from '../Icon'
import { Pill } from '../Pill'

const Container = styled.div<{ size?: string; slug?: string }>`
  position: relative;
  display: block;
  border-radius: ${borderRadius};
  background-color: ${Colors.grey};
  cursor: pointer;
  ${square('32px')};

  ${(props) =>
    props.size === 'small' &&
    css`
      ${square('24px')};
    `}
`

const LogoWrap = styled.div<{ isActive?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: ${borderRadius};
  overflow: hidden;

  ${(props) =>
    props.isActive &&
    css`
      box-shadow: 0 0 0 2px ${Colors.white}, 0 0 0 4px ${Colors.greyDark};
    `}
`

const Logo = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Placeholder = styled.div`
  color: ${Colors.white};
  background-color: ${Colors.greyDark};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Initial = styled.div<{ size?: string }>`
  color: ${Colors.white};
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.tiny};

  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: ${fontSizes.minuscule};
    `}
`

const Count = styled.div<{ animationState: string }>`
  position: absolute;
  display: flex;
  top: -${Spacing.tiny};
  right: -${Spacing.tiny};
  border-radius: ${Spacing.large};
  box-shadow: 0 0 0 2px ${Colors.white};
  opacity: 0;
  visibility: hidden;
  transition: all ${animationTime} ${animationCurve};

  ${(props) =>
    (props.animationState === 'entering' || props.animationState === 'entered') &&
    css`
      opacity: 1;
      visibility: visible;
      animation: ${elasticScale} 0.6s linear;
    `}
`

type NavBuildingProps = {
  dataTestId?: string
  name: string
  handleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void | undefined
  notifications?: number
  logo?: string
  size?: string
  slug?: string
  placeholder?: boolean
  initials?: string | number
  isActive?: boolean
}

export const NavBuilding = ({
  dataTestId,
  isActive,
  name,
  initials,
  notifications,
  logo,
  placeholder,
  handleClick,
  slug,
  size,
}: NavBuildingProps) => {
  return (
    <Container
      data-test-id={dataTestId}
      onClick={handleClick}
      data-tip={name}
      data-cy={`${name}`}
      data-place="bottom"
      data-class="tooltip"
      slug={slug}
      size={size}
    >
      <LogoWrap isActive={isActive}>
        {logo && <Logo src={logo} alt={`${name} logo`} />}
        {placeholder && (
          <Placeholder>
            <Icon icon="buildings" size="small" />
          </Placeholder>
        )}
        {!placeholder && (
          <Initial data-test-id="ProjectInitials" size={size}>
            {initials || name?.charAt(0)}
          </Initial>
        )}
      </LogoWrap>
      {!!notifications && (
        <Transition in={Boolean(notifications)} timeout={Global.TRANSITION_LENGTH} unmountOnExit enter>
          {(state: string) => (
            <Count animationState={state}>
              <Pill dataTestId="Pill_NotificationsCount" text={notifications} type="danger" />
            </Count>
          )}
        </Transition>
      )}
    </Container>
  )
}
