import { Api } from '@walter/shared'
import * as yup from 'yup'
import { i18n, t } from '../../utils'

export const Reservationsi18nFields = {
  customer: t('fields-reservation:resident'),
  amenity: t('fields-reservation:amenity'),
  status: t('fields-reservation:status'),
  note: t('fields-reservation:note'),
  start: t('fields-reservation:start'),
  end: t('fields-reservation:end'),
  numberOfResidents: t('fields-reservation:number-of-residents'),
}

export const ReservationsSchema = yup.object({
  customer: yup
    .object({ id: yup.string() })
    .default(undefined)
    .required(i18n.t('input:is-required', { field: Reservationsi18nFields.customer })),
  amenity: yup
    .object({ id: yup.string() })
    .typeError(i18n.t('input:is-required', { field: Reservationsi18nFields.amenity }))
    .default(undefined)
    .required(i18n.t('input:is-required', { field: Reservationsi18nFields.amenity })),
  status: yup.mixed<Api.Reservation_Status>().oneOf(Object.values(Api.Reservation_Status)),
  note: yup.string().nullable(),
  start: yup.string().required(i18n.t('input:is-required', { field: Reservationsi18nFields.start })),
  end: yup.string().required(i18n.t('input:is-required', { field: Reservationsi18nFields.end })),
  numberOfResidents: yup
    .number()
    .typeError(i18n.t('input:is-required', { field: Reservationsi18nFields.numberOfResidents }))
    .required(i18n.t('input:is-required', { field: Reservationsi18nFields.numberOfResidents })),
})

export type Reservations = yup.InferType<typeof ReservationsSchema>
