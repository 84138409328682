import { Colors, Spacing } from '@walter/shared'
import { rgba } from 'polished'
import { css } from 'styled-components'
import { fontSizes, lineHeights } from './typography'

// !! Serves as a globalStyles.css file

export default css`
  /* react-tooltip */

  .tooltip {
    background-color: ${rgba(Colors.black, 0.75)} !important;
    font-size: ${fontSizes.tiny} !important;
    padding: ${Spacing.tiny} ${Spacing.small} !important;
    border-radius: 5px !important;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out !important;
    max-width: 260px !important;
    text-align: center !important;

    &.large {
      font-size: ${fontSizes.small} !important;
    }

    &.place-right {
      margin-left: 13px !important;
    }

    &.offset {
      margin-left: -8px !important;
    }

    &.show {
      opacity: 1 !important;
    }

    &:before {
      display: none !important;
    }

    &:after {
      border-top-color: ${rgba(Colors.black, 0.75)} !important;
      border-top-width: 5px !important;
      border-left: 5px solid transparent !important;
      border-right: 5px solid transparent !important;
      bottom: -5px !important;
      margin-left: -5px !important;
    }

    &.place-left:after {
      border-top-color: transparent !important;
      border-left-color: ${rgba(Colors.black, 0.75)} !important;
      border-right-color: transparent !important;
      margin-top: -5px !important;
      right: -11px !important;
    }

    &.place-right:after {
      border-top-color: transparent !important;
      border-right-color: ${rgba(Colors.black, 0.75)} !important;
      margin-top: -5px !important;
      left: -6px !important;
    }

    &.type-dark.place-bottom:after {
      border-bottom-color: ${rgba(Colors.black, 0.75)} !important;
    }
  }

  /* react-select */

  .select__control {
    border-color: ${Colors.borderColor} !important;
    border-radius: 5px !important;
  }

  .select__control--is-focused {
    border-color: ${Colors.primaryColor} !important;
    box-shadow: 0 0 0 1px ${Colors.primaryColor} !important;
  }

  .select__single-value {
    padding-right: ${Spacing.medium} !important;
    color: ${Colors.grey} !important;
  }

  .select__value-container {
    padding: 4px 14px !important;
  }

  .select__value-container--is-multi.select__value-container--has-value {
    padding-left: 8px !important;
  }

  .select__value-container--is-multi.select__value-container--has-value + .select__indicators {
    display: none !important; /* Hide dropdown when multi select and has at least 1 value */
  }

  .select__placeholder {
    color: ${rgba(Colors.grey, 0.3)} !important;
  }

  .select__clear-indicator {
    /* Hide clear button */
    /* display: none !important; */
  }

  .select__indicator-separator {
    /* display: none !important; */
  }

  .select__multi-value {
    border-radius: 5px !important;
    background-color: ${rgba(Colors.borderColor, 0.5)} !important;
  }

  .select__multi-value__label {
    font-size: ${fontSizes.small} !important;
    color: ${Colors.grey} !important;
  }

  .select__multi-value__remove {
    cursor: pointer !important;
    color: ${Colors.greyLight} !important;
    margin-left: -${Spacing.tiny} !important;

    svg {
      width: 12px !important;
      height: 12px !important;
    }
  }

  .select__multi-value__remove:hover {
    color: ${Colors.grey} !important;
    background-color: transparent !important;
  }

  .select__menu {
    border-color: ${Colors.borderColor} !important;
    border-radius: 5px !important;
    overflow: hidden !important;
  }

  .select__option {
    padding: ${Spacing.small} ${Spacing.medium} !important;
    color: ${Colors.grey};
  }

  .select__option:hover,
  .select__option--is-focused,
  .select__option--is-selected {
    color: ${Colors.greyDark} !important;
    background-color: ${rgba(Colors.borderColor, 0.5)} !important;
  }

  .select__menu-notice--no-options {
    padding: ${Spacing.medium} !important;
    color: ${Colors.greyLight} !important;
  }

  /* react-mentions */

  .mentionWrapper {
    width: 100%;

    .mentionWrapper__control {
      line-height: ${lineHeights.base};
      min-height: 45px;
    }

    .mentionWrapper__highlighter,
    .mentionWrapper__input {
      padding: ${Spacing.medium};
    }

    .mentionWrapper__highlighter {
      z-index: 1; /* Position above input */
      pointer-events: none;
      border: 0 !important;
    }

    .mentionWrapper__input {
      line-height: ${lineHeights.base};
      resize: none;
      overflow: hidden;

      &::placeholder {
        color: ${rgba(Colors.grey, 0.3)};
      }
    }
  }

  .mentionWrapper__suggestions {
    border-radius: 5px;
    border: 1px solid ${Colors.borderColor};
    box-shadow: 0 2px 5px 0 ${rgba(Colors.black, 0.05)};
  }

  .mentionWrapper__suggestions__item {
    font-size: ${fontSizes.small};
    padding: ${Spacing.small} ${Spacing.medium};
  }

  .mentionWrapper__suggestions__item--focused {
    background-color: ${rgba(Colors.borderColor, 0.5)} !important;
    color: ${Colors.greyDark} !important;
  }

  .mentionedFriend {
    color: ${Colors.primaryColor} !important;
    background-color: ${rgba(Colors.primaryColor, 0.25)};
    border-radius: 3px;
  }

  /* INPUT */
  /* input[type='time']::-webkit-datetime-edit-ampm-field {
    display: none;
  }
  input[type='time']::-webkit-clear-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    appearance: none;
    margin: -10px;
  } */
`
