import { Channel, Colors, SendBirdUtils, Message, MessagingService, Spacing, useChatRoom } from '@walter/shared'
import { Avatar, t } from '@walter/shared-web'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import Bubble from './Bubble'
import { CustomMessageBubble } from './CustomMessageBubble'
import UserGroupingHeader from './MessagesUserGroupingHeader'

const Container = styled.div<{ isFromMe: boolean }>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: ${Spacing.large};

  &:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.isFromMe &&
    css`
      justify-content: flex-end;
    `}
`

const AvatarWrap = styled.div`
  margin-right: ${Spacing.medium};
`

const ReadUsers = styled.div`
  margin-top: ${Spacing.xSmall};
  margin-bottom: ${Spacing.medium};
  display: flex;

  > * + * {
    margin-left: ${Spacing.tiny};
  }
`

const Main = styled.div<{ isFromMe: boolean }>`
  flex: 1;
  width: 80%;
  max-width: 432px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: ${Spacing.xLarge};

  ${(props) =>
    props.isFromMe &&
    css`
      align-items: flex-end;
      padding-right: 0;
    `}
`

const Item = styled.div`
  position: static;

  & + & {
    margin-top: ${Spacing.small};
  }
`

export interface MessagesUserGroupingProps {
  messages: Message[]
  currentUserId: string
  onCreateTask?: (taskName: string) => void
  readReceiptsByIdMap: ReturnType<typeof useChatRoom>['readReceiptsByIdMap']
  members: NonNullable<ReturnType<typeof useChatRoom>['conversation']>['members']
  promptDeleteMessage: (message: Message) => void
}

export function MessagesUserGrouping(props: MessagesUserGroupingProps) {
  const { members, readReceiptsByIdMap, onCreateTask, currentUserId, messages, promptDeleteMessage } = props

  const { userProfile, isFromManagement, isFromMe } = useMemo(() => {
    const senderId = messages[0].sender.userId
    const userProfile = members.find((m) => m.userId === senderId)
    return {
      userProfile,
      isFromMe: currentUserId === senderId,
      isFromManagement:
        (userProfile as Channel['members'][number])?.metaData?.role === 'MANAGER' &&
        MessagingService.channelIsPrivate(messages[0].channelUrl),
    }
  }, [messages, members, currentUserId])

  return (
    <Container data-test-id={`Messages_Group_${userProfile?.nickname}`} isFromMe={isFromMe}>
      {!isFromMe && (
        <AvatarWrap>
          <Avatar name={userProfile?.nickname} photo={userProfile?.profileUrl || undefined} color={Colors.grey} />{' '}
        </AvatarWrap>
      )}
      <Main isFromMe={isFromMe}>
        {!SendBirdUtils.isCustomMessageType(messages[0]) && (
          <UserGroupingHeader
            authorName={userProfile?.nickname}
            isFromMe={isFromMe}
            isFromManagement={isFromManagement}
            createdAt={messages[0].createdAt}
          />
        )}
        {messages.map((msg) => {
          const isNoteMessage = MessagingService.channelIsPrivate(msg.channelUrl)
          const readData = readReceiptsByIdMap.get(`${msg.messageId}`)
          const readDataLimit =
            isFromMe && (userProfile as Channel['members'][number])?.metaData?.role === 'MANAGER' ? readData?.length : 1
          const isCustomMessage = SendBirdUtils.isCustomMessageType(msg)
          return (
            <React.Fragment key={msg.messageId}>
              {!isCustomMessage && (
                <Item>
                  <Bubble
                    message={msg}
                    isFromMe={isFromMe}
                    isFromManagement={isFromManagement}
                    isNote={isNoteMessage}
                    promptDeleteMessage={promptDeleteMessage}
                    showToast={() => console.error('todo(chat): showToast')}
                    onCreateTask={onCreateTask}
                  />
                </Item>
              )}
              {isCustomMessage && (
                <Item>
                  <CustomMessageBubble message={msg} />
                </Item>
              )}
              {!!readData?.length && (
                <Item>
                  <ReadUsers>
                    {readData.slice(0, readDataLimit).map((member) => {
                      const profile = members.find((m) => m.userId === member.userId)
                      if (!profile) return null
                      const name = profile.nickname
                      return (
                        <Avatar
                          key={profile.userId}
                          size="tiny"
                          hint={`${t('seen-by')} ${name}`}
                          name={name}
                          photo={profile.profileUrl}
                          color={Colors.grey}
                        />
                      )
                    })}
                  </ReadUsers>
                </Item>
              )}
            </React.Fragment>
          )
        })}
      </Main>
    </Container>
  )
}
