import { Colors, Spacing } from '@walter/shared'
import { UploadUtils } from '@walter/shared'
import { rgba, stripUnit } from 'polished'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { useFormContext } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { borderRadius, cover, inputHeightSmall } from '../../../styles/global'
import { fontSizes } from '../../../styles/typography'
import { t } from '../../../utils'
import { ErrorMessage } from '../../ErrorMessage'
import { Icon } from '../../Icon'
import { Label } from '../../Label'
import { Button } from '../Button'
import { FileWithPreview, readFilesFromInput, WalterFile } from './common'

const Inner = styled.div<{ stacked: boolean }>`
  display: flex;
  align-items: center;

  ${(props) =>
    props.stacked &&
    css`
      display: block;
    `}
`

const File = styled.div<{ stacked: boolean }>`
  position: relative;
  display: block;
  align-items: center;
  height: ${inputHeightSmall};
  line-height: ${inputHeightSmall};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
  background-color: ${Colors.offWhite};
  border-radius: ${borderRadius};
  font-size: ${fontSizes.small};

  ${(props) =>
    props.stacked &&
    css`
      margin-right: 0;
    `}

  & + & {
    margin-top: ${Spacing.small};
  }
`

const Overlay = styled.a`
  ${cover('absolute')};
`

const FileDelete = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0%;
  z-index: 10;
  background-color: ${Colors.offWhite};
  padding: 0 ${Spacing.small};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100%;
    width: 40px;
    background: linear-gradient(270deg, ${Colors.offWhite} 0%, ${rgba(Colors.offWhite, 0)} 100%);
    pointer-events: none;
  }
`

const ButtonWrap = styled.div<{ stacked: boolean }>`
  ${(props) =>
    props.stacked
      ? css`
          display: block;
          margin-top: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
        `
      : css`
          display: flex;
        `}
`

const UploadWrap = styled.span<{ stacked: boolean }>`
  ${(props) =>
    props.stacked &&
    css`
      margin-right: ${Spacing.small};
    `}
`

type FileUploadProps = {
  dataTestId?: string
  label?: string
  hint?: string
  name: string
  disabled?: boolean
}

export const FileUploadSingle = ({ name, label, disabled, dataTestId }: FileUploadProps) => {
  const {
    formState: { isSubmitting, errors },
    setValue,
    watch,
    setError,
    clearErrors,
  } = useFormContext()
  const errorMessage = errors[name as string]?.message as unknown as string
  const fileHandled: FileWithPreview | WalterFile = watch(name)

  const handleInputChange = (acceptedFiles: File[]) => {
    try {
      UploadUtils.validateFileSize(acceptedFiles)
    } catch (error) {
      setError(name, { type: 'custom', message: error?.toString() })
    }

    const filesRead = readFilesFromInput(acceptedFiles)
    setValue(name, filesRead[0], { shouldDirty: true })
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleInputChange,
    accept: '.doc,.docx,application/msword,image/*,.pdf,video/*',
    multiple: false,
    disabled,
  })

  const clearFile = () => {
    setValue(name, [], { shouldDirty: true })
  }

  const previewUrl: string =
    fileHandled &&
    (('preview' in fileHandled && fileHandled.preview) || ('url' in fileHandled && fileHandled.url) || fileHandled.name)

  return (
    <>
      <Label>{label}</Label>
      <Inner stacked={true}>
        {fileHandled && (
          <File data-test-id={`${dataTestId}_File`} stacked={false}>
            <Overlay href={previewUrl} target="_blank" rel="noopener noreferrer" />
            {fileHandled.name}
            <FileDelete
              data-test-id={`${dataTestId}_Close_Button`}
              onClick={(e) => {
                e.preventDefault()
                clearFile()
              }}
              disabled={disabled || isSubmitting}
            >
              <Icon icon="close" size="tiny" />
            </FileDelete>
          </File>
        )}
        <ButtonWrap stacked={Boolean(fileHandled)}>
          {!fileHandled && (
            <UploadWrap {...getRootProps()} stacked={false}>
              <input {...getInputProps()} multiple={false} disabled={disabled || isSubmitting} />
              <Button
                data-test-id={dataTestId + '_Upload_Button'}
                isSmall
                disabled={disabled || isSubmitting}
                text={t('add-file')}
              />
            </UploadWrap>
          )}

          {fileHandled && (
            <Button
              data-test-id={dataTestId + '_Remove_Button'}
              isSmall
              text={t('remove')}
              onClick={() => {
                setValue(name, null, { shouldDirty: true })
                clearErrors(name)
              }}
              disabled={disabled || isSubmitting}
            />
          )}
        </ButtonWrap>
      </Inner>
      {errorMessage && <ErrorMessage data-cy="error-message">{errorMessage}</ErrorMessage>}
    </>
  )
}

export const FileUploadMulti = ({ name, label, disabled, dataTestId }: FileUploadProps) => {
  const {
    formState: { isSubmitting, errors },
    setValue,
    watch,
    setError,
    clearErrors,
  } = useFormContext()
  const errorMessage = errors[name as string]?.message as unknown as string
  const filesHandled: (FileWithPreview | WalterFile)[] = watch(name) ?? []

  const handleInputChange = (acceptedFiles: File[]) => {
    try {
      UploadUtils.validateFileSize(acceptedFiles)
    } catch (error) {
      setError(name, { type: 'custom', message: error?.toString() })
    }

    const filesRead = readFilesFromInput(acceptedFiles)
    setValue(name, [...filesHandled, ...filesRead], { shouldDirty: true })
  }

  const clearFile = (previewOrName: string) => {
    setValue(
      name,
      filesHandled.filter((f) => {
        return (
          ('preview' in f && f.preview !== previewOrName && 'name' in f && f.name !== previewOrName) ||
          ('url' in f && f.url !== previewOrName)
        )
      }),
      { shouldDirty: true },
    )
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleInputChange,
    accept: '.doc,.docx,application/msword,image/*,.pdf,video/*',
    multiple: true,
    disabled,
  })

  return (
    <>
      <Label>{label}</Label>
      <Inner data-test-id={`${dataTestId}_Inner`} stacked={true}>
        {filesHandled?.length > 0 &&
          filesHandled.map((file, i) => {
            const previewUrl: string =
              file && (('preview' in file && file.preview) || ('url' in file && file.url) || file.name)

            return (
              <File key={i} stacked={true}>
                <Overlay
                  data-test-id={`${dataTestId}_File`}
                  href={previewUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                />
                {file.name}

                <FileDelete
                  data-test-id={`${dataTestId}_Close_Button`}
                  onClick={(e) => {
                    e.preventDefault()
                    clearFile(previewUrl)
                  }}
                  disabled={disabled || isSubmitting}
                >
                  <Icon icon="close" size="tiny" />
                </FileDelete>
              </File>
            )
          })}

        <ButtonWrap stacked={filesHandled?.length > 0}>
          {!filesHandled?.length && (
            <UploadWrap {...getRootProps()} stacked={true}>
              <input {...getInputProps()} multiple={true} />
              <Button
                data-test-id={dataTestId + '_Upload_Button'}
                isSmall
                disabled={disabled || isSubmitting}
                text={t('add-files')}
              />
            </UploadWrap>
          )}

          {filesHandled?.length > 0 && (
            <>
              <UploadWrap {...getRootProps()} stacked={true}>
                <input {...getInputProps()} multiple={true} />
                <Button
                  data-test-id={dataTestId + '_Upload_Button'}
                  isSmall
                  disabled={disabled || isSubmitting}
                  text={`${t('add-files')}`}
                />
              </UploadWrap>
            </>
          )}

          {filesHandled?.length > 0 && (
            <Button
              data-test-id={dataTestId + '_Remove_Button'}
              isSmall
              onClick={() => {
                setValue(name, [], { shouldDirty: true })
                clearErrors(name)
              }}
              disabled={disabled || isSubmitting}
              text={filesHandled?.length > 1 ? t('remove-all') : t('remove')}
            />
          )}
        </ButtonWrap>
      </Inner>
      {errorMessage && <ErrorMessage data-cy="error-message">{errorMessage}</ErrorMessage>}
    </>
  )
}
