import { Config } from '@walter/shared'
import { ErrorState } from '@walter/shared-web'
import { t } from '../../utils'
import React from 'react'
import { ErrorBoundary as RErrorBoundary, FallbackProps } from 'react-error-boundary'
import { captureError } from '../../utils/logger'

export function ErrorBoundary({ children }: { children: React.ReactNode }) {
  return <RErrorBoundary FallbackComponent={ErrorFallback}>{children}</RErrorBoundary>
}

function ErrorFallback({ error }: FallbackProps) {
  React.useEffect(() => {
    if (Config.isProduction && error) {
      captureError(error)
    }
  }, [error])

  if (Config.isProduction) {
    return <ErrorState errorMessage={t('general:an-error-occured-weve-received-it')} />
  }

  // TODO: Can we redo this component so that it shows more detail on the error when we're in dev?
  return (
    <div role="alert">
      <p>Something went wrong (Look in Javascript Developer console for more information on error)</p>
      <pre>{error.message}</pre>
      <pre>{error.stack}</pre>
    </div>
  )
}
