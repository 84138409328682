import { Colors, Spacing, Types } from '@walter/shared'
import React from 'react'
import styled from 'styled-components'
import { borderRadius, boxShadow } from '../../styles/global'
import { t } from '../../utils'
import { useOutsideAlerter } from '../../utils/hooks'
import { Button } from '../Button/index'
import { Dropdown } from '../Dropdown'
import { Label } from '../Label'
import { Select } from '../Select'
import { FilterOption } from './types'

const DEFAULT_BOOLEAN_OPTIONS = [
  {
    label: 'True',
    value: 'true',
  },
  {
    label: 'False',
    value: 'false',
  },
]

const Heading = styled(Label)`
  display: block;
  margin-bottom: ${Spacing.small};
`

const Row = styled.div`
  display: block;

  & + & {
    margin-top: ${Spacing.small};
  }
`

const OptionsWrap = styled.div`
  position: relative;
  margin-right: ${Spacing.small};
`

const Menu = styled.div`
  position: absolute;
  top: 100%;
  left: 0%;
  padding: ${Spacing.medium};
  border: 1px solid ${Colors.borderColor};
  border-radius: ${borderRadius};
  width: 200px;
  background-color: ${Colors.white};
  z-index: 100;
  margin-top: ${Spacing.small};
  ${boxShadow};
`

type FilterOptionsProps = {
  dataTestId?: string
  options: FilterOption[]
  onAddNewFilter: (pathOption: FilterOption | undefined, value: string | number | undefined) => void
  heading?: string
}

export function FilterOptions({ dataTestId, heading, options, onAddNewFilter }: FilterOptionsProps) {
  const [expanded, setExpanded] = React.useState(false)
  const [pathDropdown, setPathDropdown] = React.useState<string | number | undefined>('')
  const [valueDropdown, setValueDropdown] = React.useState<string | number | undefined>()

  const wrapperRef = React.useRef(null)
  useOutsideAlerter(wrapperRef, () => setExpanded(false))

  const currentPathOption = React.useMemo(
    () => options.find(({ pathOptionValue }) => pathOptionValue === pathDropdown),
    [pathDropdown, options],
  )

  const pathOptions = React.useMemo(() => {
    return options.map(({ pathOptionLabel, pathOptionValue }) => ({
      label: pathOptionLabel,
      value: pathOptionValue,
    }))
  }, [options])

  const pathValueOptions = React.useMemo(() => {
    if (!currentPathOption) {
      return []
    }

    if (currentPathOption.type === 'boolean') {
      return DEFAULT_BOOLEAN_OPTIONS
    }

    if (currentPathOption.type === 'dropdown' || currentPathOption.type === 'select') {
      return currentPathOption.dropdownOptions
    }

    return []
  }, [currentPathOption])

  React.useEffect(() => {
    if (valueDropdown) {
      onAddNewFilter(currentPathOption, valueDropdown)
      setPathDropdown(undefined)
      setValueDropdown(undefined)
      setExpanded(false)
    }
  }, [valueDropdown, currentPathOption])

  return (
    <OptionsWrap>
      <Button onClick={() => setExpanded(!expanded)} dataTestId={dataTestId + '_btnFilter'}>
        {t('filter')}
      </Button>

      {expanded && (
        <Menu ref={wrapperRef}>
          {heading && <Heading>{heading}</Heading>}
          <Row>
            <Dropdown
              placeholder={t('select')}
              value={pathDropdown}
              onChange={setPathDropdown}
              dataTestId={dataTestId + '_ddlFilter'}
              options={[{ label: t('placeholder-select-option'), value: undefined } as Types.Option].concat(
                pathOptions,
              )}
            />
          </Row>
          {pathDropdown && <Row>{t('is')}</Row>}

          {pathDropdown && currentPathOption && (
            <Row>
              <ValueInput
                type={currentPathOption.type}
                value={valueDropdown}
                options={pathValueOptions ?? []}
                onChange={setValueDropdown}
                dataTestId={dataTestId}
              />
            </Row>
          )}
        </Menu>
      )}
    </OptionsWrap>
  )
}

type ValueInputProps = {
  type: FilterOption['type']
  value: string | number | undefined
  options: Types.Option[]
  onChange: (value: string | number | undefined) => void
  dataTestId?: string
}

function ValueInput({ type, value, options, onChange, dataTestId }: ValueInputProps) {
  if (type === 'dropdown' || type === 'boolean') {
    return (
      <Dropdown
        dataTestId={dataTestId + '_ddlFilterValue'}
        placeholder={t('select')}
        onChange={onChange}
        value={value}
        options={[{ label: t('placeholder-select-option'), value: undefined }].concat(options)}
      />
    )
  }

  if (type === 'select') {
    return (
      <Select
        id={dataTestId + '_ddlFilterValue'}
        placeholder={t('choose')}
        onChange={(option) => {
          onChange(option?.value)
        }}
        value={value}
        options={options}
      />
    )
  }

  return null
}
