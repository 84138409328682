import * as yup from 'yup'
import { t } from '../../utils'

export const TaskSettingsi18nFields = {
  sendOnPressEnter: t('send-message-when-pressing-enter'),
}

export const taskSettingsSchema = yup.object({
  sendOnPressEnter: yup.boolean().optional(),
})

export type TaskSettingsFormSchema = yup.InferType<typeof taskSettingsSchema>
