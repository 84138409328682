import * as yup from 'yup'
import { i18n, t } from '../../utils'

export const AccessKeysi18nFields = {
  number: t('number'),
  property: t('unit'),
  owners: t('residents-uppercase'),
}

export const AccessKeysSchema = yup.object({
  number: yup.string().required(() => i18n.t('input:is-required', { field: AccessKeysi18nFields.number })),
  property: yup.object({
    id: yup
      .string()
      .required(() => i18n.t('input:is-required', { field: AccessKeysi18nFields.property }))
      .nullable(),
  }),
  owners: yup.array(yup.object({ id: yup.string() })),
})

export const accessKeysUpdateSchema = AccessKeysSchema.clone().concat(yup.object({ id: yup.string() }))

export type AccessKeys = yup.InferType<typeof AccessKeysSchema>
export type AccessKeysUpdate = yup.InferType<typeof accessKeysUpdateSchema>
