import * as yup from 'yup'
import { t } from '../../../utils'
import { fieldIsTooLong } from './../common'

export const SavedRepliesi18nFields = {
  title: t('title'),
  message: t('message'),
}

export const savedRepliesSchema = yup.object({
  id: yup.number().min(0).optional(),
  title: yup.string().max(200, fieldIsTooLong(200, SavedRepliesi18nFields.message)).required(),
  message: yup.string().max(200, fieldIsTooLong(200, SavedRepliesi18nFields.message)).required(),
})

export type SavedReplies = yup.InferType<typeof savedRepliesSchema>
