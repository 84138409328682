import * as yup from 'yup'
import { i18n, t } from '../../utils'

export const Registeri18nFields = {
  shortName: t('auth:company-name'),
  email: t('auth:email'),
  password: t('auth:password'),
}

export const RegisterSchema = yup.object({
  shortName: yup.string().required(i18n.t('input:is-required', { field: Registeri18nFields.shortName })),
  email: yup
    .string()
    .email(t('enter-email-invalid'))
    .required(i18n.t('input:is-required', { field: Registeri18nFields.email })),
  password: yup
    .string()
    .min(8, t('create-strong-password'))
    .required(i18n.t('input:is-required', { field: Registeri18nFields.password })),
})

export type Register = yup.InferType<typeof RegisterSchema>
