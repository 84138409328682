/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Colors, Spacing } from '@walter/shared'
import { WebDateUtils } from '../../utils/date'
import React, { ChangeEvent, useState } from 'react'
import Calendar from 'react-calendar/dist/entry.nostyle'
import styled, { css } from 'styled-components'
import { boxShadowDark } from '../../styles/global'
import { t } from '../../utils'
import { useOutsideAlerter } from '../../utils/hooks'
import { Button } from '../Button'
import { ButtonGroup } from '../ButtonGroup'
import { Icon } from '../Icon'
import { Input } from '../Input'
import { DatePickerStyled } from './styles'

type DatePickerPosition = 'top' | 'bottom'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CalendarWrap = styled.div<{ withTime: boolean; position: DatePickerPosition }>`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: ${Colors.white};
  margin-top: ${Spacing.small};

  ${(props) =>
    props.withTime &&
    css`
      margin-bottom: ${Spacing.xLarge};
      padding: ${Spacing.large};
      ${boxShadowDark};
      .react-calendar {
        box-shadow: none;
      }
    `}

  ${(props) =>
    props.position === 'top' &&
    css`
      top: auto;
      bottom: 100%;
      margin-top: 0;
      margin-bottom: ${Spacing.small};
    `}
`

const Arrow = styled.span`
  display: flex;
  padding: ${Spacing.tiny};
`

const ManuallyCenteredDiv = styled.div`
  margin-top: 31px;
  margin-left: -25px;
  cursor: pointer;
  z-index: 2;
`

type DatePickerProps = {
  dataTestId?: string
  label: string
  value: Date | null
  onChange: (date: Date | null) => void
  placeholder?: string
  position?: DatePickerPosition
  withTime?: boolean
  calendarProps?: any // https://github.com/wojtekmaj/react-calendar/blob/master/src/Calendar.jsx
}

export const DatePicker = React.memo(
  ({
    dataTestId,
    label,
    value,
    onChange,
    placeholder,
    position = 'bottom',
    withTime,
    calendarProps,
  }: DatePickerProps) => {
    const [calendarVisible, setCalendarVisible] = useState(false)
    const wrapperRef = React.useRef(null)

    useOutsideAlerter(wrapperRef, () => setCalendarVisible(false))

    function handleCalendarChange(newDate: Date) {
      if (withTime) {
        onChange(newDate)
      } else {
        setCalendarVisible(false)
        onChange(newDate)
      }
    }

    function handleClearCalendar() {
      onChange(null)
    }

    function convertHourMinutes(time: string) {
      const [hours, minutes] = time.split(':').map((val) => parseInt(val))

      return WebDateUtils.dayjs(value ?? new Date())
        .set('hours', hours)
        .set('minutes', minutes)
        .toDate()
    }

    function handleClickConfirm() {
      const dateTime = convertHourMinutes(time)

      onChange(dateTime)
      setCalendarVisible(false)
    }

    function handleTimeChange(e: ChangeEvent<HTMLInputElement>) {
      const dateWithTime = convertHourMinutes(e.target.value)

      onChange(dateWithTime)
    }

    const time = React.useMemo(() => {
      return WebDateUtils.format(value || new Date(), 'HH:mm')
    }, [value])

    const formattedTextInputValue = React.useMemo(() => {
      if (!value) {
        return ''
      }

      if (withTime) {
        return WebDateUtils.format(value || new Date(), 'YYYY-MM-DD - HH:mm')
      }

      return WebDateUtils.format(value || new Date(), 'YYYY-MM-DD')
    }, [withTime, value])

    return (
      <DatePickerStyled data-cy="pick-a-date">
        <Container data-test-id={`${dataTestId}_DatePicker`}>
          <Input
            dataTestId={`${dataTestId}_Input`}
            label={label}
            placeholder={placeholder}
            onClick={() => setCalendarVisible(true)}
            value={formattedTextInputValue}
            readOnly
          />
          {calendarVisible && (
            <CalendarWrap
              data-test-id={`${dataTestId}_Calendar`}
              ref={wrapperRef}
              position={position}
              withTime={!!withTime}
            >
              <Calendar
                onChange={(newDate) => handleCalendarChange(Array.isArray(newDate) ? newDate[0] : newDate)}
                value={value}
                prevLabel={
                  <Arrow>
                    <Icon icon="left-chevron" size="small" />
                  </Arrow>
                }
                nextLabel={
                  <Arrow>
                    <Icon icon="right-chevron" size="small" />
                  </Arrow>
                }
                {...calendarProps}
              />
              {withTime && (
                <>
                  <div
                    style={{
                      marginTop: Spacing.medium,
                    }}
                  >
                    <Input value={time} type="time" label="Time" onChange={handleTimeChange} />
                  </div>

                  <div
                    style={{
                      marginTop: Spacing.large,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <ButtonGroup>
                      <Button testID="cancel-button" size="small" onClick={() => setCalendarVisible(false)}>
                        {t('cancel')}
                      </Button>
                      <Button testID="confirm-button" size="small" theme="primary" onClick={handleClickConfirm}>
                        {t('confirm')}
                      </Button>
                    </ButtonGroup>
                  </div>
                </>
              )}
            </CalendarWrap>
          )}
          <ManuallyCenteredDiv onClick={handleClearCalendar}>
            <Icon size={'small'} icon="close" color={Colors.greyLight} />
          </ManuallyCenteredDiv>
        </Container>
      </DatePickerStyled>
    )
  },
)
