import React from 'react'
import styled from 'styled-components'
import { Spacing } from '@walter/shared'

const Container = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: -${Spacing.tiny};
  justify-content: flex-start;
`

const Item = styled.div`
  padding: ${Spacing.tiny};
  display: flex;
`

type PillGroupProps = {
  dataTestId?: string
  children: React.ReactNode
}

export const PillGroup = ({ dataTestId, children }: PillGroupProps) => (
  <Container data-test-id={dataTestId}>
    {React.Children.map(children, (child, i) => child && <Item key={i}>{child}</Item>)}
  </Container>
)
