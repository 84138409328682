import { Api } from '@walter/shared'
import * as yup from 'yup'
import { i18n, t } from '../../utils'
import { fieldIsTooLong } from './common'

export const Adi18nFields = {
  title: t('fields-ad:title'),
  description: t('fields-ad:description'),
  price: t('fields-ad:price'),
  images: t('fields-ad:photos'),
  owner: t('fields-ad:owner'),
  createAt: t('fields-ad:posted-on'),
  phoneNumber: t('marketplace:mobile-phone-number'),
  email: t('marketplace:email'),
}

export const adSchema = yup.object({
  title: yup
    .string()
    .required(i18n.t('input:is-required', { field: Adi18nFields.title }))
    .max(20, fieldIsTooLong(20, Adi18nFields.title)),
  description: yup.string().optional().max(200, fieldIsTooLong(200, Adi18nFields.description)),
  price: yup
    .number()
    .typeError(i18n.t('input:must-be-number', { field: Adi18nFields.price }))
    .max(9_999_999, i18n.t('input:too-high', { field: Adi18nFields.price, max: 9_999_999 }))
    .optional(),
  phone: yup
    .object({
      number: yup
        .string()
        .test(
          'phoneNumber',
          i18n.t('input:must-be-phone-number', {
            field: Adi18nFields.phoneNumber,
            formats: '+11231231234, 1231231234',
          }),
          (value) => {
            const regex = new RegExp(/(\+[1-9])?[0-9]{3}[0-9]{3}[0-9]{4}/)
            return value != null && value.trim().length > 0 ? regex.test(value) : true
          },
        )
        .nullable()
        .optional(),
    })
    .nullable()
    .optional(),
  email: yup.string().email().optional(),
  status: yup.string().oneOf(Object.values(Api.Ad_Status)).defined(),
  isSold: yup.boolean().nullable().default(false),
  owner: yup
    .object({ id: yup.string().required(i18n.t('input:is-required', { field: Adi18nFields.owner })) })
    .typeError(i18n.t('input:is-required', { field: Adi18nFields.owner }))
    .required(i18n.t('input:is-required', { field: Adi18nFields.owner })),
  images: yup.mixed(),
})

export const adSchemaUpdate = adSchema.clone().concat(yup.object({ id: yup.string() }))

export type Ad = yup.InferType<typeof adSchema> & { status: Api.Ad_Status }
export type AdUpdate = yup.InferType<typeof adSchemaUpdate> & { status: Api.Ad_Status }
