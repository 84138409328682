import { Colors, Spacing } from '@walter/shared'
import { PillProps } from '@walter/shared-web'
import { mix, rgba, stripUnit } from 'polished'
import React from 'react'
import styled, { css } from 'styled-components'
import {
  animationCurve,
  animationTime,
  borderRadius,
  boxShadow,
  boxShadowDark,
  cover,
  square,
} from '../../styles/global'
import { fontSizes } from '../../styles/typography'
import { Avatar, AvatarSize } from '../Avatar'
import { BuildingLogo } from '../BuildingLogo'
import { Button, ButtonProps } from '../Button'
import { ButtonGroup } from '../ButtonGroup'
import { DisplayContent } from '../DisplayRichTextarea'
import { Pill } from '../Pill'

export { ResourceItemSimplified } from './simple'

const Container = styled.div<{ type: ResourceItemType }>`
  position: relative;
  background-color: ${Colors.white};
  width: 100%;
  transition: all ${animationTime} ${animationCurve};

  ${(props) =>
    props.type === 'list' &&
    css`
      padding: ${Spacing.large};

      &:hover {
        background-color: ${mix(0.5, Colors.offWhite, Colors.white)};
      }

      & + & {
        border-top: 1px solid ${Colors.borderColor};
      }
    `}

  ${(props) =>
    props.type === 'grid' &&
    css`
      display: flex;
      flex-direction: column;
      border-radius: ${borderRadius};
      border: 1px solid ${Colors.borderColor};
      overflow: hidden;
      ${boxShadow};

      &:hover {
        transform: translateY(-2px);
        ${boxShadowDark};
      }
    `}
`
const Extra = styled.div`
  padding: 0 ${Spacing.large};
  min-width: 216px;
`

const Inner = styled.div<{ type: ResourceItemType }>`
  display: flex;

  ${(props) =>
    props.type === 'list' &&
    css`
      align-items: center;
    `}

  ${(props) =>
    props.type === 'grid' &&
    css`
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
    `}
`

const FigureWrap = styled.div<{ type: ResourceItemType }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  img {
    ${cover('absolute')};
    object-fit: cover;
  }

  ${(props) =>
    props.type === 'list' &&
    css`
      margin-right: ${Spacing.medium};
      border-radius: ${borderRadius};
      overflow: hidden;
      ${square('40px')};
    `}

  ${(props) =>
    props.type === 'grid' &&
    css`
      width: 100%;
      height: 130px;
    `}
`

const Main = styled.div<{ type: ResourceItemType }>`
  flex: 2;

  ${(props) =>
    props.type === 'list' &&
    css`
      padding-right: ${Spacing.large};
    `}

  ${(props) =>
    props.type === 'grid' &&
    css`
      width: 100%;
      padding: ${Spacing.large};
    `}
`

const Name = styled.strong`
  display: block;
  color: ${Colors.greyDark};
  margin-bottom: ${`${(stripUnit(Spacing.tiny) as number) * 0.5}px`};

  &:last-child {
    margin-bottom: 0;
  }
`

const Description = styled.p`
  white-space: pre-wrap; /* Needed because sometimes we put break line and some formatting in the text */
  font-size: ${fontSizes.small};
  margin-bottom: ${Spacing.small};
`

const DescriptionWrap = styled.div`
  white-space: pre-wrap; /* Needed because sometimes we put break line and some formatting in the text */
  font-size: ${fontSizes.small};
  margin-bottom: ${Spacing.small};
`

const Meta = styled.p`
  font-size: ${fontSizes.small};
  color: ${Colors.greyLight};
`

const Overlay = styled.div`
  display: block;
  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

  ${cover('absolute')};
`

const Actions = styled.div<{ type: ResourceItemType }>`
  position: relative;
  /* Position above overlay */
  z-index: 1;
  flex-shrink: 0;

  ${(props) =>
    props.type === 'grid' &&
    css`
      margin-bottom: ${Spacing.large};
      margin-left: ${Spacing.large};
    `}
`

const TopWrap = styled.div`
  margin-bottom: ${Spacing.small};
  display: flex;
  align-items: flex-start;
`

const HighlightedBar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: ${Spacing.small};
  background: ${Colors.primaryColor};
`

const BuildingWrap = styled.div`
  margin-left: auto;
`

const HoverActions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  bottom: 0;
  padding-right: ${Spacing.large};
  background-color: ${Colors.white};
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity ${animationTime} ${animationCurve}, visibility ${animationTime} ${animationCurve};

  ${Container}:hover & {
    opacity: 1;
    visibility: visible;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    width: 160px;
    background: linear-gradient(90deg, ${rgba(Colors.white, 0)} 0%, ${Colors.white} 100%);
  }
`

type ResourceItemType = 'list' | 'grid'

type ResourceItemProps = {
  name: string
  language?: string
  categories?: any
  type?: ResourceItemType
  description?: string | React.ReactNode
  pill?: string
  pillProps?: Omit<PillProps, 'text'>
  meta?: any
  figure?: React.ReactNode
  figureUrl?: string
  figureName?: string
  figureSize?: AvatarSize
  handleClick?: () => void
  primaryAction?: ButtonProps & { label: string }
  secondaryAction?: (ButtonProps & { label: string }) | null
  renderDescription?: () => React.ReactNode
  renderFigure?: () => React.ReactNode
  isHighlighted?: boolean
  building?: {
    name: string
    nameInitials: string
    squareLogo: {
      url: string
    }
  }
  descriptionIsHtml?: boolean
  extra?: React.ReactNode
  hoverActions?: React.ReactNode
  hint?: string
  nested?: boolean
}

/**
 * @deprecated use ResourceItemSimplified instead and pass it child nodes
 */
export const ResourceItem = React.memo(
  ({
    type = 'list',
    name,
    pill,
    pillProps,
    building,
    description,
    renderDescription,
    figure,
    figureUrl,
    figureName,
    figureSize = 'large',
    meta,
    isHighlighted,
    renderFigure,
    handleClick,
    primaryAction,
    secondaryAction,
    descriptionIsHtml,
    extra,
    hoverActions,
    hint,
  }: ResourceItemProps) => {
    const hasActions = primaryAction || secondaryAction

    return (
      <Container type={type} data-cy={`resource-${name}`} data-test-id={`ResourceItem_${name}`}>
        <Inner type={type}>
          {!!figureUrl && (
            <FigureWrap data-test-id="CoverImage_Container" type={type}>
              <Avatar name={figureName} photo={figureUrl} size={figureSize} />
            </FigureWrap>
          )}
          {figure && (
            <FigureWrap data-test-id="CoverImage_Container" type={type}>
              {figure}
            </FigureWrap>
          )}
          {renderFigure && (
            <FigureWrap data-test-id="CoverImage_Container" type={type}>
              {renderFigure()}
            </FigureWrap>
          )}
          <Main data-test-id="Main_Container" type={type}>
            {(!!pill || building) && (
              <TopWrap data-test-id="Top_Wrap">
                {!!pill && <Pill dataTestId="Pill_Item" {...pillProps} text={pill} />}
                {building && (
                  <BuildingWrap data-test-id="Building_Wrap">
                    <BuildingLogo
                      name={building.name}
                      initial={building.nameInitials}
                      logo={building.squareLogo?.url}
                    />
                  </BuildingWrap>
                )}
              </TopWrap>
            )}
            <Name data-test-id="Title">{name}</Name>
            {description && descriptionIsHtml && (
              <DisplayContent dataTestId="Description" content={`${(description as string).substring(0, 100)}`} />
            )}
            {description && !descriptionIsHtml && <Description data-test-id="Description">{description}</Description>}
            {renderDescription && (
              <DescriptionWrap data-test-id="Description_Wrap">{renderDescription()}</DescriptionWrap>
            )}
            {meta && <Meta data-test-id="Meta">{meta}</Meta>}
          </Main>
          <Extra data-test-id="Extra">{extra}</Extra>
          {hasActions && (
            <Actions data-test-id="Actions_Container" type={type} data-tip={hint}>
              <ButtonGroup spacing="tight">
                {primaryAction && (
                  <Button theme="primary" size="small" dataTestId={`${primaryAction.label}_Button`} {...primaryAction}>
                    {primaryAction.label}
                  </Button>
                )}
                {secondaryAction && (
                  <Button
                    theme="tertiary"
                    size="small"
                    dataTestId={`${secondaryAction.label}_Button`}
                    {...secondaryAction}
                  >
                    {secondaryAction.label}
                  </Button>
                )}
              </ButtonGroup>
            </Actions>
          )}
        </Inner>
        {isHighlighted && <HighlightedBar />}
        {hoverActions && <HoverActions>{hoverActions}</HoverActions>}
        <Overlay onClick={handleClick} />
      </Container>
    )
  },
)

ResourceItem.displayName = 'ResourceItem'
