import React, { useCallback } from 'react'
import { Colors, Spacing } from '@walter/shared'
import { t } from '../../utils'
import { rgba, stripUnit } from 'polished'
import { useDropzone } from 'react-dropzone'
import styled, { css } from 'styled-components'
import { borderRadius, cover, inputHeightSmall } from '../../styles/global'
import { fontSizes } from '../../styles/typography'
import { Button } from '../Button'
import { Icon } from '../Icon'
import { Label } from '../Label'

const Inner = styled.div<{ stacked?: boolean }>`
  display: flex;
  align-items: center;

  ${(props) =>
    props.stacked &&
    css`
      display: block;
    `}
`

const File = styled.div<{ stacked?: boolean }>`
  position: relative;
  display: block;
  align-items: center;
  height: ${inputHeightSmall};
  line-height: ${inputHeightSmall};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
  background-color: ${Colors.offWhite};
  border-radius: ${borderRadius};
  font-size: ${fontSizes.small};
  margin-right: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};

  ${(props) =>
    props.stacked &&
    css`
      margin-right: 0;
    `}

  & + & {
    margin-top: ${Spacing.small};
  }
`

const Overlay = styled.a`
  ${cover('absolute')};
`

const FileDelete = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0%;
  z-index: 10;
  background-color: ${Colors.offWhite};
  padding: 0 ${Spacing.small};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100%;
    width: 40px;
    background: linear-gradient(270deg, ${Colors.offWhite} 0%, ${rgba(Colors.offWhite, 0)} 100%);
    pointer-events: none;
  }
`

const ButtonWrap = styled.div<{ stacked?: boolean }>`
  display: flex;

  ${(props) =>
    props.stacked &&
    css`
      margin-top: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
    `}
`

const UploadWrap = styled.span<{ stacked: boolean }>`
  ${(props) =>
    props.stacked &&
    css`
      margin-right: ${Spacing.small};
    `}
`

type FilePreviewOrUrl = {
  preview?: string
  url?: string
  name: string
}

type FileUploadProps = {
  dataTestId?: string
  isLoading?: boolean
  label?: string
  allowMultiple?: boolean
  files?: FilePreviewOrUrl[]
  disabled?: boolean
  onChange?: (v: any[]) => void
}

export const FileUpload = ({
  dataTestId,
  label,
  files,
  onChange,
  allowMultiple,
  isLoading,
  disabled,
}: FileUploadProps) => {
  const onDrop = useCallback(
    (acceptedFiles: FilePreviewOrUrl[]) => {
      onChange?.([
        ...(files || []),
        ...acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      ])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files],
  )

  const clearFile = (preview: string) => {
    onChange?.(files?.filter((f) => f.preview !== preview && f.url !== preview) ?? [])
    window.URL.revokeObjectURL(preview)
  }

  const clearFiles = () => {
    files?.forEach((file) => {
      if (file.preview) window.URL.revokeObjectURL(file.preview)
      if (file.url) window.URL.revokeObjectURL(file.url)
    })
    onChange?.([])
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.doc,.docx,application/msword,image/*,.pdf,video/*',
    multiple: allowMultiple,
    disabled,
  })

  return (
    <>
      {label && <Label>{label}</Label>}
      <Inner stacked={allowMultiple} data-test-id={dataTestId}>
        {files &&
          files.length > 0 &&
          files.map((file, i) => (
            <File key={i} stacked={allowMultiple} data-test-id={`${dataTestId}_File_${file.name}`}>
              <Overlay href={file.preview || file.url} target="_blank" rel="noopener noreferrer" />
              {file.name}
              {allowMultiple && (
                <FileDelete
                  data-test-id={`${dataTestId}_File_${file.name}_Delete_Button`}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (file.preview) clearFile(file.preview)
                    if (file.url) clearFile(file.url)
                  }}
                  disabled={disabled}
                >
                  <Icon icon="close" size="tiny" />
                </FileDelete>
              )}
            </File>
          ))}
        <ButtonWrap stacked={allowMultiple && files && files.length > 0}>
          <UploadWrap {...getRootProps()} stacked={allowMultiple}>
            <input {...getInputProps()} multiple={allowMultiple} />
            {(!files?.length || allowMultiple) && (
              <Button
                size="small"
                isLoading={isLoading}
                testID={'shared-web-fileupload-button'}
                disabled={disabled}
                dataTestId={`${dataTestId}_Files_Upload_Button`}
              >
                {t('add-file')}
              </Button>
            )}
          </UploadWrap>
          {files && files.length > 0 && (
            <Button
              size="small"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                clearFiles()
              }}
              isLoading={isLoading}
              testID={'shared-web-fileupload-remove-button'}
              disabled={disabled}
              dataTestId={`${dataTestId}_Files_Remove_Button`}
            >
              {allowMultiple && files?.length > 1 ? t('remove-all') : t('remove')}
            </Button>
          )}
        </ButtonWrap>
      </Inner>
    </>
  )
}
