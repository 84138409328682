import { Spacing, Colors } from '@walter/shared'
import { rgba, stripUnit } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { animationCurve, animationTime, borderRadius, inputHeight } from '../../styles/global'
import { Label } from '../Label'

type FormType = {
  errors: Record<string, { message: string }>
  isSubmitting: boolean
  register: any
}

const FormContext = React.createContext({} as FormType)

type ReactForm = {
  children: React.ReactChildren
  onSubmit: (values: any) => void
  context: FormType
}

export function ReactForm({
  children,
  onSubmit,
  context,
}: {
  children: React.ReactNode
  onSubmit: (values: unknown) => void
  context: FormType
}) {
  return (
    <FormContext.Provider value={context}>
      <form onSubmit={onSubmit}>{children}</form>
    </FormContext.Provider>
  )
}

type Input = {
  name: string
  displayName: string
  type: 'text' | 'textarea'
  placeholder?: string
  [k: string]: any
}

const InputContainer = styled.div`
  width: 100%;
  padding: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
`

const StyledInput = styled.input`
  flex: 1;
  height: ${inputHeight};
  border-radius: ${borderRadius};
  box-shadow: inset 0 0 0 1px ${Colors.borderColor};
  background-color: ${Colors.white};
  width: 100%;
  resize: none;
  color: ${Colors.grey};
  vertical-align: top;
  outline: 0;
  padding: 0 ${Spacing.medium};
  transition: background-color ${animationTime} ${animationCurve}, color ${animationTime} ${animationCurve},
    box-shadow ${animationTime} ${animationCurve};

  &::placeholder {
    color: ${rgba(Colors.grey, 0.3)};
  }

  &:focus {
    background-color: ${Colors.white};
    box-shadow: inset 0 0 0 1px ${Colors.primaryColor}, 0 0 0 1px ${Colors.primaryColor};
  }

  &[disabled] {
    pointer-events: none;
    user-select: none;
    opacity: 0.5; /* Overwrite iOS styles */
  }
`

ReactForm.Input = function Input({ name, displayName, type, placeholder, ...restProps }: Input) {
  const { isSubmitting, errors, register } = React.useContext(FormContext)

  return (
    <InputContainer className={`u-${restProps.width || 'no-width'}`}>
      <Label>{displayName}</Label>

      <StyledInput type={type} {...register(name)} disabled={isSubmitting} placeholder={placeholder} />

      {errors[name] && <p className="text-sm text-red-600 mt-1">{errors[name].message}</p>}
    </InputContainer>
  )
}

type CheckBox = {
  name: string
  text: string
}

ReactForm.CheckBox = function CheckBox({ name, text }: CheckBox) {
  const { isSubmitting, errors, register } = React.useContext(FormContext)
  return (
    <div>
      <label className="flex items-center">
        <input
          type="checkbox"
          className="block border text-lg rounded w-6 h-6 border-gray-200 text-blue-600 focus:ring-0 focus:outline-none focus:ring-offset-0 disabled:text-gray-200 disabled:cursor-not-allowed"
          {...register(name)}
          disabled={isSubmitting}
        />
        <span className="block ml-4">{text}</span>
      </label>
      {errors[name] && <p className="text-sm text-red-600 mt-1">{errors[name].message}</p>}
    </div>
  )
}

ReactForm.Custom = ({ children }: { children: React.ReactNode }) => {
  return { children }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
`

ReactForm.Container = ({ children }: { children: React.ReactNode }) => {
  return <Container>{children}</Container>
}
