import { Colors, Spacing } from '@walter/shared'
import React from 'react'
import { isMobile } from 'react-device-detect'
import styled, { css } from 'styled-components'
import Logo from '../static/images/logo.svg'
import { GlobalLayout } from './GlobalLayout'

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${Colors.white};
  display: flex;
`

const Content = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
  padding: 5vw;
  ${(props) =>
    props.isMobile &&
    css`
      width: 100%;
    `}
`

const Sidebar = styled.div`
  width: 55%;
  background-color: ${Colors.black};
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);
  background-image: url('https://storage.googleapis.com/upload-mtl/walter/static/usewalter-login-image.png');
  filter: brightness(0.5);
`

const Inner = styled.div<{ isMobile: boolean }>`
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;

  ${(props) =>
    props.isMobile &&
    css`
      max-width: 100%;
    `}
`

const StyledLogo = styled(Logo)`
  display: block;
  position: absolute;
  top: ${Spacing.xLarge};
  left: ${Spacing.xLarge};
  width: 200px;
  height: 50px;
  fill: ${Colors.black};
`

type AuthContentType = {
  children: React.ReactNode
}

export const AuthContent = ({ children }: AuthContentType) => {
  return (
    <Container>
      <StyledLogo />
      <Content isMobile={isMobile}>
        <Inner isMobile={isMobile}>{children}</Inner>
      </Content>
      {!isMobile && <Sidebar />}
    </Container>
  )
}

export const AuthLayout = ({ children }: { children: React.ReactNode }) => (
  <GlobalLayout>
    <AuthContent>{children}</AuthContent>
  </GlobalLayout>
)
