import * as yup from 'yup'
import { i18n, t } from '../../utils'

export const OtherResidentsi18nFields = {
  firstName: t('first-name'),
  lastName: t('last-name'),
  email: t('email'),
  phone: t('mobile-phone-number'),
  type: t('resident-type'),
}

export const OtherResidentsSchema = yup.object({
  firstName: yup.string().required(i18n.t('input:is-required', { field: OtherResidentsi18nFields.firstName })),
  lastName: yup.string().required(i18n.t('input:is-required', { field: OtherResidentsi18nFields.lastName })),
  email: yup
    .string()
    .email(t('enter-email-invalid'))
    .required(i18n.t('input:is-required', { field: OtherResidentsi18nFields.email })),
  phone: yup.object({
    number: yup
      .string()
      .nullable()
      .optional()
      .matches(new RegExp(/(\+[1-9])?[0-9]{3}[0-9]{3}[0-9]{4}/), {
        message: t('enter-phone-number-invalid'),
        excludeEmptyString: true,
      }),
  }),
  type: yup
    .string()
    .typeError(i18n.t('input:is-required', { field: OtherResidentsi18nFields.type }))
    .required(i18n.t('input:is-required', { field: OtherResidentsi18nFields.type })),
})

export const OtherResidentsUpdateSchema = OtherResidentsSchema.clone().concat(yup.object({ id: yup.string() }))

export type OtherResidents = yup.InferType<typeof OtherResidentsSchema>
