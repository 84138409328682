import { t, ToastContext, useWebAuth } from '@walter/shared-web'
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import AuthContext from './Auth'

const SessionValidatorContext = React.createContext({})

export default SessionValidatorContext

export function SessionValidatorProvider({ children }: { children: React.ReactNode }) {
  const { pathname: currentPath } = useLocation()
  const { updateTokenStateFromLocalStorage, isSessionExpired } = useWebAuth()
  const { logout } = useContext(AuthContext)
  const { showToast } = useContext(ToastContext)

  useEffect(() => {
    if (isSessionExpired()) {
      showToast('negative', t('session-expired-please-login-again'), '', 20000)
      logout()
    }
    updateTokenStateFromLocalStorage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath])

  return <SessionValidatorContext.Provider value={{}}>{children}</SessionValidatorContext.Provider>
}
