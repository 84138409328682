import { Api, Config, isNotAuthorizedError } from '@walter/shared'
import {
  BoxCenter,
  ConfirmModal,
  DashboardWrapperInner,
  EmptyState,
  GlobalLayout,
  LastLocationContext,
  Loading,
  ToastContext,
  WebLogger,
} from '@walter/shared-web'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Menu from '../components/nav/Menu'
import Nav from '../components/nav/Nav'
import AuthContext from '../contexts/Auth'
import { ConversationsProvider } from '../contexts/Conversations'
import { CurrentUserProvider } from '../contexts/CurrentUser'
import ProjectContext, { ProjectProvider } from '../contexts/Project'
import PropertyContext, { PropertyProvider } from '../contexts/Property'
import { SessionValidatorProvider } from '../contexts/SessionValidator'
import { t } from '../utils/i18n'
import { OtherResidentAddRequestContent } from './OtherResidentAddRequestContent'

type DashboardWrapperProp = {
  children: React.ReactNode
}

export const DashboardWrapper = ({ children }: DashboardWrapperProp) => {
  const {
    push,
    location: { pathname },
  } = useHistory()

  const { showToast } = useContext(ToastContext)
  const { lastLocation, setLastLocation } = useContext(LastLocationContext)
  const { currentUser, logout, errorCurrentUserQuery, token, loadingCurrentUserQuery, refetchCurrentUserQuery } =
    useContext(AuthContext)
  const [addUserRequest] = Api.useDoUserAddRequestResidentWebMutation()

  const { data: { user: detailedResident } = {}, loading: loadingDetailedResident } =
    Api.useResidentWithAddressResidentWebQuery({
      skip:
        !currentUser ||
        currentUser.pendingUserAddRequests.length === 0 ||
        !currentUser.pendingUserAddRequests[0].requestCreatorUser?.id,
      variables: {
        where: {
          id: currentUser?.pendingUserAddRequests[0]?.requestCreatorUser?.id as string,
        },
      },
    })

  const isUserAddRequest = React.useMemo(() => {
    return currentUser?.pendingUserAddRequests?.length > 0
  }, [currentUser])

  async function handleUnauthorize() {
    await logout()
    push('/auth/login')
  }

  async function confirmAddUserRequest() {
    await addUserRequest({
      variables: { id: currentUser.pendingUserAddRequests[0].id, status: 'ADDED' },
    })
    await refetchCurrentUserQuery()
  }

  async function cancelAddUserRequest() {
    await addUserRequest({
      variables: { id: currentUser.pendingUserAddRequests[0].id, status: 'REJECTED' },
    })
    await refetchCurrentUserQuery()
  }

  React.useEffect(() => {
    if (!lastLocation) {
      setLastLocation(pathname)
    }
  }, [setLastLocation, lastLocation, pathname])

  React.useEffect(() => {
    if (isNotAuthorizedError(errorCurrentUserQuery) || !token) {
      if (token) {
        showToast('negative', t('session-expired-please-login-again'), '', 20000)
      }
      handleUnauthorize()
    }
  }, [errorCurrentUserQuery, loadingCurrentUserQuery, token, currentUser])

  if (!currentUser || loadingCurrentUserQuery) return <Loading />

  if (isUserAddRequest) {
    let requesterAddress = ''
    if (!loadingDetailedResident) {
      requesterAddress = detailedResident?.livingProperty
        ? `${detailedResident.livingProperty.building?.address?.address1}, ${detailedResident.livingProperty.building?.address?.city}, ${detailedResident.livingProperty.building?.address?.state}, ${detailedResident.livingProperty.building?.address?.country}, ${detailedResident.livingProperty.building?.address?.zip}`
        : ''

      if (!requesterAddress) {
        requesterAddress =
          detailedResident?.ownedProperties && detailedResident?.ownedProperties.length > 0
            ? `${detailedResident.ownedProperties[0].building?.address?.address1}, ${detailedResident.ownedProperties[0].building?.address?.city}, ${detailedResident.ownedProperties[0].building?.address?.state}, ${detailedResident.ownedProperties[0].building?.address?.country}, ${detailedResident.ownedProperties[0].building?.address?.zip}`
            : ''
      }
    }

    return loadingDetailedResident ? (
      <Loading />
    ) : (
      <ConfirmModal
        visible={true}
        onConfirm={confirmAddUserRequest}
        onClose={cancelAddUserRequest}
        isLoading={false}
        customDescription={' '}
        extraContent={OtherResidentAddRequestContent({
          isOwner: currentUser.pendingUserAddRequests[0].isOwner,
          project: currentUser.pendingUserAddRequests[0].targetedProject.name ?? '',
          address: `${currentUser.pendingUserAddRequests[0].targetedProject.building?.address?.address1}, ${currentUser.pendingUserAddRequests[0].targetedProject.building?.address?.city}, ${currentUser.pendingUserAddRequests[0].targetedProject.building?.address?.state}, ${currentUser.pendingUserAddRequests[0].targetedProject.building?.address?.country}, ${currentUser.pendingUserAddRequests[0].targetedProject.building?.address?.zip}`,
          appartmentNumber: `${currentUser.pendingUserAddRequests[0].targetedProperty.address?.apartmentNumber}`,
          requesterAddress,
          requesterfullName: `${currentUser.pendingUserAddRequests[0].requestCreatorUser?.firstName} ${currentUser.pendingUserAddRequests[0].requestCreatorUser?.lastName}`,
        })}
      />
    )
  }

  return (
    <SessionValidatorProvider>
      <GlobalLayout>
        <ValidDataWrapper>
          <ProjectProvider>
            <PropertyProvider>
              <CurrentUserProvider>
                <HasNoPropertiesWrapper>
                  <ConversationsProvider>
                    <Nav />
                    <ValidManagingCompanyWrapper>
                      <PropertyWrapper>
                        <Menu />
                        <DashboardWrapperInner>{children}</DashboardWrapperInner>
                      </PropertyWrapper>
                    </ValidManagingCompanyWrapper>
                  </ConversationsProvider>
                </HasNoPropertiesWrapper>
              </CurrentUserProvider>
            </PropertyProvider>
          </ProjectProvider>
        </ValidDataWrapper>
      </GlobalLayout>
    </SessionValidatorProvider>
  )
}

function ValidDataWrapper({ children }: { children: React.ReactElement }) {
  const { push } = useHistory()
  const { logout, currentUser } = useContext(AuthContext)
  const { showToast } = React.useContext(ToastContext)

  const handleUnauthorize = async () => {
    await logout()
    push('/auth/login')
  }

  const isAResident = React.useMemo(() => {
    return currentUser?.role === 'RESIDENT'
  }, [currentUser])

  React.useEffect(() => {
    if (!isAResident) {
      showToast('negative', t('user-is-not-a-resident'))
      handleUnauthorize()
    }
  }, [isAResident])

  if (!isAResident) return <Loading />

  return <>{children}</>
}

function HasNoPropertiesWrapper({ children }: { children: React.ReactNode }) {
  const { uniqProperties } = useContext(PropertyContext)
  const { logout } = useContext(AuthContext)
  const { push } = useHistory()

  if (uniqProperties.length === 0) {
    return (
      <BoxCenter>
        <EmptyState
          icon="house"
          heading={t('general:profile-no-longer-linked-to-any-units-heading')}
          description={t('general:profile-no-longer-linked-to-any-units-description')}
          primaryAction={{
            label: t('navigation:logout'),
            handleClick: async () => {
              await logout()
              push('/auth/login')
            },
          }}
        />
      </BoxCenter>
    )
  }

  return <>{children}</>
}

function ValidManagingCompanyWrapper({ children }: { children: React.ReactNode }) {
  const { currentProject } = useContext(ProjectContext)

  if (
    currentProject &&
    (!currentProject.managingCompany || currentProject.managingCompany.id === Config.transitionManagingCompanyId)
  ) {
    return (
      <BoxCenter>
        {' '}
        <EmptyState icon="close" heading={t('general:managing-company-no-longer-use-usewalter')} />
      </BoxCenter>
    )
  }

  return <>{children}</>
}

function PropertyWrapper({ children }: { children: React.ReactNode }) {
  const { loadingProperty, errorLoadingPropertyQuery } = useContext(PropertyContext)
  const { loadingProject, errorLoadingProjectQuery } = useContext(ProjectContext)

  const error = errorLoadingPropertyQuery || errorLoadingProjectQuery

  React.useEffect(() => {
    if (error) {
      WebLogger.captureError(error)
    }
  }, [error])

  if (error) {
    return (
      <BoxCenter>
        <EmptyState heading="Error" description="Couldn't load your property" />
      </BoxCenter>
    )
  }

  if (loadingProperty || loadingProject) {
    return <Loading />
  }

  return <>{children}</>
}
