import React from 'react'

export function useOutsideAlerter(ref: React.MutableRefObject<HTMLDivElement | null>, onClickCallback: () => void) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event: MouseEvent) {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      onClickCallback()
    }
  }

  React.useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
}
