import * as yup from 'yup'
import { i18n, t } from '../../utils'

export const Groupsi18nFields = {
  title: t('name-uppercase'),
  description: t('fields-segment:short-description'),
  model: t('fields-segment:model'),
  path: t('fields-segment:field'),
  comparison: t('fields-segment:comparison'),
  value: t('fields-segment:value'),
  properties: t('fields-segment:units'),
  users: t('residents-uppercase'),
}

const SegmentFieldsSchema = yup
  .object()
  .shape(
    {
      model: yup.string().when('path', {
        is: (path?: string) => !!path,
        then: yup
          .string()
          .nullable()
          .required(() => i18n.t('input:is-required', { field: Groupsi18nFields.model })),
        otherwise: yup.string().optional().nullable(),
      }),
      path: yup.string().when('model', {
        is: (model?: string) => !!model,
        then: yup
          .string()
          .nullable()
          .required(() => i18n.t('input:is-required', { field: Groupsi18nFields.path })),
        otherwise: yup.string().optional().nullable(),
      }),
      comparison: yup
        .string()
        .nullable()
        .when('model', {
          is: (model?: string) => !!model,
          then: yup
            .string()
            .nullable()
            .required(() => i18n.t('input:is-required', { field: Groupsi18nFields.comparison })),
          otherwise: yup.string().optional().nullable(),
        }),
      value: yup
        .string()
        .nullable()
        .when('model', {
          is: (model?: string) => !!model,
          then: yup
            .string()
            .nullable()
            .required(() => i18n.t('input:is-required', { field: Groupsi18nFields.value })),
          otherwise: yup.string().optional().nullable(),
        }),
    },
    [['model', 'path']],
  )
  .optional()

export const GroupsSchemaCreate = yup.object().shape(
  {
    title: yup.string().required(() => i18n.t('input:is-required', { field: Groupsi18nFields.title })),
    description: yup.string().optional().nullable(),
    segmentFields: yup.array().of(SegmentFieldsSchema).nullable(),

    properties: yup
      .array(yup.object({ id: yup.string() }))
      .optional()
      .nullable(),
    users: yup
      .array(yup.object({ id: yup.string() }))
      .optional()
      .nullable(),
  },
  [['model', 'path']],
)

export type Groups = yup.InferType<typeof GroupsSchemaCreate> & { id: string }
