import { useResidentConversations, useResidentUnreadMessagesCount } from '@walter/shared'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import ProjectContext from './Project'

type IConcersationsContext = ReturnType<typeof useResidentConversations> &
  ReturnType<typeof useResidentUnreadMessagesCount>

const ConversationsContext = React.createContext({} as IConcersationsContext)

export function ConversationsProvider({ children }: { children: React.ReactNode }) {
  const { currentProject } = useContext(ProjectContext)
  const { propertyId } = useParams<{ propertyId: string; channelUrl: string }>()

  const residentConversations = useResidentConversations({
    selectedProjectId: currentProject?.id as string,
    selectedPropertyId: propertyId,
  })

  const residentUnreadMessagesCount = useResidentUnreadMessagesCount(propertyId)

  return (
    <ConversationsContext.Provider value={{ ...residentConversations, ...residentUnreadMessagesCount }}>
      {children}
    </ConversationsContext.Provider>
  )
}

export function useConversations() {
  return React.useContext(ConversationsContext)
}
