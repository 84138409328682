import * as yup from 'yup'
import { t } from '../../utils'

export const ThirdPartySettingsHopemi18nFields = {
  importEnabled: t('third-party:import-enabled'),
  hopemCompanyId: t('primmo-company-id'),
  hopemBuildingId: t('primmo-building-id'),
  automaticallyInviteResidentCreatedFromThirdParty: t('automatically-invite-new-resident-created-from-third-party'),
}

export const ThirdPartySettingsHopemSchema = yup.object({
  importEnabled: yup.boolean(),
  config: yup.object({
    hopemCompanyId: yup.number(),
    hopemBuildingId: yup.number(),
    automaticallyInviteResidentCreatedFromThirdParty: yup.boolean(),
  }),
})

export type ThirdPartySettingsHopem = yup.InferType<typeof ThirdPartySettingsHopemSchema>

export const ThirdPartySettingsCondoManageri18nFields = {
  importEnabled: t('import-enabled'),
  condoManagerId: t('add-your-condo-manager-id-to-automatically-integrate-with-walter'),
  automaticallyInviteResidentCreatedFromThirdParty: t('automatically-invite-new-resident-created-from-third-party'),
}

export const ThirdPartySettingsCondoManagerSchema = yup.object({
  importEnabled: yup.boolean(),
  config: yup.object({
    condoManagerId: yup.string(),
    automaticallyInviteResidentCreatedFromThirdParty: yup.boolean(),
  }),
})

export type ThirdPartySettingsCondoManager = yup.InferType<typeof ThirdPartySettingsCondoManagerSchema>
