import * as yup from 'yup'
import { t } from '../../utils'

export const ChatSettingsi18nFields = {
  sendOnPressEnter: t('send-message-when-pressing-enter'),
}

export const ChatSettingsSchema = yup.object({
  sendOnPressEnter: yup.boolean(),
})

export type ChatSettings = yup.InferType<typeof ChatSettingsSchema>
