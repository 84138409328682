import * as yup from 'yup'
import { i18n, t } from '../../utils'

export const Rulei18nFields = {
  selectedProject: t('selected-project'),
  title: t('form-label-title'),
  description: t('description'),
  attachments: t('attachments'),
}

export const ruleSchema = yup.object({
  projects: yup
    .array()
    .required(i18n.t('input:is-required', { field: Rulei18nFields.selectedProject }))
    .min(1, i18n.t('input:is-required', { field: Rulei18nFields.selectedProject })),
  title: yup.string().required(i18n.t('input:is-required', { field: Rulei18nFields.title })),
  description: yup.string().optional(),
  attachment: yup.mixed().optional(),
})

export type RuleFormItem = yup.InferType<typeof ruleSchema>
