import { yupResolver } from '@hookform/resolvers/yup'
import { Colors, Config, Spacing } from '@walter/shared'
import * as R from 'ramda'
import React, { ReactNode, useState } from 'react'
import {
  Controller,
  FieldErrorsImpl,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
  SubmitHandler,
  DeepPartial,
} from 'react-hook-form'
import 'react-quill/dist/quill.snow.css'
import { GroupBase, InputActionMeta } from 'react-select'
import styled, { css } from 'styled-components'
import { AnyObjectSchema } from 'yup'
import { ToastContext } from '../../contexts'
import { WebLogger, t } from '../../utils'
import { AnnotatedSection } from '../AnnotatedSection'
import { ButtonGroup } from '../ButtonGroup'
import { Card } from '../Card'
import { CardSection } from '../Card/CardSection'
import { ColorPicker } from '../ColorPicker'
import { Fieldset } from '../Fieldset'
import { FieldsetItem } from '../Fieldset/FieldsetItem'
import { FormSection } from '../FormDataView'
import { Button, ButtonWithModal } from './Button'
import { Checkbox } from './Checkbox'
import { CoverImageUpload } from './CoverImageUpload'
import { DatePicker, DatePickerPosition } from './DatePicker'
import { Input } from './Input'
import { RichTextarea } from './RichTextarea'
import { Select } from './Select'
import { FileUploadMulti, FileUploadSingle } from './UploadComponent/file'
import { ImageUploadMulti, ImageUploadSingle } from './UploadComponent/image'

const Padded = styled.div`
  padding: ${Spacing.large};
`

const CheckBoxRow = styled.div<{ height: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  ${(props) =>
    css`
      height: ${props.height}px;
    `};
  padding-left: 12px;
`

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never }
type XOR<T, U> = T | U extends Record<string, unknown> ? (Without<T, U> & U) | (Without<U, T> & T) : T | U
type OptionValue = XOR<Record<string, any>, string | number>
type Option = { value: OptionValue; label: string }
type FormError = FieldErrorsImpl<{
  [x: string]: any
}>

export function Form<DataSchema extends Record<string, any>, Schema extends AnyObjectSchema>({
  schema,
  onSubmit,
  children,
  defaultValues,
  doNotWrapFieldsInFieldSet: doNotWrapFieldsInFieldSet = false,
  autoComplete = 'off',
}: {
  schema: Schema
  onSubmit: SubmitHandler<DataSchema>
  children: any
  defaultValues?: DeepPartial<DataSchema>
  doNotWrapFieldsInFieldSet?: boolean
  autoComplete?: string
}) {
  const { showToast } = React.useContext(ToastContext)
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })

  function handleError(err: FormError) {
    if (!Config.isProduction) {
      WebLogger.captureError(err)
    }
    const errors = Object.keys(err)
    if (
      errors.some((key) =>
        Object.keys(schema.fields).some((field) => {
          // Nested fields are in the format of `field.nestedField`
          return key.includes(field)
        }),
      )
    ) {
      showToast('negative', t('please-fill-all-the-required-values'))
      return
    }
    showToast('negative', t('unexpected-error-occurred'))
  }

  return doNotWrapFieldsInFieldSet ? (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit, handleError)} autoComplete={autoComplete}>
        {children}
      </form>
    </FormProvider>
  ) : (
    <Padded data-test-id="Form_Container">
      <FormProvider {...methods}>
        <form data-test-id="Form" onSubmit={methods.handleSubmit(onSubmit, handleError)}>
          <Fieldset>{children}</Fieldset>
        </form>
      </FormProvider>
    </Padded>
  )
}

type InputType =
  | 'tel'
  | 'date'
  | 'file'
  | 'color'
  | 'email'
  | 'image'
  | 'radio'
  | 'reset'
  | 'hidden'
  | 'number'
  | 'search'
  | 'submit'
  | 'password'
  | 'text'
  | 'time'
  | 'textarea'

Form.Input = function FormInput<Model extends Record<string, any>>({
  dataTestId,
  type = 'text',
  name,
  label,
  width = '1-1',
  disabled = false,
  ...rest
}: {
  dataTestId?: string
  type?: InputType
  name: keyof Model
  label: string
  width?: string
  disabled?: boolean
  [k: string]: any
}) {
  const {
    register,
    formState: { isSubmitting, errors },
  } = useFormContext()

  return (
    <FieldsetItem dataTestId={dataTestId} width={width}>
      <Input
        dataTestId={dataTestId}
        type={type}
        disabled={isSubmitting || disabled}
        errors={errors}
        label={label}
        {...rest}
        {...register(name as string)}
      />
    </FieldsetItem>
  )
}

Form.InputWithTransformation = function InputPostTransform({
  setValueTo,
  beforeUpdatingFormData,
  name,
  type,
  label,
  width,
  disabled,
}: {
  setValueTo: (value: string | number) => string
  beforeUpdatingFormData: (value: string) => string | number
  name: string
  label?: string
  type?: InputType
  width?: string
  disabled?: boolean
}) {
  const {
    formState: { isSubmitting, errors },
    control,
  } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FieldsetItem width={width}>
          <Input
            type={type}
            disabled={isSubmitting || disabled}
            errors={errors}
            label={label}
            name={name}
            onChange={(e) => {
              onChange(beforeUpdatingFormData(e.target.value))
            }}
            value={setValueTo(value)}
          />
        </FieldsetItem>
      )}
    />
  )
}

Form.TextArea = function FormTextArea<Model extends Record<string, any>>({
  dataTestId,
  name,
  label,
  width = '1-1',
  ...rest
}: {
  dataTestId?: string
  name: keyof Model
  label: string
  width?: string
  [k: string]: any
}) {
  const {
    register,
    formState: { isSubmitting, errors },
  } = useFormContext()

  return (
    <FieldsetItem dataTestId={dataTestId} width={width}>
      <Input
        dataTestId={dataTestId}
        type="textarea"
        disabled={isSubmitting}
        errors={errors}
        label={label}
        {...rest}
        {...register(name as string)}
      />
    </FieldsetItem>
  )
}

Form.RichTextarea = function FormRichTextArea<Model extends Record<string, any>>({
  dataTestId,
  name,
  placeholder,
  disabled,
}: {
  dataTestId?: string
  name: keyof Model
  placeholder?: string

  disabled?: boolean
}) {
  return (
    <FieldsetItem dataTestId={dataTestId}>
      <RichTextarea placeholder={placeholder} name={name as string} disabled={disabled} />
    </FieldsetItem>
  )
}

Form.FileUpload = function FileUpload<Model extends Record<string, any>>({
  dataTestId,
  name,
  label,
  allowMultiple = false,
  width = '1-1',
  disabled = false,
}: {
  dataTestId?: string
  name: keyof Model
  label: string
  allowMultiple?: boolean
  width?: string
  disabled?: boolean
}) {
  return (
    <FieldsetItem dataTestId={dataTestId} width={width}>
      {allowMultiple ? (
        <FileUploadMulti dataTestId={dataTestId} name={name as string} label={label} disabled={disabled} />
      ) : (
        <FileUploadSingle dataTestId={dataTestId} name={name as string} label={label} disabled={disabled} />
      )}
    </FieldsetItem>
  )
}

Form.ImageUpload = function ImageUpload<Model extends Record<string, any>>({
  dataTestId,
  name,
  label,
  allowMultiple = false,
  width = '1-1',
  disabled = false,
  hint,
}: {
  dataTestId?: string
  name: keyof Model
  label: string
  allowMultiple?: boolean
  width?: string
  disabled?: boolean
  hint?: string
}) {
  return (
    <FieldsetItem dataTestId={dataTestId} width={width}>
      {allowMultiple ? (
        <ImageUploadMulti dataTestId={dataTestId} name={name as string} label={label} disabled={disabled} hint={hint} />
      ) : (
        <ImageUploadSingle
          dataTestId={dataTestId}
          name={name as string}
          label={label}
          disabled={disabled}
          hint={hint}
        />
      )}
    </FieldsetItem>
  )
}

Form.ImageGalleryWithUpload = function ImageGalleryWithUpload<Model extends Record<string, any>>({
  name,
  label,
  disabled,
}: {
  name: keyof Model
  label: string
  disabled: boolean
}) {
  const { register } = useFormContext()
  return (
    <FieldsetItem dataTestId="ImageGallery">
      <CoverImageUpload label={label} {...register(name as string)} disabled={disabled} />
    </FieldsetItem>
  )
}

Form.CheckBox = function FormCheckBox<Model extends Record<string, any>>({
  dataTestId,
  name,
  label,
  disabled,
  ...rest
}: {
  dataTestId?: string
  name: keyof Model
  label: string
  disabled?: boolean
  [k: string]: any
}) {
  const {
    formState: { isSubmitting, errors },
  } = useFormContext()
  const errorMessage = errors[name as string]?.message as unknown as string

  return (
    <Checkbox
      dataTestId={dataTestId}
      name={name as string}
      label={label}
      disabled={isSubmitting || disabled}
      errorMessage={errorMessage}
      {...rest}
    />
  )
}

Form.CheckBoxFullWidth = function FormCheckBox<Model extends Record<string, any>>({
  dataTestId,
  name,
  label,
  disabled,
  height,
  ...rest
}: {
  dataTestId?: string
  name: keyof Model
  label: string
  disabled?: boolean
  height?: string | number
  [k: string]: any
}) {
  const {
    formState: { isSubmitting, errors },
  } = useFormContext()
  const errorMessage = errors[name as string]?.message as unknown as string

  return (
    <CheckBoxRow data-test-id="CheckBox_Row" height={height ? height.toString() : '30'}>
      <Checkbox
        dataTestId={dataTestId}
        name={name as string}
        label={label}
        disabled={isSubmitting || disabled}
        errorMessage={errorMessage}
        {...rest}
      />
    </CheckBoxRow>
  )
}

Form.Button = function FormButton({
  text,
  type,
  customDisabled,
  ...rest
}: {
  text: string
  type: 'button' | 'submit' | 'reset'
  customDisabled?: boolean
  [k: string]: any
}) {
  const {
    formState: { isSubmitting },
  } = useFormContext()
  return (
    <Button type={type} text={text} disabled={customDisabled !== undefined ? customDisabled : isSubmitting} {...rest} />
  )
}

Form.ButtonWithModal = function FormButton({
  dataTestId,
  text,
  type,
  delegateClick,
  ...rest
}: {
  dataTestId?: string
  text: string
  type: 'button' | 'submit' | 'reset'
  delegateClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  [k: string]: any
}) {
  const {
    formState: { isSubmitting },
  } = useFormContext()
  return (
    <ButtonWithModal
      dataTestId={dataTestId}
      type={type}
      text={text}
      disabled={isSubmitting}
      delegateClick={delegateClick}
      {...rest}
    />
  )
}

Form.Select = function FormSelect<Model, OptionValue>({
  dataTestId,
  name,
  label,
  options,
  isMulti = false,
  isClearable = false,
  value,
  width,
  hint,
  additionalOnChange,
  disabled = false,
  isSearchable = true,
  onMenuScrollToBottom,
  isLoading,
  inputValue,
  onInputChange,
  multiSelectColor,
  noOptionsText,
}: {
  dataTestId?: string
  name: keyof Model
  label?: string
  options: readonly (Option | GroupBase<Option>)[]
  isMulti?: boolean
  isClearable?: boolean
  value?: Option | (Option | GroupBase<Option>)[]
  width?: string
  additionalOnChange?: (selected: { value: OptionValue }) => void
  hint?: string
  disabled?: boolean
  isSearchable?: boolean
  onMenuScrollToBottom?: () => void | Promise<void>
  isLoading?: boolean
  inputValue?: string
  onInputChange?: (value: string, actionMeta: InputActionMeta) => void
  multiSelectColor?: keyof typeof Colors
  noOptionsText?: string
}) {
  const {
    formState: { isSubmitting, errors },
  } = useFormContext()

  let errorMessage: string = ''
  const firstError = errors[name as string]
  if (firstError) {
    if ('id' in firstError) {
      errorMessage = firstError.id?.message as string
    } else if ('message' in firstError) {
      errorMessage = firstError.message as string
    }
  }

  return (
    <FieldsetItem dataTestId={dataTestId} width={width}>
      <Select
        dataTestId={dataTestId}
        name={name as string}
        label={label}
        isMulti={isMulti}
        isClearable={isClearable}
        disabled={isSubmitting || disabled}
        options={options}
        defaultValue={value}
        isSearchable={isSearchable}
        errorMessage={errorMessage}
        hint={hint}
        additionalOnChange={additionalOnChange}
        onMenuScrollToBottom={onMenuScrollToBottom}
        isLoading={isLoading}
        inputValue={inputValue}
        onInputChange={onInputChange}
        multiSelectColor={multiSelectColor}
        noOptionsText={noOptionsText}
      />
    </FieldsetItem>
  )
}

Form.selectfunctions = {
  convertValue<Obj, Value extends keyof Obj, Label extends keyof Obj>(
    value: Obj,
    optionValueName: Value,
    optionLabelName: Label,
  ) {
    return { value: value[optionValueName], label: value[optionLabelName] }
  },
  byFormValue: R.curry(function filterValue(valuesInFormData: { id?: string }[], availableValue: { id: string }) {
    return !!valuesInFormData?.find((value) => value.id === availableValue.id)
  }),
}

Form.ColorPicker = function FormInput({
  dataTestId,
  label,
  width = '1-1',
  onChange,
  value,
  disabled,
}: {
  dataTestId?: string
  type?: InputType
  label: string
  onChange: (colorHex: string) => void
  width?: string
  value?: string
  disabled?: boolean
}) {
  return (
    <FieldsetItem dataTestId={dataTestId} width={width}>
      <ColorPicker disabled={disabled} label={label} initialColor={value} onChange={onChange} />
    </FieldsetItem>
  )
}

Form.useSafeFunctions = function SafeFunctions<Obj>() {
  const { watch, getValues, setValue } = useFormContext()
  return {
    watch<Key extends keyof Obj>(value: Key) {
      return watch(value as string)
    },
    getValues<Key extends keyof Obj>(value: Key): Obj[Key] {
      return getValues(value as string)
    },
    setValue<Key extends keyof Obj>(name: Key, value: any) {
      return setValue(name as string, value)
    },
  }
}

Form.DateTime = function DateTime<Model extends Record<string, any>>({
  dataTestId,
  name,
  label,
  value,
  width = '1-1',
  withTime = false,
  isClearable = false,
  position = 'top',
  minDate,
  disabled = false,
  additionalOnChange,
}: {
  dataTestId?: string
  name: keyof Model
  label: string
  value?: Date
  width?: string
  withTime?: boolean
  isClearable?: boolean
  position?: DatePickerPosition
  minDate?: Date
  disabled?: boolean
  additionalOnChange?: (date?: Date) => void
}) {
  const {
    formState: { errors, isSubmitting },
    setValue,
    control,
  } = useFormContext()

  const handleChange = (date?: Date) => {
    setValue(name.toString(), value, { shouldDirty: true }) // Serves no non-redundant purpose except to dirty
    additionalOnChange && additionalOnChange(date)
  }

  return (
    <Controller
      name={name as string}
      control={control}
      render={({ field: { onChange } }) => (
        <FieldsetItem dataTestId={dataTestId} width={width}>
          <DatePicker
            dataTestId={dataTestId}
            value={value ?? null}
            label={label}
            name={name as string}
            additionalOnChange={(date) => onChange(handleChange(date))}
            withTime={withTime}
            errors={errors}
            isClearable={isClearable}
            position={position}
            minDate={minDate}
            disabled={isSubmitting || disabled}
          />
        </FieldsetItem>
      )}
    />
  )
}

type DateTimeField<Model extends Record<string, any>> = {
  name: keyof Model
  label: string
  value?: string
  width?: string
}
Form.StartEndDateTime = function StartEndDateTime<Model extends Record<string, any>>({
  dataTestId,
  startField,
  endField,
  withTime,
  disabled = false,
}: {
  dataTestId?: string
  startField: DateTimeField<Model>
  endField: DateTimeField<Model>
  withTime: boolean
  disabled?: boolean
}) {
  const { getValues, setValue } = useFormContext()
  const minDate = useWatch({ name: startField.name as string })

  function onStartGreaterThanEnd(date?: Date) {
    if (date && date > new Date(getValues(endField.name as string))) {
      setValue(endField.name as string, undefined)
    }
  }

  return (
    <>
      <Form.DateTime
        dataTestId={`${dataTestId}_StartDate`}
        name={startField.name as string}
        label={startField.label}
        withTime={withTime}
        disabled={disabled}
        additionalOnChange={onStartGreaterThanEnd}
      />
      <Form.DateTime
        dataTestId={`${dataTestId}_EndDate`}
        name={endField.name}
        label={endField.label}
        withTime={withTime}
        minDate={typeof minDate === 'string' ? new Date(minDate) : minDate}
        disabled={disabled}
      />
    </>
  )
}

Form.CreateActions = function CreateActions({
  close,
  createTx = 'create',
  dataTestId = '',
}: {
  close?: (() => void) | (() => Promise<void>)
  createTx?: string
  dataTestId?: string
}) {
  const {
    formState: { isSubmitting, isDirty },
  } = useFormContext()
  const dataTestIdValue = dataTestId ? dataTestId + '_ActionsGroup' : 'ActionsGroup'
  return (
    <FormSection data-test-id={dataTestIdValue} style={{ padding: `${Spacing.large} ${Spacing.xxSmall}` }}>
      <ButtonGroup>
        {close && (
          <Form.Button type="button" text={t('cancel')} onClick={close} data-test-id={dataTestId + '_Cancel_Button'} />
        )}
        <Form.Button
          type="submit"
          text={t(createTx)}
          theme="primary"
          customDisabled={!isDirty || isSubmitting}
          data-test-id={dataTestId + '_Create_Button'}
        />
      </ButtonGroup>
    </FormSection>
  )
}

Form.UpdateActions = function UpdateActions({
  close,
  handleDelete,
  enableSavingWhen,
  dataTestId,
  customDisabled,
  deleteDisabled,
}: {
  close?: (() => void) | (() => Promise<void>)
  handleDelete?: any
  enableSavingWhen?: boolean
  dataTestId?: string
  customDisabled?: boolean
  deleteDisabled?: boolean
}) {
  const {
    formState: { isSubmitting, isDirty },
  } = useFormContext()
  const dataTestIdValue = dataTestId ? dataTestId + '_ActionsGroup' : 'ActionsGroup'

  return (
    <FieldsetItem dataTestId={dataTestIdValue}>
      <ButtonGroup>
        {close && (
          <Form.Button type="button" text={t('cancel')} onClick={close} data-test-id={dataTestId + '_Cancel_Button'} />
        )}
        {handleDelete && (
          <Form.ButtonWithModal
            type="button"
            text={t('delete')}
            delegateClick={handleDelete}
            dataTestId={dataTestId + '_ConfirmDelete_Button'}
            data-test-id={dataTestId + '_Delete_Button'}
            disabled={customDisabled || deleteDisabled}
          />
        )}
        <Form.Button
          type="submit"
          text={t('save')}
          theme="primary"
          customDisabled={enableSavingWhen ? false : !isDirty || isSubmitting}
          data-test-id={dataTestId + '_Save_Button'}
          disabled={customDisabled}
        />
      </ButtonGroup>
    </FieldsetItem>
  )
}

Form.SaveAction = function SaveAction() {
  const {
    formState: { isSubmitting, isDirty },
  } = useFormContext()
  return (
    <FieldsetItem dataTestId="SaveAction">
      <ButtonGroup>
        <Form.Button
          data-test-id="Save_Button"
          type="submit"
          text={t('save')}
          theme="primary"
          customDisabled={!isDirty || isSubmitting}
        />
      </ButtonGroup>
    </FieldsetItem>
  )
}

Form.Section = function section({
  dataTestId,
  title,
  description = '',
  isVisible = true,
  children,
}: {
  dataTestId?: string
  title: string
  description?: string
  isVisible?: boolean
  children: ReactNode
}) {
  return isVisible ? (
    <AnnotatedSection dataTestId={dataTestId} key={title} heading={title} description={description}>
      <Card dataTestId={dataTestId}>
        <CardSection dataTestId={dataTestId}>
          <Fieldset>{children}</Fieldset>
        </CardSection>
      </Card>
    </AnnotatedSection>
  ) : null
}

export const convertToOptionValue = (
  objectsToLook: { id: string }[],
  subValueInEditedObject: { id: string }[],
  formattingFunctionForLabel: (obj: Record<string, any>) => string,
) => {
  return objectsToLook
    .filter((object) => subValueInEditedObject.filter((subObject) => subObject.id === object.id).length)
    .map((object) => ({ value: { id: object.id }, label: formattingFunctionForLabel(object) }))
}
//Debug component
Form.Pre = function Pre() {
  const [showData, setShowData] = useState(false)
  const [showError, setShowError] = useState(false)
  const {
    watch,
    formState: { errors },
  } = useFormContext()

  if (process.env.NODE_ENV !== 'development') {
    return null
  }

  return (
    <>
      <Form.Button
        type="button"
        text={showData ? 'hide data' : 'show data'}
        onClick={() => setShowData(!showData)}
        style={{ marginRight: '10px' }}
      />
      <Form.Button
        type="button"
        text={showError ? 'hide errors' : 'show errors'}
        onClick={() => setShowError(!showError)}
      />
      <pre>
        {showData && JSON.stringify(watch(), null, 2)}
        {showError && JSON.stringify(errors, null, 2)}
      </pre>
    </>
  )
}
