import React, { useCallback, useState } from 'react'
import { Colors, Spacing } from '@walter/shared'
import { rgba, stripUnit } from 'polished'
import ImageViewer from 'react-simple-image-viewer'
import styled from 'styled-components'
import { borderRadius, cover } from '../../styles/global'
import { Label } from '../Label'

const Container = styled.div`
  display: block;
`

const StyledLabel = styled(Label)`
  margin-bottom: ${Spacing.small};
`

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${`${(stripUnit(Spacing.tiny) as number) * 1.5}px`};
`

const Item = styled.div`
  position: static;
  flex-basis: 25%;
  padding: ${`${(stripUnit(Spacing.tiny) as number) * 1.5}px`};
`

const PhotoWrap = styled.a`
  display: block;
  position: relative;
  border-radius: ${borderRadius};
  overflow: hidden;
  padding-bottom: 80%;
  min-width: 48px;

  &:after {
    content: '';
    box-shadow: inset 0 0 0 1px ${rgba(Colors.black, 0.15)};
    border-radius: ${borderRadius};
    pointer-events: none;
    ${cover('absolute')};
  }
`

const Photo = styled.img`
  object-fit: cover;
  ${cover('absolute')};
`

type PhotoGalleryProps = {
  photos: string[]
  label?: string
}

export const PhotoGallery = ({ label, photos }: PhotoGalleryProps) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  return (
    <Container data-test-id="PhotoGallery_Container">
      {label && <StyledLabel>{label}</StyledLabel>}
      <Inner>
        {photos.length > 0
          ? photos.map((photo, i) => (
              <Item data-test-id="PhotoGallery_Item" key={i}>
                <PhotoWrap onClick={() => openImageViewer(i)}>
                  <Photo data-test-id="PhotoGallery_Img" src={photo} alt="" />
                </PhotoWrap>
              </Item>
            ))
          : 'No image(s)'}
        {isViewerOpen && (
          <ImageViewer
            src={photos}
            currentIndex={currentImage}
            backgroundStyle={{ zIndex: 999 }}
            onClose={closeImageViewer}
          />
        )}
      </Inner>
    </Container>
  )
}
