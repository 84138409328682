import { Spacing } from '@walter/shared'
import React, { InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import { t } from '../../utils'
import { SearchInput } from '../SearchInput'
import { FilterOptions } from './FilterOptions'
import { FilterResultsList } from './FilterResultsList'
import { FilterTagList } from './FilterTagList'
import { FilterOption, FilterSegment, SelectedFilter } from './types'

const Container = styled.div`
  margin-bottom: ${Spacing.large};
`

const Inner = styled.div`
  display: flex;
  align-items: flex-end;
  justfiy-content: flex-end;
`

const SearchWrap = styled.div`
  flex: 1;
`

const FilterTagWrap = styled.div`
  width: 200px;
`

const WAITING_TIME = 300

type FilterBarProps = {
  dataTestId?: string
  numberOfFoundItems: number
  handleFiltersChange: (filters: SelectedFilter[]) => void
  filterOptions?: FilterOption[]
  onTextChange?: (text: string) => void
  heading?: string
  searchInputProps?: InputHTMLAttributes<HTMLInputElement>
  loadingSearch?: boolean
  segments?: FilterSegment[]
  tagsListTitle?: string
}

export function FilterBar({
  dataTestId,
  numberOfFoundItems,
  filterOptions = [],
  heading,
  onTextChange,
  handleFiltersChange,
  searchInputProps,
  loadingSearch,
  segments,
  tagsListTitle,
}: FilterBarProps) {
  const [filters, setFilters] = React.useState<SelectedFilter[]>([])
  const [selectedSegment, setSelectedSegment] = React.useState('')

  const timer = React.useRef<number | null>(null)

  React.useEffect(() => {
    return () => {
      timer.current = null
    }
  }, [])

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement

    if (e.key === 'Enter') {
      setSelectedSegment('customSearch')
      setFilters([
        ...filters,
        {
          label: `${t('search')}: ${target.value}`,
          type: 'search',
          chosenValue: target.value,
        },
      ])
    }
  }

  function handleOnSearchInputTextChange(text: any) {
    if (timer.current) {
      clearTimeout(timer.current)
    }

    if (onTextChange) {
      timer.current = window.setTimeout(onTextChange, WAITING_TIME, text)
    }
    setSelectedSegment('customSearch')
    setFilters([
      ...filters,
      {
        label: `${t('search')}: ${text}`,
        type: 'search',
        chosenValue: text,
      },
    ])
  }

  function handleApplyFilter(pathOption: FilterOption | undefined, value: string | number | undefined) {
    if (!pathOption) {
      return
    }
    const newFiltersList: SelectedFilter[] = [
      ...filters,
      {
        ...pathOption,
        label: getTagLabel(pathOption, value),
        chosenValue: value,
      },
    ]

    setFilters(newFiltersList)
    setSelectedSegment('customSearch')
  }

  React.useEffect(() => {
    if (handleFiltersChange) {
      // All
      if (!selectedSegment) {
        handleFiltersChange([])
      }
      // Custom search
      else if (selectedSegment === 'customSearch') {
        handleFiltersChange(filters)
      }
      // Selected segments
      else if (selectedSegment) {
        handleFiltersChange([{ chosenSegment: selectedSegment }])
      }
    }
  }, [handleFiltersChange, filters, selectedSegment])

  function handleRemoveTag(filter: SelectedFilter) {
    const newFiltersList = filters.filter((f) => f.label !== filter.label)

    setFilters(newFiltersList)

    if (handleFiltersChange) {
      handleFiltersChange(newFiltersList)
    }
  }

  const groupFilterTags = React.useMemo(() => {
    if (!segments) {
      return []
    }

    const tags = [
      { label: t('all'), value: '0', isActive: !selectedSegment, handleClick: () => setSelectedSegment('') },
    ]
    if (segments?.length > 0) {
      tags.push(
        ...segments?.map(({ title, id }, index) => ({
          label: title,
          value: (index + 1).toString(), // Used to access the handleClick by index in FilterTagList. +1 to skip past the "All" option
          handleClick: () => setSelectedSegment(id), // ID here is a long hash string, not a simple number
          isActive: selectedSegment === id,
        })),
      )
    }

    if (tags.length > 0) {
      tags.push({
        label: t('custom-search'),
        value: tags.length.toString(),
        isActive: selectedSegment === 'customSearch',
        handleClick: () => setSelectedSegment('customSearch'),
      })
    }

    return tags
  }, [segments, filters, selectedSegment])

  return (
    <Container data-test-id={`${dataTestId}_FilterBar_Container`} data-cy="filter-bar-input">
      <Inner>
        {filterOptions.length > 0 && (
          <FilterOptions
            options={filterOptions}
            heading={heading}
            onAddNewFilter={handleApplyFilter}
            dataTestId={dataTestId}
          />
        )}
        {segments && segments?.length > 0 && (
          <FilterTagWrap>
            <FilterTagList
              dataTestId={dataTestId + '_FilterTagList'}
              tags={groupFilterTags}
              tagsListTitle={tagsListTitle}
            />
          </FilterTagWrap>
        )}

        <SearchWrap>
          <SearchInput
            {...searchInputProps}
            value={searchInputProps?.value?.toString()}
            data-test-id={dataTestId + '_Search_Input'}
            onTextChange={handleOnSearchInputTextChange}
            onKeyDown={handleKeyDown}
            resetOnEnter
          />
        </SearchWrap>
      </Inner>

      {filters.length > 0 && selectedSegment && (
        <FilterResultsList
          results={filters}
          numberOfFoundItems={numberOfFoundItems}
          onRemoveResult={handleRemoveTag}
          loadingSearchResults={!!loadingSearch}
        />
      )}
    </Container>
  )
}

function getTagLabel(filterOption: FilterOption, valueDropdown: SelectedFilter['chosenValue']) {
  if (filterOption.type === 'boolean') {
    if (String(valueDropdown) === 'true') {
      return filterOption.trueLabel || 'true'
    }

    return filterOption.falseLabel || 'false'
  }

  if ((filterOption.type === 'dropdown' || filterOption.type === 'select') && filterOption.dropdownOptions) {
    return filterOption.dropdownOptions.find((option) => option.value === valueDropdown)?.label || 'N/A'
  }

  return 'N/A'
}
