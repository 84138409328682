import React from 'react'
import styled, { css } from 'styled-components'
import { Colors, Spacing, Types } from '@walter/shared'
import { rgba } from 'polished'
import { rotate } from '../../styles/animations'
import { square } from '../../styles/global'
import { Heading } from '../Heading'
import { Icon } from '../Icon'
import { ButtonGroup } from '../ButtonGroup'
import { Button } from '../Button'
import { t } from '../../utils'

const Container = styled.div`
  text-align: center;
  max-width: 340px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const IconWrap = styled.div<{ isLoading: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${Spacing.large};
  background-color: ${rgba(Colors.greyLight, 0.2)};
  color: ${Colors.greyLight};
  ${square('64px')};

  ${(props) =>
    props.isLoading &&
    css`
      animation: ${rotate} 1s linear infinite;
    `}
`

const StyledHeading = styled(Heading)`
  width: 100%;
  margin-bottom: ${Spacing.small};
  color: ${Colors.greyLight};
`

const Description = styled.p`
  color: ${Colors.greyLight};
`

export const LOADING_QUOTES = [
  'loading-quote-1',
  'loading-quote-2',
  'loading-quote-3',
  'loading-quote-4',
  'loading-quote-5',
]

const getRandomLoadingText = () => {
  return t(LOADING_QUOTES[Math.round(Math.random() * (LOADING_QUOTES.length - 1))])
}

type EmptyStateProps = {
  heading?: string
  icon?: Types.IconName
  isLoading?: boolean
  description?: string
  testID?: string
  primaryAction?: {
    label: string
    handleClick: () => Promise<void> | void | null
  }
  secondaryAction?: {
    label: string
    handleClick: () => void | null
  }
}

export const EmptyState = ({
  icon,
  heading,
  isLoading,
  description,
  primaryAction,
  secondaryAction,
  testID,
}: EmptyStateProps) => {
  const [currentDescription, setCurrentDescription] = React.useState(description)

  React.useEffect(() => {
    let randomTextInterval: ReturnType<typeof setInterval> | null = null

    if (isLoading && !description) {
      randomTextInterval = setInterval(() => {
        setCurrentDescription(getRandomLoadingText())
      }, 1500)
    }

    if (randomTextInterval && (!isLoading || description)) {
      clearInterval(randomTextInterval)
    }

    return () => {
      if (randomTextInterval) {
        clearInterval(randomTextInterval)
      }
    }
  }, [isLoading, description])

  const finalIcon = isLoading ? 'loading' : icon

  return (
    <Container data-testid={testID ?? ''} data-cy={testID ? testID : null}>
      {!!finalIcon && (
        <IconWrap isLoading={!!isLoading}>
          <Icon icon={finalIcon} />
        </IconWrap>
      )}
      <StyledHeading size={2}>{heading}</StyledHeading>
      {(currentDescription || description) && (
        <Description aria-label={'description'} data-testid="description">
          {description || currentDescription}
        </Description>
      )}
      {(primaryAction || secondaryAction) && (
        <ButtonGroup>
          {primaryAction && (
            <Button disabled={isLoading} theme="primary" onClick={primaryAction.handleClick}>
              {primaryAction.label}
            </Button>
          )}
          {secondaryAction && <Button onClick={secondaryAction.handleClick}>{secondaryAction.label}</Button>}
        </ButtonGroup>
      )}
    </Container>
  )
}
