import * as yup from 'yup'
import { i18n, t } from '../../utils'
import { fieldIsTooLong } from './common'

const fieldIsLongerThan10 = fieldIsTooLong(10)

export const Remotei18nFields = {
  number: t('number'),
  unit: t('unit'),
  owners: t('residents-uppercase'),
}

export const remoteSchema = yup.object({
  number: yup
    .string()
    .required(() => i18n.t('input:is-required', { field: Remotei18nFields.number }))
    .max(10, fieldIsLongerThan10(Remotei18nFields.number)),
  property: yup.object({
    id: yup.string().optional(),
  }),
  owners: yup.array(yup.object({ id: yup.string() })).optional(),
})

export type RemoteManagerWeb = yup.InferType<typeof remoteSchema> & { id: string }
