import * as yup from 'yup'
import { i18n, t } from '../../utils'
import { fieldIsTooLong } from './common'

const fieldIsLongerThan10 = fieldIsTooLong(10)

export const Parkingi18nFields = {
  number: t('fields-parking:number'),
  cadastreNumber: t('fields-parking:cadastre-number'),
  proportionateShare: t('fields-parking:proportion-share'),
  owners: t('residents-uppercase'),
  properties: t('fields-parking:units'),
}

export const parkingSchemaCreate = yup.object({
  number: yup
    .string()
    .required(() => i18n.t('input:is-required', { field: Parkingi18nFields.number }))
    .max(10, fieldIsLongerThan10(Parkingi18nFields.number)),
  cadastreNumber: yup
    .string()
    .max(10, fieldIsLongerThan10(t('fields-parking:cadastre-number')))
    .optional()
    .nullable(),
  proportionateShare: yup.number().max(100).min(0).default(0),
  owners: yup
    .array(
      yup.object({
        id: yup.string().required(),
        firstName: yup.string().optional(),
        lastName: yup.string().optional(),
      }),
    )
    .required(i18n.t('input:is-required', { field: Parkingi18nFields.owners })),
  properties: yup
    .array(
      yup.object({
        id: yup.string().required(),
        address: yup
          .object({
            id: yup.string(),
            apartmentNumber: yup.string().optional(),
          })
          .nullable()
          .optional(),
      }),
    )
    .required(i18n.t('input:is-required', { field: Parkingi18nFields.properties })),
})

export type ParkingItem = yup.InferType<typeof parkingSchemaCreate> & { id: string }
export const parkingSchemaUpdate = parkingSchemaCreate.concat(yup.object({ id: yup.string().required() }))
