import * as yup from 'yup'
import { i18n, t } from '../../utils'
import { fieldIsTooLong } from './common'

export const ManagingCompanyUseri18nFields = {
  avatar: t('avatar'),
  firstName: t('first-name'),
  lastName: t('last-name'),
  title: t('title'),
  phone: t('phone-number'),
  officePhone: t('office-phone-number'),
  preferedLanguage: t('preferred-language'),
  email: t('email'),
  password: t('password'),
  managingCompanyRole: t('role'),
}

export const managingCompanyUserSchema = yup.object().shape(
  {
    avatar: yup.mixed().optional().nullable(),
    firstName: yup
      .string()
      .max(200, fieldIsTooLong(200, ManagingCompanyUseri18nFields.firstName))
      .optional()
      .nullable(),
    lastName: yup.string().max(200, fieldIsTooLong(200, ManagingCompanyUseri18nFields.lastName)).optional().nullable(),
    title: yup.string().max(200, fieldIsTooLong(200, ManagingCompanyUseri18nFields.title)).optional().nullable(),
    phone: yup.object({
      number: yup
        .string()
        .test(
          'phone',
          i18n.t('input:must-be-phone-number', {
            field: ManagingCompanyUseri18nFields.phone,
            formats: '+11231231234, 1231231234',
          }),
          (value) => {
            const regex = new RegExp(/(\+[1-9])?[0-9]{3}[0-9]{3}[0-9]{4}/)
            return value != null && value.trim().length > 0 ? regex.test(value) : true
          },
        )
        .nullable()
        .optional(),
    }),
    officePhone: yup.object({
      number: yup
        .string()
        .test(
          'officePhone',
          i18n.t('input:must-be-phone-number', {
            field: ManagingCompanyUseri18nFields.officePhone,
            formats: '+11231231234, 1231231234',
          }),
          (value) => {
            const regex = new RegExp(/(\+[1-9])?[0-9]{3}[0-9]{3}[0-9]{4}/)
            return value != null && value.trim().length > 0 ? regex.test(value) : true
          },
        )
        .nullable()
        .optional(),
    }),
    preferedLanguage: yup
      .string()
      .typeError(i18n.t('input:is-required', { field: ManagingCompanyUseri18nFields.preferedLanguage }))
      .required(i18n.t('input:is-required', { field: ManagingCompanyUseri18nFields.preferedLanguage })),
    password: yup.string().when('password', (val?: string) => {
      if ((val?.length ?? 0) > 0) {
        return yup.string().min(8, t('password-error-length'))
      } else {
        return yup.string().optional()
      }
    }),
    email: yup
      .string()
      .email(i18n.t('input:is-required', { field: ManagingCompanyUseri18nFields.email }))
      .required(i18n.t('input:is-required', { field: ManagingCompanyUseri18nFields.email })),
    managingCompanyRole: yup
      .mixed()
      .required(i18n.t('input:is-required', { field: ManagingCompanyUseri18nFields.managingCompanyRole })),
    notificationPreferences: yup.array().optional().nullable(),
  },
  [['password', 'password']],
)

export const managingCompanyUserUpdateSchema = managingCompanyUserSchema
  .clone()
  .concat(yup.object({ id: yup.string() }))

export type ManagingCompanyUser = yup.InferType<typeof managingCompanyUserSchema>
export type ManagingCompanyUserUpdate = yup.InferType<typeof managingCompanyUserUpdateSchema>
